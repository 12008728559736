import { FC } from "react";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";

interface ForgotPasswordPageProps {}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = (): JSX.Element => {
	return (
		<>
			<ForgotPassword />
		</>
	);
};

export default ForgotPasswordPage;
