import { FC } from "react";
import { NavLink } from "react-router-dom";
import { Back } from "../LogIn/LogIn";
import Offering from "../Offering/Offering";
import styles from "./styles.module.scss";

interface SignUpProps {}

export const OnBoard = ({
	to,
	who,
	onClick,
	type = "link",
}: {
	to: string;
	who: "For Individual" | "For Corporate";
	onClick?: () => void;
	type?: "button" | "link";
}) => {
	return type === "button" ? (
		<button className={styles.onBoard} type="button" onClick={onClick}>
			<div>
				{who === "For Corporate" ? (
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="40" height="40" rx="20" fill="#FFF6E6" />
						<path
							d="M25.5291 15.77C25.4591 15.76 25.3891 15.76 25.3191 15.77C23.7691 15.72 22.5391 14.45 22.5391 12.89C22.5391 11.3 23.8291 10 25.4291 10C27.0191 10 28.3191 11.29 28.3191 12.89C28.3091 14.45 27.0791 15.72 25.5291 15.77Z"
							fill="#FFA108"
						/>
						<path
							d="M28.7896 22.7004C27.6696 23.4504 26.0996 23.7304 24.6496 23.5404C25.0296 22.7204 25.2296 21.8104 25.2396 20.8504C25.2396 19.8504 25.0196 18.9004 24.5996 18.0704C26.0796 17.8704 27.6496 18.1504 28.7796 18.9004C30.3596 19.9404 30.3596 21.6504 28.7896 22.7004Z"
							fill="#FFA108"
						/>
						<path
							d="M14.4402 15.77C14.5102 15.76 14.5802 15.76 14.6502 15.77C16.2002 15.72 17.4302 14.45 17.4302 12.89C17.4302 11.29 16.1402 10 14.5402 10C12.9502 10 11.6602 11.29 11.6602 12.89C11.6602 14.45 12.8902 15.72 14.4402 15.77Z"
							fill="#FFA108"
						/>
						<path
							d="M14.5491 20.8496C14.5491 21.8196 14.7591 22.7396 15.1391 23.5696C13.7291 23.7196 12.2591 23.4196 11.1791 22.7096C9.59914 21.6596 9.59914 19.9496 11.1791 18.8996C12.2491 18.1796 13.7591 17.8896 15.1791 18.0496C14.7691 18.8896 14.5491 19.8396 14.5491 20.8496Z"
							fill="#FFA108"
						/>
						<path
							d="M20.1208 23.87C20.0408 23.86 19.9508 23.86 19.8608 23.87C18.0208 23.81 16.5508 22.3 16.5508 20.44C16.5608 18.54 18.0908 17 20.0008 17C21.9008 17 23.4408 18.54 23.4408 20.44C23.4308 22.3 21.9708 23.81 20.1208 23.87Z"
							fill="#FFA108"
						/>
						<path
							d="M16.8708 25.9396C15.3608 26.9496 15.3608 28.6096 16.8708 29.6096C18.5908 30.7596 21.4108 30.7596 23.1308 29.6096C24.6408 28.5996 24.6408 26.9396 23.1308 25.9396C21.4208 24.7896 18.6008 24.7896 16.8708 25.9396Z"
							fill="#FFA108"
						/>
					</svg>
				) : (
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="40" height="40" rx="20" fill="#FFF6E6" />
						<path
							d="M20 10C17.38 10 15.25 12.13 15.25 14.75C15.25 17.32 17.26 19.4 19.88 19.49C19.96 19.48 20.04 19.48 20.1 19.49C20.12 19.49 20.13 19.49 20.15 19.49C20.16 19.49 20.16 19.49 20.17 19.49C22.73 19.4 24.74 17.32 24.75 14.75C24.75 12.13 22.62 10 20 10Z"
							fill="#FFA108"
						/>
						<path
							d="M25.0809 22.1499C22.2909 20.2899 17.7409 20.2899 14.9309 22.1499C13.6609 22.9999 12.9609 24.1499 12.9609 25.3799C12.9609 26.6099 13.6609 27.7499 14.9209 28.5899C16.3209 29.5299 18.1609 29.9999 20.0009 29.9999C21.8409 29.9999 23.6809 29.5299 25.0809 28.5899C26.3409 27.7399 27.0409 26.5999 27.0409 25.3599C27.0309 24.1299 26.3409 22.9899 25.0809 22.1499Z"
							fill="#FFA108"
						/>
					</svg>
				)}

				<p>{who}</p>
			</div>

			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37259 0 0 5.37258 0 12C0 18.6274 5.37259 24 12 24ZM12.1397 7.69173C11.9142 7.4489 11.5346 7.43484 11.2917 7.66032C11.0489 7.88581 11.0349 8.26545 11.2603 8.50827L14.2241 11.7H7.8C7.46863 11.7 7.2 11.9686 7.2 12.3C7.2 12.6314 7.46863 12.9 7.8 12.9H14.2241L11.2603 16.0917C11.0349 16.3346 11.0489 16.7142 11.2917 16.9397C11.5346 17.1652 11.9142 17.1511 12.1397 16.9083L16.4188 12.3L12.1397 7.69173Z"
					fill="#0866FF"
				/>
			</svg>
		</button>
	) : (
		<NavLink to={to} className={styles.onBoard} onClick={onClick}>
			<div>
				{who === "For Corporate" ? (
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="40" height="40" rx="20" fill="#FFF6E6" />
						<path
							d="M25.5291 15.77C25.4591 15.76 25.3891 15.76 25.3191 15.77C23.7691 15.72 22.5391 14.45 22.5391 12.89C22.5391 11.3 23.8291 10 25.4291 10C27.0191 10 28.3191 11.29 28.3191 12.89C28.3091 14.45 27.0791 15.72 25.5291 15.77Z"
							fill="#FFA108"
						/>
						<path
							d="M28.7896 22.7004C27.6696 23.4504 26.0996 23.7304 24.6496 23.5404C25.0296 22.7204 25.2296 21.8104 25.2396 20.8504C25.2396 19.8504 25.0196 18.9004 24.5996 18.0704C26.0796 17.8704 27.6496 18.1504 28.7796 18.9004C30.3596 19.9404 30.3596 21.6504 28.7896 22.7004Z"
							fill="#FFA108"
						/>
						<path
							d="M14.4402 15.77C14.5102 15.76 14.5802 15.76 14.6502 15.77C16.2002 15.72 17.4302 14.45 17.4302 12.89C17.4302 11.29 16.1402 10 14.5402 10C12.9502 10 11.6602 11.29 11.6602 12.89C11.6602 14.45 12.8902 15.72 14.4402 15.77Z"
							fill="#FFA108"
						/>
						<path
							d="M14.5491 20.8496C14.5491 21.8196 14.7591 22.7396 15.1391 23.5696C13.7291 23.7196 12.2591 23.4196 11.1791 22.7096C9.59914 21.6596 9.59914 19.9496 11.1791 18.8996C12.2491 18.1796 13.7591 17.8896 15.1791 18.0496C14.7691 18.8896 14.5491 19.8396 14.5491 20.8496Z"
							fill="#FFA108"
						/>
						<path
							d="M20.1208 23.87C20.0408 23.86 19.9508 23.86 19.8608 23.87C18.0208 23.81 16.5508 22.3 16.5508 20.44C16.5608 18.54 18.0908 17 20.0008 17C21.9008 17 23.4408 18.54 23.4408 20.44C23.4308 22.3 21.9708 23.81 20.1208 23.87Z"
							fill="#FFA108"
						/>
						<path
							d="M16.8708 25.9396C15.3608 26.9496 15.3608 28.6096 16.8708 29.6096C18.5908 30.7596 21.4108 30.7596 23.1308 29.6096C24.6408 28.5996 24.6408 26.9396 23.1308 25.9396C21.4208 24.7896 18.6008 24.7896 16.8708 25.9396Z"
							fill="#FFA108"
						/>
					</svg>
				) : (
					<svg
						width="40"
						height="40"
						viewBox="0 0 40 40"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="40" height="40" rx="20" fill="#FFF6E6" />
						<path
							d="M20 10C17.38 10 15.25 12.13 15.25 14.75C15.25 17.32 17.26 19.4 19.88 19.49C19.96 19.48 20.04 19.48 20.1 19.49C20.12 19.49 20.13 19.49 20.15 19.49C20.16 19.49 20.16 19.49 20.17 19.49C22.73 19.4 24.74 17.32 24.75 14.75C24.75 12.13 22.62 10 20 10Z"
							fill="#FFA108"
						/>
						<path
							d="M25.0809 22.1499C22.2909 20.2899 17.7409 20.2899 14.9309 22.1499C13.6609 22.9999 12.9609 24.1499 12.9609 25.3799C12.9609 26.6099 13.6609 27.7499 14.9209 28.5899C16.3209 29.5299 18.1609 29.9999 20.0009 29.9999C21.8409 29.9999 23.6809 29.5299 25.0809 28.5899C26.3409 27.7399 27.0409 26.5999 27.0409 25.3599C27.0309 24.1299 26.3409 22.9899 25.0809 22.1499Z"
							fill="#FFA108"
						/>
					</svg>
				)}

				<p>{who}</p>
			</div>

			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37259 0 0 5.37258 0 12C0 18.6274 5.37259 24 12 24ZM12.1397 7.69173C11.9142 7.4489 11.5346 7.43484 11.2917 7.66032C11.0489 7.88581 11.0349 8.26545 11.2603 8.50827L14.2241 11.7H7.8C7.46863 11.7 7.2 11.9686 7.2 12.3C7.2 12.6314 7.46863 12.9 7.8 12.9H14.2241L11.2603 16.0917C11.0349 16.3346 11.0489 16.7142 11.2917 16.9397C11.5346 17.1652 11.9142 17.1511 12.1397 16.9083L16.4188 12.3L12.1397 7.69173Z"
					fill="#0866FF"
				/>
			</svg>
		</NavLink>
	);
};

const SignUp: FC<SignUpProps> = (): JSX.Element => {
	return (
		<div className={styles.signUp}>
			<div className={styles.left}>
				<Offering />
			</div>

			<div className={styles.right} id="mainContainer">
				<Back to="/" className={styles.back} />

				<div className={styles.content}>
					<h6>Create an Account</h6>

					<p className={styles.sub}>
						Is this a corporate or individual account?
					</p>

					<div className={styles.onBoardContainer}>
						<OnBoard to="/sign-up/individual" who="For Individual" />

						<OnBoard to="/sign-up/corporate" who="For Corporate" />
					</div>

					<p className={styles.formFooter}>
						<span>Already have an account?</span>{" "}
						<NavLink to="/log-in">Log In</NavLink>
					</p>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
