import React, { useState } from "react";
import styles from "./styles.module.scss";

interface Tab {
	label: string;
	content: React.ReactNode;
}

interface TabsProps {
	tabs: Tab[];
	gap?: number;
	activeTab?: number;
	onTabChange?: (index: number) => void;
}

const Tabs: React.FC<TabsProps> = ({
	tabs,
	gap = 10,
	activeTab: activeTabProp,
	onTabChange,
}) => {
	const [activeTab, setActiveTab] = useState(activeTabProp || 0);

	const handleTabClick = (index: number) => {
		setActiveTab(index);
		if (onTabChange) {
			onTabChange(index);
		}
	};

	return (
		<div className={styles.tabsContainer}>
			<div
				className={styles.tabHeaders}
				style={{
					marginBottom: `${gap}px`,
				}}
			>
				{tabs.map((tab, index) => (
					<button
						type="button"
						key={index}
						className={`${styles.tabButton} ${
							activeTab === index ? styles.active : ""
						}`}
						onClick={() => handleTabClick(index)}
					>
						{tab.label}
					</button>
				))}
			</div>

			<div className={styles.tabContent}>{tabs[activeTab].content}</div>
		</div>
	);
};

export default Tabs;
