export const cx = (...classNames: string[]) =>
	classNames.filter(Boolean).join(" ");

export const slugify = (str: string): string => {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, "")
		.replace(/\s+/g, "-")
		.replace(/--+/g, "-");
};

export const formatToMoney = (num: number) => {
	return num
		.toLocaleString("en-US", {
			style: "currency",
			currency: "NGN",
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		.replace("NGN", "₦");
};

export const validatePassword = (password: string): string => {
	const minLength = 8;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumber = /\d/.test(password);
	const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

	if (password.length < minLength)
		return "Password must be at least 8 characters long.";
	if (!hasUpperCase)
		return "Password must contain at least one uppercase letter.";
	if (!hasLowerCase)
		return "Password must contain at least one lowercase letter.";
	if (!hasNumber) return "Password must contain at least one number.";
	if (!hasSpecialChar)
		return "Password must contain at least one special character.";

	return "";
};

export const formatDate = (dateString: string | Date): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "short",
		day: "numeric",
	};
	const date = new Date(dateString);
	return date.toLocaleDateString("en-US", options); // "Oct 24, 2024"
};

export const formatCurrency = (amount: number): string => {
	return new Intl.NumberFormat("en-NG", {
		style: "currency",
		currency: "NGN",
	}).format(amount);
};

export default {
	cx,
	slugify,
	validatePassword,
	formatToMoney,
	formatDate,
	formatCurrency,
};
