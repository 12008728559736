import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../../api/adminBaseApi";
import { useAuth } from "../../context/auth";
import { useFormContext } from "../../context/FormContext";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import UploadBox from "../InputField/UploadBox";
import Tabs from "../Tab/Tab";
import styles from "./styles.module.scss";

interface FormStepProps {
	step: number;
	formData: UserFormData;
	handleChange: (name: string, value: string | number | boolean | File) => void;
	nextStep: () => void;
	prevStep: () => void;
	resetForm?: () => void;
}

export interface UserFormData {
	groupName: string;
	email: string;
	excelFile?: File;
	businessType: string;
	identificationId: string;
	identificationType: string;
	businessName: string;
}

export const UserSteps: React.FC<FormStepProps> = ({
	handleChange,
	formData,
}) => {
	const { userData, refresh } = useAuth();
	const { closeForm } = useFormContext();
	const { axiosFetch } = useAxiosFunction();
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [activeTab, setActiveTab] = useState(0);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [businessTypes, setBusinessTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();
	const [identificationTypes, setIdentificationTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();

	useEffect(() => {
		const fetchData = async () => {
			const businessTypesRes = await axiosFetch({
				method: "GET",
				url: "/BusinessTypes/GetBusinessTypeByName?pageNumber=1&pageSize=100",
			});

			setBusinessTypes(
				businessTypesRes?.businessTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);

			const identificationTypesRes = await axiosFetch({
				method: "GET",
				url: "/IdentificationTypes/GetIdentificationTypes?pageNumber=1&pageSize=100",
			});

			setIdentificationTypes(
				identificationTypesRes?.identificationTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const isValidString = (value: string) => value.trim() !== "";

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const value = e.target.value;
		handleChange(name, value);
		let error = "";

		switch (name) {
			case "businessName":
			case "businessType":
			case "identificationType":
			case "identificationId":
				if (!isValidString(value)) error = `${name} cannot be empty`;
				break;
			case "email":
				if (!emailRegex.test(value)) error = `Invalid email format`;
				break;
		}

		setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
	};

	const isFormValid = () => {
		if (
			!formData.email ||
			!formData.businessName ||
			!formData.businessType ||
			!formData.identificationType ||
			!formData.identificationId ||
			errors.businessName ||
			errors.email ||
			errors.businessType ||
			errors.identificationType ||
			errors.identificationId
		) {
			return false;
		}

		return true;
	};

	useEffect(() => {
		const hasErrors = Object.values(errors).some((error) => error !== "");

		setIsButtonDisabled(hasErrors || !isFormValid());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors, formData]);

	const handleSubmitForSingleUser = async (event: React.FormEvent) => {
		event.preventDefault();

		toast.promise(
			axiosFetch({
				method: "POST",
				url: "/Auth/RegisterByProxy",
				requestConfig: {
					data: {
						email: formData.email,
						groupName: formData.groupName || undefined,
						businessType: formData.businessType,
						identificationId: formData.identificationId,
						identificationType: formData.identificationType,
						businessName: formData.businessName,
					},
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Onboarding...",
				success: (res) => {
					if (res.isSuccessful) {
						refresh();
						closeForm();

						return res.remark || "Sent successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	const handleSubmitForMultipleUsers = async (event: React.FormEvent) => {
		event.preventDefault();

		if (formData.excelFile) {
			const data = new FormData();

			data.append("userPublicId", userData?.publicId || "");
			data.append("excelFile", formData.excelFile);

			try {
				toast.promise(
					axios.post(
						API_BASE_URL + "Auth/RegisterMultipleUsersByProxy/",
						data,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								Accept: "application/json",
								Authorization: `Bearer ${userData?.token}`,
							},
						}
					),
					{
						loading: "Onboarding...",
						success: (res) => {
							if (res.data.isSuccessful) {
								refresh();
								closeForm();
								return res.data.remark || "Sent successfully!";
							}

							throw new Error(
								res.data || "An error occurred, please try again!"
							);
						},
						error: (err) => {
							if (axios.isAxiosError(err)) {
								return (
									err.response?.data.title ||
									err.response?.data.remark ||
									err.message
								);
							}
							return err.message;
						},
					}
				);
			} catch (error) {
				toast.error("Error submitting the form");
			}
		}
	};

	return (
		<form
			className={styles.content}
			onSubmit={
				activeTab === 0
					? handleSubmitForSingleUser
					: handleSubmitForMultipleUsers
			}
		>
			<h6>Onboard User</h6>

			<p className={styles.sub}>Invite link will be sent to user's email</p>

			<Tabs
				activeTab={activeTab}
				onTabChange={setActiveTab}
				tabs={[
					{
						label: "Single user",
						content: (
							<div className={styles.onBoardContainer}>
								<InputField
									label="Group Name (Optional)"
									name="groupName"
									placeholder="Enter group name"
									value={formData.groupName}
									onChange={handleInputChange}
									tooltip="Used for group email accounts"
									autoComplete="organization"
								/>

								<InputField
									label="Email"
									name="email"
									placeholder="Enter email address"
									value={formData.email}
									onChange={handleInputChange}
									autoComplete="email"
									type="email"
									error={errors.email}
								/>

								<InputField
									label="Business Name"
									name="businessName"
									placeholder="Enter business name"
									value={formData.businessName}
									onChange={handleInputChange}
									error={errors.businessName}
									isRequired
								/>

								<InputField
									label="Business Type"
									name="businessType"
									type="select"
									value={formData.businessType}
									onChange={handleInputChange}
									selectOptions={businessTypes}
									placeholder="Select organization type"
									error={errors.businessType}
									isRequired
								/>

								<InputField
									label="Identification Type"
									name="identificationType"
									type="select"
									value={formData.identificationType}
									onChange={handleInputChange}
									selectOptions={identificationTypes}
									placeholder="Select identification type"
									error={errors.identificationType}
									isRequired
								/>

								<InputField
									label="Identification Number"
									name="identificationId"
									placeholder="Identification number"
									value={formData.identificationId}
									onChange={handleInputChange}
									error={errors.identificationId}
									isRequired
								/>

								<button
									className="btn"
									type="submit"
									disabled={isButtonDisabled}
								>
									Send Link
								</button>
							</div>
						),
					},
					{
						label: "Multiple users",
						content: (
							<div className={styles.onBoardContainer}>
								<UploadBox
									label="Upload an Excel file with emails in the first column of the first worksheet (one row per email)"
									onUpload={(uploads) => {
										handleChange("excelFile", uploads[0]);
									}}
									accept={{
										file: "xlsx",
										type: {
											"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
												[".xlsx"],
											"application/vnd.ms-excel": [".xls"],
										},
									}}
									error={
										!formData.excelFile
											? "Please upload a valid Excel file for multiple users."
											: ""
									}
								/>

								<button
									className="btn"
									type="submit"
									disabled={!formData.excelFile}
								>
									Send Link
								</button>
							</div>
						),
					},
				]}
			/>
		</form>
	);
};
