import { FC } from "react";
import star from "../../assets/images/star.png";
import styles from "./styles.module.scss";

interface PricingHeroProps {}

interface CardProps {
	price: string;
	title: string;
	description: string;
}

const prices = [
	{
		price: "₦500",
		title: "Average Debt Settlement Time",
		sub: "Great for lenders and vendors who want to make an educated estimate about how long their prospective recipient takes to settle financial outstanding.",
	},
	{
		price: "₦500",
		title: "Trustworthiness Check",
		sub: "Great for users who want to quickly find out if their prospective recipient has outstanding financial obligations that are overdue.",
	},
	{
		price: "₦500",
		title: "Outstanding Salary Check",
		sub: "Great for lenders, vendors, and job-seekers who want to verify an organization's ability to pay staff salaries as and when due.",
	},
	{
		price: "₦2,000",
		title: "Detailed Informal Transactions Report",
		sub: "Available to financial organizations alone to enable them properly quantify the risk of transacting with a target using granular information.",
	},
];

const Card: React.FC<CardProps> = ({ price, title, description }) => {
	return (
		<div className={styles.card}>
			<h3 className={styles.price}>
				{price}
				<span>/Request</span>
			</h3>

			<h4 className={styles.title}>{title}</h4>

			<p className={styles.description}>{description}</p>
		</div>
	);
};

const PricingHero: FC<PricingHeroProps> = (): JSX.Element => {
	return (
		<>
			<div className={styles.pricingHero} id="mainContainer">
				<p>Pricing</p>

				<h1>
					Our simple pricing with no hidden fees
					<div className={styles.star}>
						<img alt="star" src={star} />
					</div>
				</h1>

				<p>Clear, affordable and transparent fees</p>
			</div>

			<div className={styles.cardContainer} id="mainContainer">
				{prices.map((pr, i) => (
					<Card
						key={i}
						price={pr.price}
						title={pr.title}
						description={pr.sub}
					/>
				))}
			</div>
		</>
	);
};

export default PricingHero;
