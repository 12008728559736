import { FC } from "react";
import Otp from "../components/Otp/Otp";

interface OtpPageProps {}

const OtpPage: FC<OtpPageProps> = (): JSX.Element => {
	return (
		<>
			<Otp />
		</>
	);
};

export default OtpPage;
