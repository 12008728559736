import { FC } from "react";
import { NavLink } from "react-router-dom";
import heroImg from "../../assets/images/hero.png";
import star from "../../assets/images/star.png";
import { RightArrow } from "../../assets/svg/Svg";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface HeroProps {}

const Hero: FC<HeroProps> = (): JSX.Element => {
	return (
		<div id="mainContainer" className={styles.hero}>
			<div className={styles.left}>
				<div className={styles.header}>
					<h1 className={styles.top}>
						<div className={styles.star}>
							<img alt="star" src={star} />
						</div>
						The database that <br /> helps you make <br /> safer transactions
					</h1>

					<h2 className={styles.bottom}>
						Verify the credibility of your person or entity <br /> of interest
						before transacting with them.
					</h2>
				</div>

				<div className={styles.cta}>
					<div>
						<NavLink to={`/${slugify("Sign Up")}`} className="btn">
							Get Started
						</NavLink>

						<NavLink to={`/${slugify("About Us")}`} className="btn-secondary">
							Learn More
						</NavLink>
					</div>

					<NavLink to={`/${slugify("Contact")}`} className={styles.more}>
						<p> Got questions? Talk to an expert</p>

						<RightArrow className="" />
					</NavLink>
				</div>
			</div>

			<div className={styles.right}>
				<img alt="hero background" src={heroImg} />
			</div>
		</div>
	);
};

export default Hero;
