import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = (): JSX.Element => {
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const { axiosFetch } = useAxiosFunction();
	const [formData, setFormData] = useState({
		email: "",
	});
	const [errors, setErrors] = useState({
		email: "",
	});

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setFormData({
			...formData,
			[name]: e.target.value,
		});

		if (e.target.value === "") {
			setErrors({ ...errors, [name]: `${name} cannot be empty` });
		} else {
			setErrors({ ...errors, [name]: "" });
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		toast.promise(
			axiosFetch({
				method: "POST",
				url: `/Auth/ForgotPassword?email=${formData.email}`,
			}),
			{
				loading: "Submitting...",
				success: (res) => {
					if (res.isSuccessful) {
						console.log(res);

						return res.remark || "Submitted successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	// Check if the form is valid (no errors and no empty required fields)
	useEffect(() => {
		const hasErrors = Object.values(errors).some((error) => error !== "");
		const isEmptyField = Object.values(formData).some((value) => value === "");

		// Disable button if there are any errors or if any required field is empty
		setIsButtonDisabled(hasErrors || isEmptyField);
	}, [errors, formData]);

	return (
		<div className={styles.forgotPassword}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back to="/log-in" fixed />

				<div className={styles.content}>
					<h6>Forgot Password</h6>

					<p className={styles.sub}>
						Please input your email, an OTP will be sent to the email shortly.
					</p>

					<form onSubmit={handleSubmit}>
						<InputField
							label="Email"
							name="email"
							type="email"
							placeholder="name@example.com"
							value={formData.email}
							onChange={handleInputChange}
							error={errors.email}
							isRequired
						/>

						<button className="btn" type="submit" disabled={isButtonDisabled}>
							Send OTP
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
