import { FC, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import background from "../../assets/images/footer.png";
import Logo from "../../assets/images/Logo-white.png";
import {
	Facebook,
	Instagram,
	Linkedin,
	Twitter,
} from "../../assets/svg/Social";
import { footerData } from "../../data/constant";
import siteMetadata from "../../data/siteMetaData";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface FooterProps {}

const Cta = ({ name }: { name: string }) => (
	<div className={`${styles[name]}`}>
		<h5>Get started with dtt to make safer transactions</h5>

		<NavLink to={`/${slugify("Sign Up")}`} className="btn">
			Register
		</NavLink>
	</div>
);

const Socials = ({ name }: { name: string }) => (
	<div className={`${styles.socials} ${styles[name]}`}>
		<a
			aria-label="Facebook"
			href={siteMetadata.facebook}
			target="_blank"
			rel="noopener noreferrer"
		>
			<Facebook className="" />
		</a>

		<a
			aria-label="Twitter"
			href={siteMetadata.twitter}
			target="_blank"
			rel="noopener noreferrer"
		>
			<Twitter className="" />
		</a>

		<a
			aria-label="Instagram"
			href={siteMetadata.instagram}
			target="_blank"
			rel="noopener noreferrer"
		>
			<Instagram className="" />
		</a>

		<a
			aria-label="Linkedin"
			href={siteMetadata.linkedin}
			target="_blank"
			rel="noopener noreferrer"
		>
			<Linkedin className="" />
		</a>
	</div>
);

const Footer: FC<FooterProps> = (): JSX.Element => {
	const { pathname } = useLocation();
	const [active, setActive] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname, active]);

	return (
		<section className={styles.footer}>
			<img alt="Logo" src={background} className={styles.bg} />

			<div className={styles.contents} id="mainContainer">
				<Cta name="" />

				<div>
					<NavLink to="/" className={styles.logo}>
						<img alt="Logo" src={Logo} />
					</NavLink>

					<div className={styles.nav}>
						{footerData.map((nav, index) => (
							<NavLink
								to={`/${slugify(nav)}`}
								key={index.toFixed()}
								onClick={() => setActive(nav)}
							>
								{nav}
							</NavLink>
						))}
					</div>

					<Cta name="mobileCta" />

					<Socials name="" />
				</div>
			</div>

			<div className={styles.line} id="mainContainer" />

			<div id="mainContainer">
				<h6>
					Copyright © 2024 domestic transaction tracker | All Rights Reserved
				</h6>
			</div>
		</section>
	);
};

export default Footer;
