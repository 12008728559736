import { FC } from "react";
import StaffManagement from "../../components/Dashboard/UserManagement/StaffManagement";
import UserManagement from "../../components/Dashboard/UserManagement/UserManagement";
import { useAuth } from "../../context/auth";

interface UserManagementPageProps {}

const UserManagementPage: FC<UserManagementPageProps> = (): JSX.Element => {
	const { userData } = useAuth();

	return (
		<>
			{userData?.userRole === "Corporate" ? (
				<StaffManagement />
			) : (
				<UserManagement />
			)}
		</>
	);
};

export default UserManagementPage;
