import { FC } from "react";
import Payment from "../../components/Dashboard/Payment/Payment";

interface PaymentPageProps {}

const PaymentPage: FC<PaymentPageProps> = (): JSX.Element => {
	return (
		<>
			<Payment />
		</>
	);
};

export default PaymentPage;
