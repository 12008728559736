import axios from "axios";

// const test = "http://dtt.runasp.net/api/v1/";

export const API_BASE_URL = "https://www.staging.dtt.ng/api/v1/";

const axiosInstance = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

export default axiosInstance;
