import { FC } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { Auto, Fast, Trans, Unlimited } from "../../assets/svg/FeatureSvg";
import styles from "./styles.module.scss";

interface OfferingProps {}

interface FeatureCardProps {
	icon: JSX.Element;
	title: string;
	description: string;
}

const FeatureCard: FC<FeatureCardProps> = ({ icon, title, description }) => {
	return (
		<div className={styles.card}>
			<div className={styles.iconWrapper}>{icon}</div>

			<div className={styles.content}>
				<h5 className={styles.title}>{title}</h5>
				<p className={styles.description}>{description}</p>
			</div>
		</div>
	);
};

const Offering: FC<OfferingProps> = (): JSX.Element => {
	return (
		<div className={styles.offering}>
			<NavLink to="/" className={styles.logo}>
				<img alt="Logo" src={Logo} />
			</NavLink>

			<div className={styles.content}>
				<h3>
					Offering the best <br /> at what we do
				</h3>

				<div className={styles.featureContainer}>
					<div className={styles.feature}>
						<FeatureCard
							icon={<Trans />}
							title="Transparency"
							description="No hidden charges as you pay for what you search ONLY."
						/>

						<FeatureCard
							icon={<Fast />}
							title="Fast & Easy"
							description="Get access instantly, search transactions immediately."
						/>
					</div>

					<div className={styles.feature}>
						<FeatureCard
							icon={<Auto />}
							title="Automated Reminders"
							description="One free email to remind debtors to settle debts."
						/>

						<FeatureCard
							icon={<Unlimited />}
							title="Unlimited Features"
							description="Access all features with the new updates we roll out!"
						/>
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<p>Do you want to speak with a representative?</p>

				<p>
					<span>Call us at</span> <span>800 1301 448</span>
				</p>
			</div>
		</div>
	);
};

export default Offering;
