import * as Accordion from "@radix-ui/react-accordion";
import { FC } from "react";
import FaqSvg from "../../assets/svg/FaqSvg";
import { faqData } from "../../data/constant";
import styles from "./styles.module.scss";

interface FAQsProps {}

const FAQs: FC<FAQsProps> = (): JSX.Element => {
	return (
		<div id="mainContainer" className={styles.faq}>
			<div className={styles.header}>
				<h4>Frequently Asked Questions</h4>

				<FaqSvg />
			</div>

			<Accordion.Root
				type="single"
				className={styles.accordion}
				collapsible
				defaultValue="0"
			>
				{faqData.slice(0, 6).map((faq, i) => (
					<Accordion.Item
						value={i.toString()}
						key={i.toFixed()}
						className={styles.item}
					>
						<Accordion.Header>
							<Accordion.Trigger className={styles.trigger}>
								<p className={styles.title}>{faq.q}</p>

								<svg
									className={styles.icon1}
									width="24"
									height="24"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="16" cy="16" r="16" fill="#FFA108" />
									<path
										d="M10.2402 21.7601L21.7602 10.2401"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M21.7602 21.7601L10.2402 10.2401"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<svg
									className={styles.icon2}
									width="24"
									height="24"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="16" cy="16" r="16" fill="white" />
									<path
										d="M16 10.2401V21.7601"
										stroke="#FFA108"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10.2402 16H21.7602"
										stroke="#FFA108"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Accordion.Trigger>
						</Accordion.Header>

						<Accordion.Content className={styles.content}>
							<div>{faq.a()}</div>
						</Accordion.Content>
					</Accordion.Item>
				))}
			</Accordion.Root>
		</div>
	);
};

export default FAQs;
