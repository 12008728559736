import { FC } from "react";
import styles from "./styles.module.scss";

interface SupportProps {}

const Support: FC<SupportProps> = (): JSX.Element => {
	return <div className={styles.support}>Support</div>;
};

export default Support;
