import { FC } from "react";
import SignUp from "../components/SignUp/SignUp";

interface SignUpPageProps {}

const SignUpPage: FC<SignUpPageProps> = (): JSX.Element => {
	return (
		<>
			<SignUp />
		</>
	);
};

export default SignUpPage;
