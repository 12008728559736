import { FC } from "react";
import Support from "../../components/Dashboard/Support/Support";

interface SupportPageProps {}

const SupportPage: FC<SupportPageProps> = (): JSX.Element => {
	return (
		<>
			<Support />
		</>
	);
};

export default SupportPage;
