import { FC, FormEvent, useState } from "react";
import toast from "react-hot-toast";
import contact from "../../assets/images/contact.png";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import styles from "./styles.module.scss";

interface ContactFormProps {}

const ContactForm: FC<ContactFormProps> = (): JSX.Element => {
	const { axiosFetch } = useAxiosFunction();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		message: "",
	});

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		toast.promise(
			axiosFetch({
				method: "POST",
				url: "/ContactUsResponses/CreateContactUs",
				requestConfig: {
					data: formData,
				},
			}),
			{
				loading: "Submitting in...",
				success: (res) => {
					if (res.isSuccessful) {
						return res.remark || "Submitted successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.contact}>
			<div className={styles.left}>
				<div className={styles.overlay} />

				<img alt="background" src={contact} />
			</div>

			<div className={styles.right}>
				<h5>Contact Us</h5>

				<form onSubmit={handleSubmit}>
					<div className={styles.field}>
						<InputField
							label="First Name"
							placeholder="Enter first name"
							value={formData.firstName}
							onChange={handleInputChange}
							name="firstName"
							secondary
							isRequired
						/>

						<InputField
							label="Last Name"
							placeholder="Enter last name"
							value={formData.lastName}
							onChange={handleInputChange}
							name="lastName"
							secondary
							isRequired
						/>
					</div>

					<div className={styles.field}>
						<InputField
							label="Email"
							type="email"
							placeholder="Enter your email"
							value={formData.email}
							onChange={handleInputChange}
							name="email"
							secondary
							isRequired
						/>

						<InputField
							label="Phone number"
							type="tel"
							placeholder="Enter phone number"
							value={formData.phoneNumber}
							onChange={handleInputChange}
							name="phoneNumber"
							secondary
							isRequired
						/>
					</div>

					<InputField
						label="Messages"
						placeholder="Leave a message"
						value={formData.message}
						onChange={handleInputChange}
						name="message"
						secondary
						isRequired
					/>

					<button className="btn" type="submit">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
};

export default ContactForm;
