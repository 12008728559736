import { FC } from "react";
import Terms from "../components/Policy/Terms";

interface TermsPageProps {}

const TermsPage: FC<TermsPageProps> = (): JSX.Element => {
	return (
		<>
			<Terms />
		</>
	);
};

export default TermsPage;
