import React from "react";
import styles from "./styles.module.scss";

interface ExpandableCardProps {
	index: number;
	icon: React.ReactNode;
	mainText: string;
	subText: string;
	isActive: boolean;
	onClick: () => void;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
	index,
	icon,
	mainText,
	subText,
	isActive,
	onClick,
}) => {
	return (
		<div
			className={`${styles.expandableCard} ${isActive ? styles.active : ""}`}
			onClick={onClick}
		>
			<div className={styles.icon}>
				{icon}

				{isActive && <h5 className={styles.index}>{`0${index}`}</h5>}
			</div>

			<div className={styles.info}>
				<h5 className={styles.main}>{mainText}</h5>

				{isActive && <p className={styles.sub}>{subText}</p>}
			</div>
		</div>
	);
};

export default ExpandableCard;
