import jsPDF from "jspdf";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import { useFormContext } from "../../context/FormContext";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import { formatCurrency, formatDate } from "../../utils";
import InputField from "../InputField/InputField";
import PaymentModal from "../PopUps/Payments/PaymentModal";
import RecordAvailableModal from "../PopUps/RecordAvailableModal";
import { OnBoard } from "../SignUp/SignUp";
import Tabs from "../Tab/Tab";
import { Credibility } from "./MultiStepForm ";
import styles from "./styles.module.scss";

interface FormStepProps {
	step: number;
	formData: SearchFormData;
	handleChange: (name: string, value: string | number | boolean | File) => void;
	nextStep: () => void;
	prevStep: () => void;
	resetForm?: () => void;
	credibility?: Credibility;
	setCredibility?: (credibility: Credibility | undefined) => void;
}

export interface SearchFormData {
	isIndividual: boolean;
	search: string;
}

export const SearchStep1: React.FC<FormStepProps> = ({
	handleChange,
	nextStep,
}) => {
	return (
		<form className={styles.content}>
			<h6>Search Record</h6>

			<p className={styles.sub}>
				What kind of record do you want to search for?
			</p>

			<div className={styles.onBoardContainer}>
				<OnBoard
					to=""
					type="button"
					who="For Individual"
					onClick={() => {
						handleChange("isIndividual", true);
						nextStep();
					}}
				/>

				<OnBoard
					to=""
					type="button"
					who="For Corporate"
					onClick={() => {
						handleChange("isIndividual", false);
						nextStep();
					}}
				/>
			</div>
		</form>
	);
};

export const SearchStep2: React.FC<FormStepProps> = ({
	handleChange,
	formData,
	nextStep,
	setCredibility,
}) => {
	const { axiosFetch } = useAxiosFunction();
	const { userData, refresh } = useAuth();
	const [payment, setPayment] = useState(false);
	const { toggleForm, closeForm } = useFormContext();
	const [isModalOpen, setModalOpen] = useState(false);
	const [isRecordFound, setIsRecordFound] = useState(false);
	const [errors, setErrors] = useState({
		search: "",
	});
	const [activeTab, setActiveTab] = useState(0);

	const validateField = (name: string, value: string) => {
		let error = "";

		if (name === "search") {
			if (formData.isIndividual) {
				if (value.length !== 11) {
					error = "BVN or NIN must be 11 digits.";
				}
			} else {
				if (!value.trim()) {
					error = "Business Name or RC Number is required.";
				}
			}
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));

		return error === "";
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!validateField("search", formData.search)) {
			toast.error("Please fix the errors before submitting.");
			return;
		}

		let queryParams = "";

		if (activeTab === 0) {
			queryParams = formData.isIndividual
				? `bvn=${formData.search}&hasBvn=true&userId=${userData?.publicId}`
				: `registrationNumber=${formData.search}&userId=${userData?.publicId}`;
		} else if (activeTab === 1) {
			queryParams = formData.isIndividual
				? `nin=${formData.search}&hasNin=true&userId=${userData?.publicId}`
				: `businessName=${formData.search}&userId=${userData?.publicId}`;
		}

		toast.promise(
			axiosFetch({
				method: "GET",
				url: `/Credibilities/HasRecordWithDtt?${queryParams}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Searching record...",
				success: (res) => {
					if (res === true) {
						setIsRecordFound(true);
						setModalOpen(true);

						return res.remark || "Record successfully!";
					} else if (res === false) {
						setIsRecordFound(false);
						setModalOpen(true);
					}

					throw new Error(res.remark || "Record not found!");
				},
				error: (err) => err.message,
			}
		);
	};

	const retrieveReport = async () => {
		let queryParams = "";

		if (activeTab === 0) {
			queryParams = formData.isIndividual
				? `bvn=${formData.search}&userId=${userData?.publicId}`
				: `registrationNumber=${formData.search}&userId=${userData?.publicId}`;
		} else if (activeTab === 1) {
			queryParams = formData.isIndividual
				? `nin=${formData.search}&userId=${userData?.publicId}`
				: `businessName=${formData.search}&userId=${userData?.publicId}`;
		}

		toast.promise(
			axiosFetch({
				method: "GET",
				url: `/Credibilities/GetCredibilityReport?pageNumber=1&pageSize=100&${queryParams}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Retrieving reports...",
				success: (res) => {
					if (res.isSuccessful) {
						refresh();

						if (setCredibility) {
							setCredibility({
								isCredible: res.isCredible,
								totalCount: res.totalCount,
								transactions: res.transactions,
							});
						}
						nextStep();
						setModalOpen(false);

						return res.remark || "Retrieved successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					setModalOpen(false);

					return err.message;
				},
			}
		);
	};

	const handleProceed = () => {
		const productCost = 500;

		if (userData?.isStaff) {
			retrieveReport();
			return;
		}

		if (!userData?.isIndividual) {
			retrieveReport();
			return;
		}

		if (userData?.balance < productCost) {
			setPayment(true);
		} else {
			retrieveReport();
		}

		setModalOpen(false);
		setIsRecordFound(false);
	};

	return (
		<>
			<form className={styles.content} onSubmit={handleSubmit}>
				<h6>Search Record</h6>

				<p className={styles.sub}>Search for a record in our database.</p>

				<div className={styles.onBoardContainer}>
					<Tabs
						gap={20}
						activeTab={activeTab}
						onTabChange={(index) => setActiveTab(index)}
						tabs={[
							{
								label: formData.isIndividual ? "BVN" : "RC Number",
								content: (
									<InputField
										type="number"
										label={
											formData.isIndividual
												? "Bank Verification Number (BVN)"
												: "RC Number"
										}
										name="search"
										placeholder={
											formData.isIndividual ? "Enter BVN" : "Enter RC Number"
										}
										value={formData.search}
										onChange={(name, e) => {
											handleChange(name, e.target.value);
											validateField(name, e.target.value);
										}}
										error={errors.search}
										isRequired
									/>
								),
							},
							{
								label: formData.isIndividual ? "Enter NIN" : "Business Name",
								content: (
									<InputField
										type={formData.isIndividual ? "number" : "text"}
										label={
											formData.isIndividual
												? "National Identification Number (NIN)"
												: "Registered Business Name"
										}
										name="search"
										placeholder={
											formData.isIndividual
												? "Enter NIN"
												: "Enter Business Name"
										}
										value={formData.search}
										onChange={(name, e) => {
											handleChange(name, e.target.value);
											validateField(name, e.target.value);
										}}
										error={errors.search}
										isRequired
									/>
								),
							},
						]}
					/>

					<button
						className="btn"
						type="submit"
						disabled={errors.search.length > 0 || formData.search.length === 0}
					>
						Search Record
					</button>
				</div>
			</form>

			<RecordAvailableModal
				isOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
				isAvailable={isRecordFound}
				subTitle={
					isRecordFound
						? "Please click proceed to see the requested person's present record."
						: "This record is not in our database, do you want to register any transaction with us to add a record?"
				}
				title={isRecordFound ? "Record Is Available" : "Record Not Found"}
				cta={{
					primary: isRecordFound
						? {
								onClick: handleProceed,
								title: "Proceed",
						  }
						: {
								onClick: () => {
									closeForm();
									toggleForm("record");
								},
								title: "Yes, I do",
						  },
					secondary: {
						onClick: () => {
							setModalOpen(false);
						},
						title: isRecordFound ? "Cancel" : "No, I don’t",
					},
				}}
			/>

			{payment && (
				<PaymentModal
					productName="Detailed Informal Report Of Transactions"
					onClose={() => setPayment(false)}
				/>
			)}
		</>
	);
};

export const SearchStep3: React.FC<FormStepProps> = ({ credibility }) => {
	const { userData } = useAuth();
	const calculateDaysOwing = (dueDate: string | Date) => {
		const today = new Date();
		const due = new Date(dueDate);

		const differenceInTime = today.getTime() - due.getTime();

		const daysOwing = Math.floor(differenceInTime / (1000 * 3600 * 24));

		return daysOwing;
	};

	const downloadPDFReport = () => {
		if (!credibility) return;
		const doc = new jsPDF();

		doc.setFontSize(18);
		doc.text("Credit Report", 10, 10);

		doc.setFontSize(12);
		doc.text("Personal Information", 10, 20);
		doc.text(
			`Full Name: ${credibility.transactions?.[0]?.recipientFirstName} ${credibility.transactions?.[0]?.recipientLastName}`,
			10,
			30
		);
		doc.text(`BVN: ${credibility.transactions?.[0]?.recipientBvn}`, 10, 40);

		doc.setFontSize(12);
		doc.text("Debt Information", 10, 50);

		credibility.transactions?.forEach((transaction, index) => {
			const y = 60 + index * 40;

			doc.text(`Creditor ${index + 1}`, 10, y);
			doc.text(
				`Creditor Name: ${transaction.recipientFirstName} ${transaction.recipientLastName}`,
				10,
				y + 10
			);
			doc.text(
				`Amount: ₦${transaction.amountInvolved.toLocaleString()}`,
				10,
				y + 20
			);
			doc.text(`Payment Type: ${transaction.transactionType}`, 10, y + 30);
			doc.text(
				`Payment Status: ${transaction.isSettled ? "Resolved" : "Unresolved"}`,
				10,
				y + 40
			);
			doc.text(
				`Due Date: ${new Date(transaction.dueDate).toLocaleDateString()}`,
				10,
				y + 50
			);
		});

		doc.save("credit_report.pdf");
	};

	return (
		<div className={styles.content}>
			<h6>Credit Report</h6>

			<p className={styles.sub}>View user’s credit information</p>

			<div className={styles.section}>
				<h3>Personal Information</h3>

				<div className={styles.infoBox}>
					<div className={styles.infoRow}>
						<span>Full Name</span>

						<span>
							{userData?.firstName} {userData?.lastName}
						</span>
					</div>

					<div className={styles.infoRow}>
						<span>Date of Birth</span>

						<span>{formatDate(userData?.dateOfBirth || "")}</span>
					</div>

					<div className={styles.infoRow}>
						<span>{userData?.isIndividual ? "BVN" : "Business Name"}</span>

						<span>
							{userData?.isIndividual ? userData?.bvn : userData?.businessName}
						</span>
					</div>
				</div>
			</div>

			<div className={styles.section}>
				<h3>Debt Information</h3>

				<div className={styles.debtInfoContainer}>
					{credibility?.transactions?.map((transaction, index) => (
						<div key={transaction.publicId} className={styles.debtInfo}>
							<div className={styles.infoRow}>
								<span>Creditor Name({index + 1})</span>

								<span>
									{transaction.recipientFirstName}{" "}
									{transaction.recipientLastName}
								</span>
							</div>

							<div className={styles.infoRow}>
								<span>Amount</span>

								<span>{formatCurrency(transaction.amountInvolved)}</span>
							</div>

							<div className={styles.infoRow}>
								<span>Payment Type</span>

								<span>{transaction.transactionType}</span>
							</div>

							<div className={styles.infoRow}>
								<span>Payment Status</span>

								<span
									className={
										transaction.isSettled ? styles.resolved : styles.unresolved
									}
								>
									{transaction.isSettled ? "Resolved" : "Unresolved"}
								</span>
							</div>

							<div className={styles.infoRow}>
								<span>Payment Due Date</span>

								<span>{formatDate(transaction.dueDate)}</span>
							</div>

							<div className={styles.infoRow}>
								<span>Number of Days Owing</span>

								<span>
									{transaction.isSettled
										? "0 Days"
										: `${
												calculateDaysOwing(transaction.dueDate) >= 0
													? `${calculateDaysOwing(transaction.dueDate)} Days`
													: `${Math.abs(
															calculateDaysOwing(transaction.dueDate)
													  )} Days (not due yet)`
										  }`}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>

			<button
				className="btn"
				type="button"
				onClick={downloadPDFReport}
				disabled={credibility?.transactions === null}
			>
				Download Report
			</button>
		</div>
	);
};
