import { FC } from "react";
import LogIn from "../components/LogIn/LogIn";

interface LogInPageProps {}

const LogInPage: FC<LogInPageProps> = (): JSX.Element => {
	return (
		<>
			<LogIn />
		</>
	);
};

export default LogInPage;
