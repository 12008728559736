import { FC } from "react";
import History from "../../components/Dashboard/History/History";

interface HistoryPageProps {}

const HistoryPage: FC<HistoryPageProps> = (): JSX.Element => {
	return (
		<>
			<History />
		</>
	);
};

export default HistoryPage;
