import React, { useState } from "react";
import { formatCurrency, formatDate } from "../../utils";
import { SearchRecordType } from "../Dashboard/Home/Home";
import styles from "./styles.module.scss";

const SearchedRecordTable = ({
	searchRecord,
}: {
	searchRecord: SearchRecordType[];
}) => {
	const [searchRecords, setSearchRecords] = useState<string[]>([]);

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSearchRecords(searchRecord.map((record) => record.publicId));
		} else {
			setSearchRecords([]);
		}
	};

	const handleSelectRecord = (userId: string) => {
		setSearchRecords((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	return (
		<div className={styles.table}>
			<div className={styles.tableContainer}>
				<table>
					<thead>
						<tr>
							<th align="center">
								<input
									type="checkbox"
									checked={searchRecords.length === searchRecord.length}
									onChange={handleSelectAll}
								/>
							</th>

							<th align="left">Name</th>

							<th align="center">Cost</th>

							<th align="center">Date Searched</th>

							<th align="center">Product Type</th>
						</tr>
					</thead>
					<tbody>
						{searchRecord.map((record) => (
							<tr key={record.publicId}>
								<td align="center">
									<input
										type="checkbox"
										checked={searchRecords.includes(record.publicId)}
										onChange={() => handleSelectRecord(record.publicId)}
									/>
								</td>

								<td align="left" className={styles.user}>
									{record.targetName}
								</td>

								<td align="center">{formatCurrency(record.price)}</td>

								<td align="center">{formatDate(record.dateCreated)}</td>

								<td align="center">{record.productName}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default SearchedRecordTable;
