import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

import axiosInstance from "../api/adminBaseApi";

interface UseAxiosFunctionProps {
	method: "GET" | "POST" | "PUT" | "DELETE";
	url: string;
	requestConfig?: AxiosRequestConfig;
	formData?: FormData; // Add formData property to accept form data
}

const useAxiosFunction = () => {
	const [response, setResponse] = useState(null);
	const [error, setError] = useState<boolean | string>(false);
	const [loading, setLoading] = useState(false);
	const [controller, setController] = useState<AbortController>();

	const axiosFetch = async (configObj: UseAxiosFunctionProps) => {
		const { method, url, requestConfig = {}, formData } = configObj; // Destructure formData
		const ctrl = new AbortController();
		setController(ctrl);
		setLoading(true);

		const requestOptions: AxiosRequestConfig = {
			method: method.toLowerCase(),
			signal: ctrl.signal,
			timeout: 500000,
			...requestConfig,
		};

		if (formData) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(requestOptions as any).body = formData; // Pass formData in the request options
		}

		const res = await axiosInstance(url, requestOptions)
			.then((res) => {
				setLoading(false);

				if (res?.data) {
					setResponse(res.data);
					return res.data;
				}

				setError(res.data);
				return res.data;
			})
			.catch((err) => {
				setLoading(false);

				if (axios.isAxiosError(err)) {
					setError(
						err.response?.data.title || err.response?.data.remark || err.message
					);
					return (
						err.response?.data.title || err.response?.data.remark || err.message
					);
				}

				return "an error occurred";
			});

		return res;
	};

	useEffect(() => {
		// Cleanup function => Abort controller if it exists
		return () => controller?.abort();
	}, [controller]);

	return { axiosFetch, response, loading, error };
};

export default useAxiosFunction;
