import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import { OnBoard } from "../SignUp/SignUp";
import styles from "./styles.module.scss";

interface FormStepProps {
	step: number;
	formData: ReminderFormData;
	handleChange: (name: string, value: string | number | boolean | File) => void;
	nextStep: () => void;
	prevStep: () => void;
	resetForm?: () => void;
}

export interface ReminderFormData {
	isIndividual: boolean;
	bvn: string;
	nin: string;
	registrationNumber: string;
	businessName: string;
	reminderFrequency: string;
	createdBy: string;
	requesterEmailAddress: string;
	productName: string;
	bankName: string;
	accountNumber: string;
	isSpreadSheetReport: boolean;
	transactionType: string;
}

export const ReminderStep1: React.FC<FormStepProps> = ({
	handleChange,
	nextStep,
}) => {
	return (
		<form className={styles.content}>
			<h6>Record Type</h6>

			<p className={styles.sub}>
				What kind of record do you want to automate reminder for?
			</p>

			<div className={styles.onBoardContainer}>
				<OnBoard
					to=""
					type="button"
					who="For Individual"
					onClick={() => {
						handleChange("isIndividual", true);
						nextStep();
					}}
				/>

				<OnBoard
					to=""
					type="button"
					who="For Corporate"
					onClick={() => {
						handleChange("isIndividual", false);
						nextStep();
					}}
				/>
			</div>
		</form>
	);
};

export const ReminderStep2: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	nextStep,
}) => {
	const { axiosFetch } = useAxiosFunction();
	const [bank, setBank] = useState<{ value: string; label: string }[]>([]);
	const [errors, setErrors] = useState({
		bvn: "",
		nin: "",
		registrationNumber: "",
		businessName: "",
		requesterEmailAddress: "",
		bankName: "",
		accountNumber: "",
	});
	const [isFormValid, setIsFormValid] = useState<string | boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			const banksData = await axiosFetch({
				method: "GET",
				url: "/Banks/GetBanks?pageNumber=1&pageSize=100",
			});

			setBank(
				banksData?.bankViewModel?.map((bank: { name: string }) => ({
					value: bank.name,
					label: bank.name,
				}))
			);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateField = (name: string, value: string) => {
		let error = "";

		switch (name) {
			case "businessName":
				if (!formData.isIndividual && !value)
					error = "Business name is required.";
				break;
			case "accountNumber":
				if (!value) error = "Account number is required.";
				else if (value.length !== 10)
					error = "Account number must be 10 digits.";
				break;
			case "bankName":
				if (!value) error = "Bank is required.";
				break;
			case "bvn":
				if (formData.isIndividual && (!value || value.length !== 11))
					error = "BVN must be 11 digits.";
				break;
			case "nin":
				if (formData.isIndividual && (!value || value.length !== 11))
					error = "NIN must be 11 digits.";
				break;
			case "registrationNumber":
				if (!formData.isIndividual && !value)
					error = "Registration number is required for businesses.";
				break;
			case "requesterEmailAddress":
				if (!value) error = "Email is required.";
				else if (!/\S+@\S+\.\S+/.test(value)) error = "Email is invalid.";
				break;
			default:
				break;
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	const checkFormValidity = () => {
		const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

		const commonValidations =
			formData.bankName &&
			formData.accountNumber &&
			formData.accountNumber.length === 10 &&
			formData.requesterEmailAddress &&
			isEmailValid(formData.requesterEmailAddress);

		const individualValidations =
			formData.isIndividual &&
			formData.bvn &&
			formData.bvn.length === 11 &&
			formData.nin &&
			formData.nin.length === 11;

		const businessValidations =
			!formData.isIndividual &&
			formData.businessName &&
			formData.registrationNumber;

		setIsFormValid(
			commonValidations &&
				(formData.isIndividual ? individualValidations : businessValidations) &&
				!Object.values(errors).some((error) => error !== "")
		);
	};

	useEffect(() => {
		checkFormValidity();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData, errors]);

	const handleInputChange = (name: string, value: string) => {
		handleChange(name, value);
		validateField(name, value);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (isFormValid) {
			nextStep();
		}
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Automated Reminder</h6>
			<p className={styles.sub}>
				Add new automated reminder for{" "}
				{formData.isIndividual ? "individual" : "business"}
			</p>
			<div className={styles.onBoardContainer}>
				{!formData.isIndividual && (
					<InputField
						label={"Business Name"}
						name={"businessName"}
						placeholder={"Enter business name"}
						value={formData.businessName}
						onChange={(name, e) => handleInputChange(name, e.target.value)}
						isRequired
						autoComplete="given-name"
						error={errors.businessName}
					/>
				)}
				<InputField
					label="Account Number"
					name="accountNumber"
					placeholder="Enter account number"
					value={formData.accountNumber}
					onChange={(name, e) => handleInputChange(name, e.target.value)}
					isRequired
					type="number"
					error={errors.accountNumber}
				/>
				<InputField
					label="Bank"
					name="bankName"
					type="select"
					placeholder="Select bank"
					value={formData.bankName}
					onChange={(name, e) => handleInputChange(name, e.target.value)}
					selectOptions={bank}
					isRequired
					error={errors.bankName}
				/>
				{formData.isIndividual && (
					<>
						<InputField
							label="Bank Verification Number (BVN)"
							name="bvn"
							type="number"
							placeholder="Enter BVN"
							value={formData.bvn}
							onChange={(name, e) => handleInputChange(name, e.target.value)}
							isRequired
							error={errors.bvn}
						/>
						<InputField
							label="National Identification Number (NIN)"
							name="nin"
							type="number"
							placeholder="Enter NIN"
							value={formData.nin}
							onChange={(name, e) => handleInputChange(name, e.target.value)}
							isRequired
							error={errors.nin}
						/>
					</>
				)}
				{!formData.isIndividual && (
					<InputField
						label="RC Number"
						name="registrationNumber"
						placeholder="Enter business registration number"
						value={formData.registrationNumber}
						onChange={(name, e) => handleInputChange(name, e.target.value)}
						isRequired
						error={errors.registrationNumber}
					/>
				)}
				<InputField
					label="Email"
					name="requesterEmailAddress"
					placeholder="Enter email address"
					value={formData.requesterEmailAddress}
					onChange={(name, e) => handleInputChange(name, e.target.value)}
					isRequired
					autoComplete="email"
					type="email"
					error={errors.requesterEmailAddress}
				/>
				<button className="btn" type="submit" disabled={!isFormValid}>
					Next
				</button>
			</div>
		</form>
	);
};

export const ReminderStep3: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	resetForm,
}) => {
	const reminderFrequency = [
		{ value: "Daily", label: "Daily" },
		{ value: "Weekly", label: "Weekly" },
		{ value: "Monthly", label: "Monthly" },
		{ value: "Yearly", label: "Yearly" },
	];
	const { axiosFetch } = useAxiosFunction();
	const { userData, refresh } = useAuth();
	const [transactionType, setTransactionType] = useState<
		{ value: string; label: string }[]
	>([]);
	const [productName, setProductName] = useState<
		{ value: string; label: string }[]
	>([]);
	const [errors, setErrors] = useState({
		reminderFrequency: "",
		productName: "",
		transactionType: "",
	});

	const [isFormValid, setIsFormValid] = useState<boolean | string | number>(
		false
	);

	useEffect(() => {
		const fetchData = async () => {
			const transaction = await axiosFetch({
				method: "GET",
				url: "/TransactionTypes/GetAllTransactionTypes?pageNumber=1&pageSize=100",
			});

			setTransactionType(
				transaction?.transactionTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);

			const product = await axiosFetch({
				method: "GET",
				url: "/Products/GetProducts?pageNumber=1&pageSize=100",
			});

			setProductName(
				product?.productViewModel?.map((type: { name: string }) => ({
					value: type.name,
					label: type.name,
				}))
			);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateField = (name: string, value: string) => {
		let error = "";

		if (!value) {
			error = "This field is required.";
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	useEffect(() => {
		const isValid =
			formData.transactionType &&
			formData.reminderFrequency &&
			formData.productName &&
			!Object.values(errors).some((error) => error !== "");

		setIsFormValid(isValid);
	}, [formData, errors]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!isFormValid) return;

		const dataCommon = {
			reminderFrequency: formData.reminderFrequency,
			createdBy: userData?.publicId,
			productName: formData.productName,
			requesterEmailAddress: formData.requesterEmailAddress,
			bankName: formData.bankName,
			accountNumber: formData.accountNumber,
			transactionType: formData.transactionType,
		};

		const dataIndividual = {
			bvn: formData.bvn,
			nin: formData.nin,
		};

		const dataCorporate = {
			registrationNumber: formData.registrationNumber,
			businessName: formData.businessName,
		};

		toast.promise(
			axiosFetch({
				method: "POST",
				url: "/Credibilities/CreateAutomatedReminder",
				requestConfig: {
					data: {
						...dataCommon,
						...(formData.isIndividual ? dataIndividual : dataCorporate),
					},
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Adding reminder...",
				success: (res) => {
					if (res.isSuccessful) {
						if (resetForm) resetForm();
						console.log(res);
						refresh();
						return res.remark || "Automated reminder Created successfully!";
					}

					throw new Error(
						res.remark || res || "An error occurred, please try again!"
					);
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Transaction Details</h6>

			<p className={styles.sub}>Add new record into our database</p>

			<div className={styles.onBoardContainer}>
				<InputField
					label="Product Name"
					placeholder="Select product"
					name="productName"
					type="select"
					value={formData.productName}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={productName}
					error={errors.productName}
					isRequired
				/>

				<InputField
					label="Frequency"
					placeholder="Select frequency"
					name="reminderFrequency"
					type="select"
					value={formData.reminderFrequency}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={reminderFrequency}
					error={errors.reminderFrequency}
					isRequired
				/>

				<InputField
					label="Transaction Type"
					placeholder="Transaction type"
					name="transactionType"
					type="select"
					value={formData.transactionType}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={transactionType}
					error={errors.transactionType}
					isRequired
				/>

				<button className="btn" type="submit" disabled={!isFormValid}>
					Save
				</button>
			</div>
		</form>
	);
};
