import { FC } from "react";
import CountryInformation from "../components/CountryInformation/CountryInformation";

interface CountryInformationPageProps {
	who: "individual" | "business";
}

const CountryInformationPage: FC<CountryInformationPageProps> = ({
	who,
}): JSX.Element => {
	return (
		<>
			<CountryInformation who={who} />
		</>
	);
};

export default CountryInformationPage;
