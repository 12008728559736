import { createContext, ReactNode, useContext, useState } from "react";
import { UserType } from "./types";

interface AuthType {
	userData: UserType;
	setUserData: (data: Partial<UserType>) => void;
	refresh: () => void;
	refreshCount: number;
}

const Auth = createContext<AuthType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [refreshCount, setRefreshCount] = useState(0);
	const [userData, setUserDataState] = useState<UserType>({
		firstName: "",
		middleName: "",
		lastName: "",
		email: "",
		dateOfBirth: new Date(),
		businessType: "",
		identificationId: "",
		identificationType: "",
		proofOfIdentification: "",
		isIndividual: true,
		isStaff: true,
		bvn: "",
		profileImage: "",
		countryOfResidence: "",
		countryOfOrigin: "",
		stateOfOrigin: "",
		stateOfResidence: "",
		businessName: "",
		phoneNumber: "",
		nin: "",
		createdBy: "",
		dateCreated: new Date(),
		isAccountSuspended: false,
		isConfirmedStaff: false,
		publicId: "",
		userRole: "",
		balance: 0,
	});

	const setUserData = (data: Partial<UserType>) => {
		setUserDataState((prevData) => ({ ...prevData!, ...data }));
	};

	const refresh = () => {
		setRefreshCount((prev) => prev + 1);
	};

	return (
		<Auth.Provider value={{ userData, setUserData, refresh, refreshCount }}>
			{children}
		</Auth.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
	const context = useContext(Auth);

	if (!context) {
		throw new Error("useOnboarding must be used within an AuthProvider");
	}

	return context;
};
