import { FC } from "react";
import styles from "./styles.module.scss";

interface MoreProps {}

const More: FC<MoreProps> = (): JSX.Element => {
	return (
		<div className={styles.more} id="mainContainer">
			{/* <div className={styles.video}>
				<img alt="videos demo" src={video} />
			</div> */}

			<h5>
				dtt is a global financial security platform, and the fastest, first of
				its kind growing company in Africa.
			</h5>

			<h6>Who we are</h6>

			<div className={styles.contents}>
				<p>
					We aim to eliminate or reduce the effectiveness of bad-faith actors
					who create of low-trust environment via bribery, extortion, loan
					default, scams, theft, vendor payment defaults, wage payment default
					etc.
				</p>

				<p>
					We are committed to disrupting the information asymmetry that bad
					(financial) actors leverage in inflicting losses on unsuspecting
					individuals and entities. Our goal is to create high trust societies
					all around the globe.
				</p>
			</div>
		</div>
	);
};

export default More;
