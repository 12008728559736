import { FC } from "react";
import Policy from "../components/Policy/Policy";

interface PrivacyPolicyPageProps {}

const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = (): JSX.Element => {
	return <Policy />;
};

export default PrivacyPolicyPage;
