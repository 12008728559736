import { FC } from "react";
import star from "../../assets/images/star.png";
import styles from "./styles.module.scss";

interface AboutHeroProps {}

// const starts = [
// 	{
// 		icon: <ProcessedSvg />,
// 		title: "N800 Million +",
// 		sub: "processed",
// 	},
// 	{
// 		icon: <CustomerSvg />,
// 		title: "9 Million +",
// 		sub: "Customers and counting",
// 	},
// 	{
// 		icon: <SavedSvg />,
// 		title: "N12 Million",
// 		sub: "Saved so far",
// 	},
// 	{
// 		icon: <BusinessSvg />,
// 		title: "100,000",
// 		sub: "Businesses saved",
// 	},
// ];

const AboutHero: FC<AboutHeroProps> = (): JSX.Element => {
	return (
		<>
			<div className={styles.aboutHero} id="mainContainer">
				<p>About us</p>

				<h1>
					The database that helps <br /> you stay safe
					<div className={styles.star}>
						<img alt="star" src={star} />
					</div>
				</h1>

				<p>
					Verify the credibility of your person or entity of interest before
					transacting with them.
				</p>
			</div>

			{/* <div className={styles.starts}>
				<div className={styles.overlay} />

				<div className={styles.star2}>
					<img alt="star" src={star2} />
				</div>

				<div className={styles.contents} id="mainContainer">
					{starts.map((start, i) => (
						<div key={i} className={styles.start}>
							{start.icon}

							<div>
								<p>{start.title}</p>
								<p>{start.sub}</p>
							</div>
						</div>
					))}
				</div>
			</div> */}
		</>
	);
};

export default AboutHero;
