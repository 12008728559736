import { FC, useState } from "react";
import {
	ProbitySvg,
	SafetySvg,
	TrustSvg,
	ValiditySvg,
} from "../../assets/svg/Svg";
import ExpandableCard from "../ExpandableCard/ExpandableCard";
import styles from "./styles.module.scss";

interface BenefitsProps {}

const Benefits: FC<BenefitsProps> = (): JSX.Element => {
	const [activeIndex, setActiveIndex] = useState(0);

	const cardsData = [
		{
			icon: <SafetySvg className="" />,
			mainText: "Safety",
			subText:
				"Fostering confidence in informal sector interactions and peer-to-peer transactions to ensure reliable and secure exchanges among participants.",
		},
		{
			icon: <TrustSvg className="" />,
			mainText: "Trust",
			subText:
				"Creating a foundation of trust within informal sectors and peer-to-peer transactions to foster secure and reliable interactions among participants.",
		},
		{
			icon: <ProbitySvg className="" />,
			mainText: "Probity",
			subText:
				"Promoting integrity by helping to verify the integrity of corporate organisations through their ability to pay vendors and staff as and when due.",
		},
		{
			icon: <ValiditySvg className="" />,
			mainText: "Validity",
			subText:
				"Enhance trustworthiness by discouraging defaults and cultivating a culture of integrity within the informal sector to bolster credibility.",
		},
	];

	return (
		<div className={styles.benefitsContainer} id="mainContainer">
			<h4>Benefits</h4>

			<div className={styles.benefits}>
				{cardsData.map((card, index) => (
					<ExpandableCard
						key={index}
						index={index + 1}
						icon={card.icon}
						mainText={card.mainText}
						subText={card.subText}
						isActive={activeIndex === index}
						onClick={() => setActiveIndex(index)}
					/>
				))}
			</div>
		</div>
	);
};

export default Benefits;
