import { FC } from "react";
import Reminder from "../../components/Dashboard/Reminder/Reminder";

interface ReminderPageProps {}

const ReminderPage: FC<ReminderPageProps> = (): JSX.Element => {
	return (
		<>
			<Reminder />
		</>
	);
};

export default ReminderPage;
