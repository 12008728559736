import { FC } from "react";
import SignUpIndividual from "../components/SignUpIndividual/SignUpIndividual";

interface SignUpIndividualPageProps {}

const SignUpIndividualPage: FC<SignUpIndividualPageProps> = (): JSX.Element => {
	return (
		<>
			<SignUpIndividual />
		</>
	);
};

export default SignUpIndividualPage;
