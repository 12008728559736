import { FC } from "react";
import styles from "./styles.module.scss";

interface HistoryProps {}

const History: FC<HistoryProps> = (): JSX.Element => {
	return <div className={styles.history}>History</div>;
};

export default History;
