import React, { useState } from "react";
import { useFormContext } from "../../context/FormContext";
import { formatCurrency, formatDate } from "../../utils";
import { AddedRecordType } from "../Dashboard/Home/Home";
import StatusIndicator from "./StatusIndicator";
import styles from "./styles.module.scss";
import TableActions from "./TableActions";

const AddedRecordTable = ({
	addedRecord,
}: {
	addedRecord: AddedRecordType[];
}) => {
	const [selectedRecord, setSelectedRecord] = useState<string[]>([]);
	const { toggleForm, setSelectedId } = useFormContext();

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedRecord(addedRecord.map((record) => record.publicId));
		} else {
			setSelectedRecord([]);
		}
	};

	const handleSelectRecord = (publicId: string) => {
		setSelectedRecord((prevSelected) =>
			prevSelected.includes(publicId)
				? prevSelected.filter((id) => id !== publicId)
				: [...prevSelected, publicId]
		);
	};

	return (
		<div className={styles.table}>
			<div className={styles.tableContainer}>
				<table>
					<thead>
						<tr>
							<th align="center">
								<input
									type="checkbox"
									checked={selectedRecord.length === addedRecord.length}
									onChange={handleSelectAll}
								/>
							</th>

							<th align="left">Name</th>

							<th align="center">Amount</th>

							<th align="center">Due Date</th>

							<th align="center">Status</th>

							<th align="center">Action</th>
						</tr>
					</thead>
					<tbody>
						{addedRecord.map((record) => (
							<tr key={record.publicId}>
								<td align="center">
									<input
										type="checkbox"
										checked={selectedRecord.includes(record.publicId)}
										onChange={() => handleSelectRecord(record.publicId)}
									/>
								</td>

								<td align="left" className={styles.user}>
									<p>
										{record.recipientFirstName} {record.recipientLastName}
									</p>
									<p>{record.recipientEmailAddress}</p>
								</td>

								<td align="center">{formatCurrency(record.amountInvolved)}</td>

								<td align="center">{formatDate(record.dueDate)}</td>

								<td align="center">
									<StatusIndicator
										color={record.isSettled ? "green" : "red"}
										status={record.isSettled ? "Resolved" : "Unresolved"}
									/>
								</td>

								<td align="center">
									<TableActions
										actions={[
											{
												label: "Report Refund",
												onClick: () => {
													setSelectedId(record.publicId);
													toggleForm("refund");
												},
											},
										]}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AddedRecordTable;
