import React from "react";
import styles from "./styles.module.scss";

interface SwitchProps {
	checked: boolean;
	onChange: () => void;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange }) => {
	return (
		<label className={styles.switch}>
			<input type="checkbox" checked={checked} onChange={onChange} />
			<span
				className={`${styles.slider} ${checked ? styles.checked : ""}`}
			></span>
		</label>
	);
};

export default Switch;
