import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatCurrency, formatDate } from "../../../utils";
import Pagination from "../../Table/Pagination";
import styles from "./styles.module.scss";

type WalletViewModel = {
	publicId: string;
	userId: string;
	balance: number;
	businessName: string | null;
	identificationId: string | null;
	lifeTimeCostIncurred: number;
	earnedReferralBonus: number;
	isIndividual: boolean;
	isStaff: boolean | null;
	isConfirmedStaff: boolean | null;
	initialOutstandingDate: string | null;
	name: string | null;
	mostRecentTransactionType: string | null;
	mostRecentPaymentService: string | null;
	mostRecentTransactionAmount: number;
	nameOfLastTransactionBeneficiary: string | null;
};

const PaymentTable = ({
	setTotalTransaction,
}: {
	setTotalTransaction: (val: number) => void;
}) => {
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const { userData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [transaction, setTransactions] = useState<WalletViewModel[]>([]);
	const [totalPages, setTotalPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		const fetchData = async () => {
			const trans = await axiosFetch({
				method: "GET",
				url: `/Wallets/GetAllWallets?pageNumber=${currentPage}&pageSize=10&userId=${userData?.publicId}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			setTransactions(trans?.walletViewModel || []);
			setTotalTransaction(trans?.totalCount || 0);
			setTotalPage(Math.ceil(trans?.totalCount));
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, userData]);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedUsers(transaction.map((trans) => trans.publicId));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleSelectUser = (userId: string) => {
		setSelectedUsers((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	return (
		<div className={styles.table}>
			<div className={styles.tableContainer}>
				<table>
					<thead>
						<tr>
							<th align="center">
								<input
									type="checkbox"
									checked={selectedUsers.length === transaction.length}
									onChange={handleSelectAll}
								/>
							</th>

							<th align="left">Transaction Type</th>

							<th align="left">Service</th>

							<th align="left">Amount</th>

							<th align="left">Date</th>

							<th align="left">Transaction ID</th>
						</tr>
					</thead>
					<tbody>
						{transaction.map((trans) => (
							<tr key={trans.publicId}>
								<td align="center">
									<input
										type="checkbox"
										checked={selectedUsers.includes(trans.publicId)}
										onChange={() => handleSelectUser(trans.publicId)}
									/>
								</td>

								<td align="left" className={styles.user}>
									{trans.mostRecentTransactionType}
								</td>

								<td align="left">{trans.mostRecentPaymentService}</td>

								<td align="left">
									{formatCurrency(trans.mostRecentTransactionAmount)}
								</td>

								<td align="left">
									{trans.initialOutstandingDate &&
										formatDate(trans.initialOutstandingDate)}
								</td>

								<td align="left">{trans.identificationId}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{totalPages > 10 && (
				<Pagination
					total={totalPages / 10}
					current={currentPage}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default PaymentTable;
