import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Context
import { AuthProvider } from "./context/auth";

// Layout
import DashboardLayout from "./layout/DashboardLayout";
import RootLayout from "./layout/RootLayout";

// Pages
import App from "./App";
import AboutPage from "./pages/AboutPage";
import BusinessInformationPage from "./pages/BusinessInformationPage";
import ContactPage from "./pages/ContactPage";
import CountryInformationPage from "./pages/CountryInformationPage";
import Dashboard from "./pages/DashboardPage";
import HistoryPage from "./pages/DashboardPage/HistoryPage";
import PaymentPage from "./pages/DashboardPage/PaymentPage";
import SupportPage from "./pages/DashboardPage/SupportPage";
import UserManagementPage from "./pages/DashboardPage/UserManagementPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LogInPage from "./pages/LogInPage";
import OtpPage from "./pages/OtpPage";
import PersonalInformationPage from "./pages/PersonalInformationPage";
import PricingPage from "./pages/PricingPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SignUpCorporatePage from "./pages/SignUpCorporatePage";
import SignUpIndividualPage from "./pages/SignUpIndividualPage";
import SignUpPage from "./pages/SignUpPage";

// Global styles
import { FormProvider } from "./context/FormContext";
import ReminderPage from "./pages/DashboardPage/ReminderPage";
import SettingsPage from "./pages/DashboardPage/SettingsPage";
import TermsPage from "./pages/TermsPage";
import "./style/GlobalStyle.scss";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<Toaster containerStyle={{ zIndex: "99999999999" }} />

		<AuthProvider>
			<FormProvider>
				<BrowserRouter>
					<Routes>
						<Route element={<RootLayout />}>
							<Route path="/" element={<App />} />
							<Route path="/about-us" element={<AboutPage />} />
							<Route path="/pricing" element={<PricingPage />} />
							<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
							<Route path="/terms-of-use" element={<TermsPage />} />
							<Route path="/contact" element={<ContactPage />} />
						</Route>

						<Route path="/log-in" element={<LogInPage />} />
						<Route path="/verification" element={<OtpPage />} />
						<Route path="/forgot-password" element={<ForgotPasswordPage />} />
						<Route path="/reset-password" element={<ResetPasswordPage />} />
						<Route path="/sign-up" element={<SignUpPage />} />
						<Route
							path="/sign-up/individual"
							element={<SignUpIndividualPage />}
						/>
						<Route
							path="/sign-up/corporate"
							element={<SignUpCorporatePage />}
						/>
						<Route
							path="/sign-up/individual/personal-information"
							element={<PersonalInformationPage />}
						/>
						<Route
							path="/sign-up/individual/personal-information/country-information"
							element={<CountryInformationPage who="individual" />}
						/>
						<Route
							path="/sign-up/corporate/business-information"
							element={<BusinessInformationPage />}
						/>
						<Route
							path="/sign-up/corporate/business-information/country-information"
							element={<CountryInformationPage who="business" />}
						/>

						<Route element={<DashboardLayout />}>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route
								path="/dashboard/automated-reminder"
								element={<ReminderPage />}
							/>
							<Route path="/dashboard/settings" element={<SettingsPage />} />
							<Route
								path="/dashboard/user-management"
								element={<UserManagementPage />}
							/>
							<Route path="/dashboard/history" element={<HistoryPage />} />
							<Route path="/dashboard/payment" element={<PaymentPage />} />
							<Route path="/dashboard/support" element={<SupportPage />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</FormProvider>
		</AuthProvider>
	</React.StrictMode>
);
