import { FC } from "react";
import styles from "./styles.module.scss";

interface TermsProps {}

const Terms: FC<TermsProps> = (): JSX.Element => {
	return (
		<div id="mainContainer" className={styles.terms}>
			<h1>Terms of Use</h1>

			<p>
				Welcome to Domestic Transaction Tracker Limited ("we," "us," "our"). By
				accessing or using our services, you agree to comply with and be bound
				by these Terms of Use. If you do not agree with these terms, please do
				not use our services.
			</p>

			<h2>Eligibility</h2>
			<p>
				You must be at least 16 years old or a registered organization to use
				our services. By using our services, you represent and warrant that you
				meet this requirement.
			</p>

			<h2>Account Registration</h2>
			<p>
				To access certain features, you may be required to create an account and
				provide accurate and complete information. You are responsible for
				maintaining the confidentiality of your account credentials and for all
				activities that occur under your account.
			</p>

			<h2>Use of Services</h2>
			<ol>
				<li>
					<strong>Permitted Use:</strong> You may use our services solely for
					legal purposes.
				</li>
				<li>
					<strong>Prohibited Use:</strong> You agree not to use our services for
					any unlawful purpose, or in any way that could damage, disable, or
					impair our services.
				</li>
				<li>
					<strong>Intellectual Property:</strong> All content and materials
					available through our services, including text, graphics, logos,
					icons, images, and software, are the property of Domestic Transaction
					Tracker Limited or its licensors and are protected by applicable
					copyright and trademark laws. Unauthorized use of any content may
					violate these laws.
				</li>
			</ol>

			<h2>User Content</h2>
			<p>
				You may be able to post or upload content, including reviews, comments,
				or other materials ("User Content"). By providing User Content, you
				grant us a non-exclusive, royalty-free, perpetual, irrevocable, and
				fully sublicensable right to use, reproduce, modify, adapt, publish,
				translate, create derivative works from, distribute, and display such
				content throughout the world in any media.
			</p>

			<h2>Dispute Resolution</h2>
			<ol>
				<li>
					<strong>Arbitration:</strong> Any disputes arising out of or relating
					to these Terms of Use or our services shall be resolved through
					binding arbitration, rather than in court.
				</li>
				<li>
					<strong>Waiver of Class Action:</strong> You agree to waive any right
					to participate in a class action lawsuit.
				</li>
			</ol>

			<h2>Limitation of Liability</h2>
			<p>
				To the fullest extent permitted by law, Domestic Transaction Tracker
				Limited shall not be liable for any indirect, incidental, special,
				consequential, or punitive damages arising out of or relating to your
				use of our services, even if we have been advised of the possibility of
				such damages.
			</p>

			<h2>Indemnification</h2>
			<p>
				You agree to indemnify, defend, and hold harmless Domestic Transaction
				Tracker Limited, its affiliates, and their respective officers,
				directors, employees, and agents from and against any and all claims,
				damages, liabilities, costs, and expenses (including reasonable
				attorneys' fees) arising out of or related to your use of our services
				or any violation of these Terms of Use.
			</p>

			<h2>Termination</h2>
			<p>
				We reserve the right to terminate or suspend your access to our services
				at any time, without notice, for any reason, including for violation of
				these Terms of Use.
			</p>

			<h2>Changes to These Terms</h2>
			<p>
				We may update these Terms of Use from time to time. When we do, we will
				revise the "last updated" date at the top of these terms. Your continued
				use of our services after any changes constitutes your acceptance of the
				new terms.
			</p>

			<h2>Contact Us</h2>
			<p>
				If you have any questions about this privacy policy or our data
				practices, please contact us at: customercare@dtt.ng or by filling the
				"contact" form on our website.
			</p>
		</div>
	);
};

export default Terms;
