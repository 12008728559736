import axios from "axios";
import { FC, useCallback, useState } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../../api/adminBaseApi";
import { useAuth } from "../../context/auth";
import styles from "./styles.module.scss";

interface UploadBoxProps {
	label: string;
	isRequired?: boolean;
	onUpload: (uploads: File[]) => void;
	upload?: {
		getUploadedImage: (publicURL: string) => void;
		types: {
			isTransaction: boolean;
			isProofOfTransactionRequest: boolean;
			isReceiptOfPayment: boolean;
			isReceiptOfRefund: boolean;
		};
	};
	error?: string;
	maxUploadSize?: number;
	accept?: {
		type: Accept;
		file: string;
	};
}

const UploadBox: FC<UploadBoxProps> = ({
	label,
	onUpload,
	isRequired,
	error,
	upload,
	maxUploadSize = 5,
	accept = {
		type: {
			"image/png": [".png"],
			"image/jpeg": [".jpg", ".jpeg"],
		},
		file: "PNG, or JPG",
	},
}) => {
	const { userData } = useAuth();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [uploadedImage, setUploadedImage] = useState<string | null>(null);

	const createUpload = async (file: File) => {
		if (!upload) return;

		const data = new FormData();
		data.append("uploadFile", file);
		data.append("createdBy", userData?.publicId || "");
		data.append("isTransaction", `${upload.types.isTransaction}`);
		data.append(
			"isProofOfTransactionRequest",
			`${upload.types.isProofOfTransactionRequest}`
		);
		data.append("isReceiptOfPayment", `${upload.types.isReceiptOfPayment}`);
		data.append("isReceiptOfRefund", `${upload.types.isReceiptOfRefund}`);

		try {
			toast.promise(
				axios.post(API_BASE_URL + "Uploads/CreateUpload", data, {
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "application/json",
						Authorization: `Bearer ${userData?.token}`,
					},
				}),
				{
					loading: "Uploading...",
					success: (res) => {
						if (res.data.isSuccessful) {
							const publicImg = res.data.filePath;
							if (upload && publicImg) upload.getUploadedImage(publicImg);

							setUploadedImage(URL.createObjectURL(file));

							return res.data.remark || "Uploaded successfully!";
						}

						throw new Error(
							res.data.remark || "An error occurred, please try again!"
						);
					},
					error: (err) => {
						if (axios.isAxiosError(err)) {
							return (
								err.response?.data.title ||
								err.response?.data.remark ||
								err.message
							);
						}
						return err.message;
					},
				}
			);
		} catch (error) {
			toast.error("Error submitting the form");
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			setErrorMessage(null);

			const imageFile = acceptedFiles[0];
			if (imageFile) {
				if (upload) {
					createUpload(imageFile);
					onUpload(acceptedFiles);
				} else {
					setUploadedImage(URL.createObjectURL(imageFile));
					onUpload(acceptedFiles);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onUpload]
	);

	const onDropRejected = (fileRejections: FileRejection[]) => {
		const rejection = fileRejections[0];
		if (rejection?.errors[0]?.code === "file-too-large") {
			setErrorMessage(`File is too large. Max size is ${maxUploadSize}MB.`);
		} else {
			setErrorMessage(`File type not supported. Only ${accept.file} allowed.`);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		onDropRejected,
		accept: accept.type,
		maxSize: maxUploadSize * 1024 * 1024,
	});

	return (
		<div className={styles.uploadBoxContainer}>
			<div
				{...getRootProps()}
				className={`${styles.uploadBox} ${isDragActive ? styles.active : ""}`}
			>
				<input {...getInputProps()} required={isRequired} />

				<div className={styles.uploadIcon}>
					<svg
						width="48"
						height="48"
						viewBox="0 0 48 48"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M41.0079 21.041L41.0101 21.0498L41.0126 21.0585C41.0549 21.205 41.0699 21.364 41.0555 21.54L41.052 21.583L41.0559 21.6259C41.0581 21.6503 41.0586 21.6867 41.0586 21.7541L41.0586 38.9042C41.0586 38.9046 41.0586 38.905 41.0586 38.9053C41.0541 40.2004 39.9974 41.2557 38.7039 41.2557H9.30469C8.68152 41.2546 8.08418 41.0066 7.64354 40.566C7.20298 40.1254 6.95499 39.5282 6.95387 38.9052V21.7541C6.95387 21.6973 6.95742 21.6379 6.96227 21.5603L6.96547 21.5091L6.95822 21.4584C6.936 21.3029 6.94753 21.1364 6.9934 20.9636L6.99345 20.9636L6.99485 20.958L6.99796 20.9507L7.00222 20.9393L11.9194 7.71113L11.9255 7.69483L11.9304 7.67816C12.0928 7.12676 12.6054 6.74316 13.1804 6.74316H34.4992C35.0701 6.74316 35.5766 7.11875 35.7467 7.66646L35.7522 7.68409L35.7589 7.70127L41.0064 21.0351L41.0079 21.041ZM10.2946 18.7139L10.0107 19.4041H10.757H19.9133L19.918 19.649C19.9387 20.8 20.3305 21.8129 21.0795 22.5509C21.83 23.2905 22.8543 23.6604 23.9992 23.6604C25.1744 23.6604 26.1992 23.2666 26.9373 22.5476C27.6757 21.8284 28.0922 20.8177 28.1131 19.6496C28.1132 19.6494 28.1132 19.6492 28.1132 19.6489L28.1178 19.4041H37.0257H37.7799L37.4863 18.7094L33.6566 9.64851L33.5276 9.34316H33.1961H14.4836H14.1486L14.0211 9.65298L10.2946 18.7139ZM10.0492 22.0041H9.54918V22.5041V38.1557V38.6557H10.0492H37.9492H38.4492V38.1557V22.5041V22.0041H37.9492H30.6132H30.2832L30.1535 22.3076C29.6626 23.4558 28.9089 24.4019 27.9492 25.0763L27.949 25.0764C26.8552 25.8459 25.4907 26.2604 24.0039 26.2604C21.1782 26.2604 18.9404 24.7937 17.8827 22.3083L17.7532 22.0041H17.4226H10.0492Z"
							fill="#0866FF"
							stroke="#0866FF"
						/>
					</svg>
				</div>

				<p>
					Click or drop here to upload <br /> <span>{label}</span>
				</p>

				<small>
					{accept.file}. Max size of {maxUploadSize}MB.
				</small>
			</div>

			{errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
			{error && <p className={styles.errorMessage}>{error}</p>}

			{uploadedImage && (
				<div className={styles.imagePreview}>
					<img src={uploadedImage} alt="Uploaded preview unavailable" />
				</div>
			)}
		</div>
	);
};

export default UploadBox;
