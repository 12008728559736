import { FC } from "react";
import sweet from "../../assets/images/deco.png";
import star from "../../assets/images/star2.png";
import styles from "./styles.module.scss";

interface ServicesProps {}

const Services: FC<ServicesProps> = (): JSX.Element => {
	return (
		<div className={styles.servicesContainer} id="mainContainer">
			<h4>Our Services</h4>

			<div className={styles.services}>
				<article className="left">
					<div className={styles.star}>
						<img alt="star" src={star} />
					</div>

					<h5>For Corporate</h5>

					<ul>
						<li>One free automated reminder to stakeholders whose outstanding with you have become overdue.</li>

						<li>Assess the credibility of individual customers.</li>

						<li>
							Get monthly feedback on the credibility of staff.
						</li>

						<li>
							Make smart loan decisions by gaining insight into the customer's lending history, safe lending amounts, average repayment period, and history of default if any.
						</li>

						<li>
							Gain insight on the average time it take your corporate client to make repayments on credit offered in cash or kind.
						</li>

       <li>
        Gain insight on a corporate client's ability to pay staff and vendors as and when due.
       </li>
       
        <li>
         Gain access to a repository of credit worthy customers who may be interested in your credit products.
        </li>
					</ul>
				</article>

				<article className="right">
					<div className={styles.sweet}>
						<img alt="sweet" src={sweet} />
					</div>

					<h5>For Individual</h5>

					<ul>
						<li>
							Send one free automated reminder to those owing you money (a day after they promised to repay).
						</li>

						<li>
							Verify the credibility of your of your friends, family, loved ones, and associates before loaning them money.
						</li>

						<li>
							Build your credit worthiness by encouraging people who loan you money to update your lending history with us.
						</li>

						<li>
							Verify the credibility of online vendors before paying for goods.
						</li>

						<li>
							Verify the credibility of anyone you are engaging in peer-to-peer transactions with.
						</li>
					</ul>
				</article>
			</div>
		</div>
	);
};

export default Services;
