import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./chartsStyles.module.scss";

interface DonutChartProps {
	filter: "daily" | "weekly" | "monthly";
}

const DonutChart: React.FC<DonutChartProps> = ({ filter }) => {
	const donutData = {
		daily: [10, 20, 30, 40],
		weekly: [11, 24, 26, 39],
		monthly: [15, 25, 35, 25],
	};

	const chartOptions: ApexOptions = {
		chart: {
			type: "donut",
		},
		labels: ["Approved", "Pending", "Under review", "Rejected"],
		colors: ["#032B6B", "#0866FF", "#B2D0FF", "#FFA108"],
		plotOptions: {
			pie: {
				donut: {
					size: "50%",
					labels: {
						show: true,
						total: {
							show: true,
							label: "Average",
							formatter: () => "1.05",
						},
					},
				},
			},
		},
		legend: {
			show: false,
		},
	};

	const chartSeries = donutData[filter];

	return (
		<div className={styles.donutContainer}>
			<div className={styles.donutHeader}>
				<p>Statistics</p>

				<h3>
					<span>{filter}</span> activity
				</h3>
			</div>

			<div className={styles.donutContents}>
				<div className={styles.donutChart}>
					<ReactApexChart
						options={chartOptions}
						series={chartSeries}
						type="donut"
						height={300}
					/>
				</div>

				<div className={styles.legend}>
					<ul>
						<li>
							<span style={{ backgroundColor: "#032B6B" }} /> Approved - 10,000
						</li>

						<li>
							<span style={{ backgroundColor: "#0866FF" }} /> Pending - 4,000
						</li>

						<li>
							<span style={{ backgroundColor: "#B2D0FF" }} /> Under review -
							3,000
						</li>

						<li>
							<span style={{ backgroundColor: "#FFA108" }} /> Rejected - 1,200
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DonutChart;
