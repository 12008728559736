import { FC } from "react";
import Settings from "../../components/Dashboard/Settings/Settings";

interface SettingsPageProps {}

const SettingsPage: FC<SettingsPageProps> = (): JSX.Element => {
	return (
		<>
			<Settings />
		</>
	);
};

export default SettingsPage;
