import jsPDF from "jspdf";
import React, { FC, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ExportSvg } from "../../../assets/svg/Svg";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatDate } from "../../../utils";
import Pagination from "../../Table/Pagination";
import StatusIndicator from "../../Table/StatusIndicator";
import TableActions from "../../Table/TableActions";
import styles from "./styles.module.scss";

interface UserManagementProps {}

interface User {
	firstName: string;
	middleName: string;
	lastName: string;
	groupName: string | null;
	email: string;
	dateOfBirth: string;
	businessType: string;
	identificationId: string;
	identificationType: string;
	proofOfIdentification: string;
	isIndividual: boolean;
	bvn: string | null;
	isVerified: boolean;
	userRole: string;
	profileImage: string;
	countryOfResidence: string;
	countryOfOrigin: string;
	stateOfOrigin: string;
	stateOfResidence: string;
	businessName: string;
	phoneNumber: string | null;
	publicId: string;
	registrationStatus: number;
	nin: string | null;
	isAccountSuspended: boolean;
}

const UserManagement: FC<UserManagementProps> = (): JSX.Element => {
	const { userData, refreshCount } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [users, setUser] = useState<User[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [filterStatus, setFilterStatus] = useState("All");
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const fetchData = async () => {
		const getUserRes = await axiosFetch({
			method: "GET",
			url: `/Users/GetAllUsers?pageNumber=${currentPage}&pageSize=10`,
			requestConfig: {
				headers: {
					Authorization: `Bearer ${userData?.token}`,
				},
			},
		});

		setUser(getUserRes?.userViewModel || []);
		setTotalPages(getUserRes?.totalCount || 0);
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, refreshCount]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const filteredUsers = useMemo(() => {
		return users.filter(
			(user) =>
				(filterStatus === "All" ||
					(filterStatus === "Active" && user.isVerified) ||
					(filterStatus === "Blocked" && !user.isVerified) ||
					(filterStatus === "Access" && user.userRole === "Corporate") ||
					(filterStatus === "Admin" && user.userRole === "Admin")) &&
				`${user.firstName}${user.lastName} ${user.businessName}`
					.toLowerCase()
					.includes(searchTerm.toLowerCase())
		);
	}, [users, filterStatus, searchTerm]);

	const exportToPDF = () => {
		const selectedUserData = users.filter((user) =>
			selectedUsers.includes(user.publicId)
		);

		if (selectedUserData.length === 0) {
			toast.error("Select a user");

			return;
		}

		const doc = new jsPDF();

		let y = 10;
		const lineHeight = 10;
		const pageHeight = doc.internal.pageSize.height;

		selectedUserData.forEach((user) => {
			const userText = `
			Customer: ${user.firstName}${user.lastName} (${user.email})
		`;

			const textLines = doc.splitTextToSize(userText, 180);

			if (y + textLines.length * lineHeight > pageHeight) {
				doc.addPage();
				y = 10;
			}

			doc.text(textLines, 10, y);
			y += textLines.length * lineHeight;
		});

		doc.save("selected_users.pdf");
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedUsers(filteredUsers.map((user) => user.publicId));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleSelectUser = (userId: string) => {
		setSelectedUsers((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	const nav = [
		{
			name: "All Users",
			filter: "All",
		},
		{
			name: "Verified",
			filter: "Active",
		},
		{
			name: "Not Verified",
			filter: "Blocked",
		},
		{
			name: "Corporate",
			filter: "Access",
		},
		{
			name: "Admin",
			filter: "Admin",
		},
	];

	const deleteUser = async (userId: string) => {
		toast.promise(
			axiosFetch({
				method: "DELETE",
				url: "/Users/DeleteUser",
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
					data: {
						userId,
						deletedBy: userData?.publicId,
					},
				},
			}),
			{
				loading: "Deleting...",
				success: (res) => {
					if (res.isSuccessful) {
						fetchData();
						return res.remark || "Deleted successfully!";
					}

					throw new Error(res.remark || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.userManagement}>
			<div className={styles.header}>
				<p className={styles.title}>User Management</p>

				<div>
					<button type="button" className="btn-secondary" onClick={exportToPDF}>
						<ExportSvg className="" />

						<p>Export</p>
					</button>

					{/* <button
						type="button"
						className="btn"
						onClick={() => toggleForm("onboard-user")}
					>
						<div>
							<AddSvg />
						</div>

						<p>New User</p>
					</button> */}
				</div>
			</div>

			<div className={styles.nav}>
				{nav.map((nv, i) => (
					<button
						onClick={() => setFilterStatus(nv.filter)}
						key={i}
						type="button"
						className={`${filterStatus === nv.filter && styles.active}`}
					>
						{nv.name}
					</button>
				))}
			</div>

			<div className={styles.searchContainer}>
				<span className={styles.icon}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.66732 13.9997C11.1651 13.9997 14.0007 11.1641 14.0007 7.66634C14.0007 4.16854 11.1651 1.33301 7.66732 1.33301C4.16951 1.33301 1.33398 4.16854 1.33398 7.66634C1.33398 11.1641 4.16951 13.9997 7.66732 13.9997Z"
							stroke="#646667"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M14.6673 14.6663L13.334 13.333"
							stroke="#646667"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<input
					onChange={handleSearchChange}
					type="text"
					placeholder="Search User  by name or any related keywords "
					value={searchTerm}
					className={styles.searchInput}
				/>
			</div>

			<div className={styles.tableContainer}>
				<div className={styles.table}>
					<table>
						<thead>
							<tr>
								<th align="center">
									<input
										type="checkbox"
										checked={selectedUsers.length === filteredUsers.length}
										onChange={handleSelectAll}
									/>
								</th>

								<th align="left">User</th>

								<th align="left">Phone</th>

								<th align="left">Date oF Birth</th>

								<th align="center">Role</th>

								<th align="center">Status</th>

								<th align="center">Action</th>
							</tr>
						</thead>
						<tbody>
							{filteredUsers.map((user) => (
								<tr key={user.publicId}>
									<td align="center">
										<input
											type="checkbox"
											checked={selectedUsers.includes(user.publicId)}
											onChange={() => handleSelectUser(user.publicId)}
										/>
									</td>

									<td align="left" className={styles.user}>
										<p>
											{user.firstName} {user.lastName}
										</p>
										<p>{user.email}</p>
									</td>

									<td align="left">
										<p>{user.phoneNumber}</p>
									</td>

									<td align="left">
										<p>{formatDate(user.dateOfBirth)}</p>
									</td>

									<td align="center">
										<p>{user.userRole}</p>
									</td>

									<td align="center">
										<StatusIndicator
											color={user.isVerified ? "green" : "red"}
											status={user.isVerified ? "Verified" : "Not Verified"}
										/>
									</td>

									<td align="center">
										<TableActions
											actions={[
												{
													label: "Delete User",
													icon: (
														<svg
															width="18"
															height="18"
															viewBox="0 0 18 18"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.86664 1.67242L4.12414 3.08242C3.26164 3.40492 2.55664 4.42492 2.55664 5.33992V10.9124C2.55664 11.7974 3.14164 12.9599 3.85414 13.4924L7.07914 15.8999C8.13664 16.6949 9.87664 16.6949 10.9341 15.8999L14.1591 13.4924C14.8716 12.9599 15.4566 11.7974 15.4566 10.9124V5.33992C15.4566 4.41742 14.7516 3.39742 13.8891 3.07492L10.1466 1.67242C9.50914 1.43992 8.48914 1.43992 7.86664 1.67242Z"
																stroke="#515354"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M9 9.375C9.82843 9.375 10.5 8.70343 10.5 7.875C10.5 7.04657 9.82843 6.375 9 6.375C8.17157 6.375 7.5 7.04657 7.5 7.875C7.5 8.70343 8.17157 9.375 9 9.375Z"
																stroke="#515354"
																strokeWidth="1.5"
																strokeMiterlimit="10"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M9 9.375V11.625"
																stroke="#515354"
																strokeWidth="1.5"
																strokeMiterlimit="10"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													),
													onClick: () => deleteUser(user.publicId),
												},
											]}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{totalPages > 10 && (
				<Pagination
					total={totalPages / 10}
					current={currentPage}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default UserManagement;
