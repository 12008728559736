import { FC } from "react";
import styles from "./styles.module.scss";

interface PolicyProps {}

const Policy: FC<PolicyProps> = (): JSX.Element => {
	return (
		<div id="mainContainer" className={styles.policy}>
			<h1>Privacy Policy</h1>

			<p>
				Domestic Transaction Tracker Limited ("we," "us," or "our") is committed
				to protecting the privacy of our users ("you" or "your"). This privacy
				policy explains how we collect, use, disclose, and safeguard your
				information when you visit our website [website name.com], use our
				services, or interact with us.
			</p>

			<h2>Information We Collect</h2>
			<p>
				<strong>Personal Information:</strong> We may collect personally
				identifiable information, such as your name, address, email address,
				phone number, regulatory means of identification (BVN, NIN, business
				registration details), and other financial information.
			</p>
			<p>
				<strong>Transaction Information:</strong> This includes data about your
				informal transaction history, semi- formal transaction history,
				transaction bank accounts, payment history, and other related financial
				activities.
			</p>
			<p>
				<strong>Public Records:</strong> Information from public databases such
				as bankruptcies, liens, judgments, and other legal documents.
			</p>

			<h2>How We Collect Information</h2>
			<p>
				<strong>Directly From You:</strong> When you provide information through
				forms, applications, and other direct interactions.
			</p>
			<p>
				<strong>From Third Parties:</strong> Such as informal creditors,
				semi-formal creditors, lenders, and public records.
			</p>

			<h2>Use of Your Information</h2>
			<p>
				We may use the information we collect from you in the following ways:
			</p>
			<ul>
				<li>
					<strong>To Provide Services:</strong> Generate reports, monitor
					informal transaction activity, semi-formal transaction activity, and
					provide other related services.
				</li>
				<li>
					<strong>To Improve Our Services:</strong> Analyze usage and improve
					our services.
				</li>
				<li>
					<strong>To Communicate:</strong> Send you notifications, alerts, and
					other communications related to your account or our services.
				</li>
				<li>
					<strong>To Comply with Legal Obligations:</strong> As required by law,
					court orders, or government regulations.
				</li>
			</ul>

			<h2>Data Sharing and Disclosure</h2>
			<ul>
				<li>
					<strong>With Your Consent:</strong> We may share your information with
					third parties when you give us your consent to do so.
				</li>
				<li>
					<strong>With The General Public:</strong> We may share summarized
					reports of transactions (which may leverage your data) without
					exposing your Personally Identifiable Information (PII).
				</li>
				<li>
					<strong>With Service Providers:</strong> We may share information with
					third-party service providers who perform services on our behalf.
				</li>
				<li>
					<strong>For Legal Reasons:</strong> If required to do so by law, or to
					protect the rights, property, or safety of us, our users, or others.
				</li>
			</ul>

			<h2>Data Security</h2>
			<p>
				We use administrative, technical, and physical security measures to help
				protect your personal information. While we have taken reasonable steps
				to secure the personal information you provide to us, please be aware
				that despite our efforts, no security measures are perfect or
				impenetrable, and no method of data transmission can be guaranteed
				against any interception or other type of misuse.
			</p>

			<h2>Your Rights</h2>
			<ul>
				<li>
					<strong>Access:</strong> You have the right to request a copy of the
					personal data we hold about you.
				</li>
				<li>
					<strong>Correction:</strong> You have the right to request correction
					of any inaccuracies.
				</li>
				<li>
					<strong>Deletion:</strong> You have the right to request deletion of
					your personal data, subject to certain legal obligations.
				</li>
			</ul>

			<h2>Cookies and Tracking Technologies</h2>
			<p>
				We may use cookies, web beacons, and other tracking technologies to
				collect information about your online activities. This information helps
				us understand how you use our website and improve your online
				experience.
			</p>

			<h2>Contact Us</h2>
			<p>
				If you have any questions about this privacy policy or our data
				practices, please contact us at: customercare@dtt.ng or by filling the
				"contact" form on our website.
			</p>

			<h2>Changes to This Policy</h2>
			<p>
				We may update this privacy policy from time to time. When we do, we will
				revise the "last updated" date at the top of this policy. We encourage
				you to review this policy periodically to stay informed about how we are
				protecting your information.
			</p>
		</div>
	);
};

export default Policy;
