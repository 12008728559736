import img1 from "../assets/images/twitter/1.jpg";
import img10 from "../assets/images/twitter/10.jpg";
import img11 from "../assets/images/twitter/11.jpg";
import img12 from "../assets/images/twitter/12.jpg";
import img13 from "../assets/images/twitter/13.jpg";
import img14 from "../assets/images/twitter/14.jpg";
import img15 from "../assets/images/twitter/15.jpg";
import img16 from "../assets/images/twitter/16.jpg";
import img17 from "../assets/images/twitter/17.jpg";
import img18 from "../assets/images/twitter/18.jpg";
import img2 from "../assets/images/twitter/2.jpg";
import img3 from "../assets/images/twitter/3.jpg";
import img4 from "../assets/images/twitter/4.jpg";
import img5 from "../assets/images/twitter/5.jpg";
import img6 from "../assets/images/twitter/6.jpg";
import img7 from "../assets/images/twitter/7.jpg";
import img8 from "../assets/images/twitter/8.jpg";
import img9 from "../assets/images/twitter/9.jpg";

import {
	DashSvg,
	ManageSvg,
	PaymentSvg,
	ReminderSvg,
} from "../assets/svg/DashNavSvg";

export const navData = ["Pricing", "About Us", "Contact"];
export const footerData = [
	"Pricing",
	"About Us",
	"Contact",
	"Privacy Policy",
	"Terms Of Use",
];

export const twitterData = [
	{
		profileImage: img1,
		username: "@Tutsy22",
		name: "Adetutu Balogun, MBA",
		content:
			"Some of the loans you get from people do not come from a place of surplus. It comes from sacrifice. Stop abusing their generosity! You've abused my patience for 2 years! I know you are reading this tweet. This is not the end, its the calm before the storm! Answer my calls...",
		time: "11:15 AM",
		date: "19 May 2023",
		views: "71.3K",
		link: "https://twitter.com/Tutsy22/status/1659503143867088896?t=l_1N1QGvUmlTzBLcyajbNA&s=08",
		verified: true,
	},
	{
		profileImage: img2,
		username: "@_kambuu",
		name: "nana kofi",
		content:
			"I gave my old boy my savings to buy a bicycle for me. He came back home in the evening with tubers of yam and a bottle of oil and told me the bicycle will spoil my testicles.",
		time: "8:05 PM",
		date: "23 May 2023",
		views: "530.6K",
		link: "https://twitter.com/_kambuu/status/1661085961189507087?t=x578czRpnkAAtsuigUlj9A&s=08",
		verified: false,
	},
	{
		profileImage: img3,
		username: "@Oloye",
		name: "Olóyè.",
		content:
			"A lot of us remain poor because of our low trust business environment. The biggest economies don't run on cash, they run on credit. Credit is a trust based. The reason why a lot of people don't have access to capital for their amazing biz ideas is the low trust environment.",
		time: "9:56 AM ",
		date: "27 Apr 2023",
		views: "129.6K",
		link: "https://twitter.com/Ol0ye/status/1651510741877309443?t=sgQloR4guTGZq8ZjhFwOfw&s=08",
		verified: true,
	},
	{
		profileImage: img3,
		username: "@Oloye",
		name: "Olóyè.",
		content:
			"So we're all cheating ourselves out of future fortunes by being as dishonest as we are. I am sure there are times in your life or business where you haven't bought something on the account that the buyer wants to pay on delivery/the seller insists on payment before delivery.",
		time: "10:32 AM",
		date: "27 Apr 2023",
		views: "175",
		link: "https://twitter.com/Ol0ye/status/1651510741877309443?t=sgQloR4guTGZq8ZjhFwOfw&s=08",
		verified: true,
	},
	{
		profileImage: img3,
		username: "@Oloye",
		name: "Olóyè.",
		content:
			"In a high trust system, this will not even factor. But because we have no trust in eachother, we are forced to cancel that transaction. Need doesn't get fulfilled on the buyers side. Capital tied down on sellers part. Just because we'll rather be safe than sorry.",
		time: "11:32 AM",
		date: "27 Apr 2023",
		views: "6.7K",
		link: "https://twitter.com/Ol0ye/status/1651510741877309443?t=sgQloR4guTGZq8ZjhFwOfw&s=08",
		verified: true,
	},
	{
		profileImage: img4,
		username: "@SamuelOtigba",
		name: "Sam Otigba",
		content:
			'I called someone I loaned money to and asked when he plans on paying back. His reply "all the money you are making, what are you doing with it".',
		time: "3:44 PM",
		date: "21 Mar 2023",
		views: "315.3K",
		link: "https://twitter.com/SamuelOtigba/status/1638189958056820738?t=DKeVHufDs95XX7lkNyZykA&s=08",
		verified: true,
	},
	{
		profileImage: img5,
		username: "@Desmondnugzy",
		name: "Nugzy Nugzan",
		content:
			"My woman once told me that the 2 guys I called my best pals didn't like me. E no reach one year one of them was aware the other guy was running out of the country with the money and I lent him and didn't tell me.",
		time: "9:59 AM",
		date: "9 Apr 2023",
		views: "1.7K",
		link: "https://twitter.com/Desmondnugzy/status/1644988277647179776?t=b0vINzRbe45qjJypMKqlnA&s=08",
		verified: false,
	},
	{
		profileImage: img6,
		username: "@jon_d_doe",
		name: "Agbà John Doe",
		content:
			"If you're a married man or in a relationship, & a woman comes to ask you to borrow her money. If you wish to lend, ask your wife or girlfriend to be the one to lend her. And let them know you're not the one lending them the money. Let's see if they'll not pay back. End.",
		time: "2:01 PM",
		date: "8 Apr 2023",
		views: "151.1K",
		link: "https://twitter.com/jon_d_doe/status/1644687002607009793?s=08",
		verified: true,
	},
	{
		profileImage: img7,
		username: "@pyjama_ceo",
		name: "Edmund of the Big Bag",
		content:
			"I just received pics of the guy who defrauded my friends & I of $700k in 2019. He is languishing in a S.Leone jail. I may never get the money back but 2 things are guaranteed: 1. His behavior didn't stop me from trusting and believing in people. 2. He will be gnashing his teeth.",
		time: "3:22 PM",
		date: "28 Apr 2023",
		views: "163.1K",
		link: "https://twitter.com/pyjama_ceo/status/1651955052917948418?t=0F-v58AySLusrXBO2y4Ycg&s=08",
		verified: false,
	},
	{
		profileImage: img8,
		username: "@FS_Yusuf_",
		name: "FS Yusuf",
		content:
			"You dey ask person to borrow you money, you dey on disappearing message for WhatsApp. Una get mind o. Wetin person go take do evidence??",
		time: "3:30 PM",
		date: "30 May 2023",
		views: "55.4K",
		link: "https://twitter.com/FS_Yusuf_/status/1663553375898857474?s=19",
		verified: true,
	},
	{
		profileImage: img9,
		username: "@ScentsByLimmah",
		name: "LIMMAH B",
		content: "Even the one I trusted haven't refunded my 74k till now.",
		time: "1:18 PM",
		date: "4 Jun 2023",
		views: "412",
		link: "https://twitter.com/ScentsByLimmah/status/1665332209832665093?t=NvIZiGJ-eGXdahQNcW2WJA&s=19",
		verified: false,
	},
	{
		profileImage: img10,
		username: "@upfortransfer",
		name: "Up for transfer",
		content:
			"I just remembered two people are owing me money since last year December One 89k, one 350k What should I do to them 🤔",
		time: "9:36 PM",
		date: "4 Jun 2023",
		views: "8.4K",
		link: "https://twitter.com/upfortransfer/status/1665457499288264704?t=fkbQiHhDnRLxQtoqABzlsQ&s=19",
		verified: false,
	},
	{
		profileImage: img11,
		username: "@hackSultan",
		name: "Name cannot be blank",
		content:
			"Invested in this business super early on, like 6 months now, no single investor update, no workings, nothing. Founder just collected funds and kept on flexing life, doesn't even reply my WhatsApp message again. LOL. 😂 counting my losses sha.",
		time: "5:38 PM",
		date: "18 Jun 2023",
		views: "308.2K",
		link: "https://twitter.com/hackSultan/status/1670471112830885890?t=6e6KzTqazGdcJh1GYiGHZw&s=08",
		verified: true,
	},
	{
		profileImage: img12,
		username: "@oku_yungx",
		name: "Oku",
		content:
			"Make I give you una real life gist. A friend's uncle in the UK gave his friend £28,500 to be paid in 3 months time. TO cut the long story, whenever he ask his friend for the money, he gets to dream about snakes chasing him. After the 4th time, he stopped asking.",
		time: "7:27 PM",
		date: "21 Jun 2023",
		views: "592.8K",
		link: "https://twitter.com/oku_yungx/status/1671585571645403137?s=19",
		verified: true,
	},
	{
		profileImage: img13,
		username: "@WunmiWonka",
		name: "Wonka",
		content:
			"Someone has been owing me 500k since 2020 It's wild then even when I told him my dad was sick and I needed money, he didn't return my money but he bought car, rented apartment and even got married.",
		time: "9:44 PM",
		date: "26 Jun 2023",
		views: "313.3K",
		link: "https://twitter.com/WunmiWonka/status/1673431941964505092?t=ld_v610Tac8TdDzxpRVJ0w&s=19",
		verified: false,
	},
	{
		profileImage: img14,
		username: "@coder_blvck",
		name: "Big Sheddy",
		content:
			"This guy owing me 600k for months now has been refusing to pick up my calls. Why are Nigerians like this? He Never had this issue when he was collecting the money. No wahala",
		time: "10:24 AM",
		date: "21 Aug 2023",
		views: "8.4K",
		link: "https://twitter.com/coder_blvck/status/1693554560340488296?t=Mg59FIol1OEMYsCN1c66LA&s=19",
		verified: false,
	},
	{
		profileImage: img15,
		username: "@hackSultan",
		name: "Name cannot be blank",
		content: `Borrowed a friend a huge amount of money in Feb to be returned in a week. She stopped replying my messages after. Yesterday I messaged her angrily and told her to forget the money. She replied with "Awwnnnnn, I can't believe this, thanks a lot" Omooo I won craze. What???`,
		time: "3:51 PM",
		date: "3 Sep 2023",
		views: "1.1M",
		link: "https://twitter.com/hackSultan/status/1698348074270126350?t=dsCBXvG4hj7GxqsZJonHyQ&s=19",
		verified: true,
	},
	{
		profileImage: img16,
		username: "@HauwaSaka",
		name: "Hawikay",
		content:
			"This guy scammed me of about 15M I beg you, if you have any information about him especially if you are in the UK, or if you know his relatives pls help us with more information to help recover our money. Pls  RT if this makes it to your TL..... pls x.com/McCartney000",
		time: "7:53 PM",
		date: "7 Sep 2023",
		views: "920.2K",
		link: "https://twitter.com/HauwaSaka/status/1699858449457123606?t=x5Pwn_7TrRTXtWPD1ebHtg&s=19",
		verified: false,
	},
	{
		profileImage: img17,
		username: "@_VALKING",
		name: "valking",
		content: "It is soo uncomfortable, asking for your own money back.",
		time: "1:15 PM",
		date: "13 Nov 2023",
		views: "295.6K",
		link: "https://twitter.com/_VALKlNG/status/1724038304864059894?t=7Pi5xcMZCBY8NzzHuxMsMA&s=19",
		verified: true,
	},
	{
		profileImage: img18,
		username: "@ChampagneDT",
		name: "Champagne DT",
		content:
			"This is a good time to check with your debtors if the reason they haven't paid back your loan is because they have treated it as revenue.",
		time: "2:20 PM",
		date: "14 Dec 2023",
		views: "65.9K",
		link: "https://x.com/ChampagneDT/status/1735288534033170536?t=fRR-qoqfWIPBD76lMRjbVg&s=08",
		verified: false,
	},
];

export const dashboardNavData = [
	{
		name: "Dashboard",
		icon: <DashSvg />,
	},
	{
		name: "Automated Reminder",
		icon: <ReminderSvg />,
	},
	{
		name: "User Management",
		icon: <ManageSvg />,
	},
	// {
	// 	name: "History",
	// 	icon: <HistorySvg />,
	// },
	{
		name: "Payment",
		icon: <PaymentSvg />,
	},
	// {
	// 	name: "Support",
	// 	icon: <SupportSvg />,
	// },
];

export const faqData = [
	{
		q: "How fast will I get a response? ",
		a: () => (
			<span>
				Receiving responses with lightning speed, typically in less than a
				minute, is a hallmark of this service, ensuring swift assistance and
				seamless intraction.
			</span>
		),
	},
	{
		q: "How do I protect my money?",
		a: () => (
			<span>
				By verifying the credibility of your recipient(s) before initiating
				transfers, you can effectively economize your finances and ensure
				prudent use of resources.
			</span>
		),
	},
	{
		q: "Do I have to pay before use? ",
		a: () => (
			<span>
				Our policy ensures no charges unless a search record exists in our
				database, safeguarding you from unnecessary expenses.
			</span>
		),
	},
	{
		q: "How does payment work for corporates? ",
		a: () => (
			<span>
				<span>
					Corporate organizations can begin leveraging our services by
					depositing an advance sum of #50,000 (or it's equivalent in other
					currencies) which allows the organization and staff to utilize our
					services in excess of the deposit.
				</span>
				<br />
				<br />
				<span>
					Corporate entities are permitted to settle accumulated outstanding
					balances between 30 to 90 days if their initial deposit is depleted,
					ensuring continuous service.
				</span>
			</span>
		),
	},
	{
		q: "Do you offer complimentary services? ",
		a: () => (
			<span>
				<span>
					You have the option to subscribe for automated reminders to monitor
					the credibility of your person/organization of interest or yourself.
				</span>
				<br />
				<br />
				<span>
					We will run automated checks depending on the frequency you select and
					we will only charge you for successful checks.
				</span>
			</span>
		),
	},
];

export default { navData, faqData, dashboardNavData, twitterData, footerData };
