import React, { useEffect, useRef } from "react";
import Available from "../../assets/images/Available.png";
import NotAvailable from "../../assets/images/NotAvailable.png";
import styles from "./styles.module.scss";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	subTitle: string;
	isAvailable: boolean;
	cta: {
		primary: {
			title: string;
			onClick: () => void;
		};
		secondary: {
			title: string;
			onClick: () => void;
		};
	};
}

const RecordAvailableModal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	subTitle,
	title,
	isAvailable,
	cta,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				onClose();
			}
		};

		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen, onClose]);

	return (
		<div className={`${styles.overlay} ${isOpen ? styles.open : ""}`}>
			<div className={styles.modal} ref={modalRef}>
				<div className={styles.modalContent}>
					<div className={styles.image}>
						<img
							src={isAvailable ? Available : NotAvailable}
							alt="Available sign"
						/>
					</div>

					<h6>{title}</h6>

					<p>{subTitle}</p>

					<button
						type="button"
						className="btn"
						onClick={() => {
							// onClose();
							cta.primary.onClick();
						}}
					>
						{cta.primary.title}
					</button>

					<button
						type="button"
						className="btn-secondary"
						onClick={() => {
							// onClose();
							cta.secondary.onClick();
						}}
					>
						{cta.secondary.title}
					</button>
				</div>
			</div>
		</div>
	);
};

export default RecordAvailableModal;
