import { FC } from "react";
import Benefits from "../components/Benefits/Benefits";
import FAQs from "../components/FAQs/FAQs";
import Hero from "../components/Hero/Hero";
import Reviews from "../components/Reviews/Reviews";
import Services from "../components/Services/Services";

const HomePage: FC = (): JSX.Element => {
	return (
		<>
			<Hero />

			{/* <Brands /> */}

			<Services />

			<Benefits />

			<Reviews />

			<FAQs />
		</>
	);
};

export default HomePage;
