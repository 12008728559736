import { FC } from "react";
import BusinessInformation from "../components/BusinessInformation/BusinessInformation";

interface BusinessInformationPageProps {}

const BusinessInformationPage: FC<
	BusinessInformationPageProps
> = (): JSX.Element => {
	return (
		<>
			<BusinessInformation />
		</>
	);
};

export default BusinessInformationPage;
