import { FC, FormEvent, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

interface OtpProps {}

const Otp: FC<OtpProps> = (): JSX.Element => {
	const { userData, setUserData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
	const [countdown, setCountdown] = useState(30);
	const inputsRef = useRef<HTMLInputElement[]>([]);

	useEffect(() => {
		if (countdown > 0) {
			const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
			return () => clearTimeout(timer);
		}
	}, [countdown]);

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number
	) => {
		const { value } = e.target;

		if (/^[0-9]$/.test(value)) {
			const newOtp = [...otp];
			newOtp[idx] = value;
			setOtp(newOtp);

			if (idx < inputsRef.current.length - 1 && value) {
				inputsRef.current[idx + 1].focus();
			}
		}
	};

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement>,
		idx: number
	) => {
		if (e.key === "Backspace") {
			const newOtp = [...otp];

			if (otp[idx] === "" && idx > 0) {
				inputsRef.current[idx - 1].focus();
				newOtp[idx - 1] = "";
			} else {
				newOtp[idx] = "";
			}
			setOtp(newOtp);
		}
	};

	// Form submit handler
	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const otpCode = otp.join("");

		toast.promise(
			axiosFetch({
				method: "POST",
				url: `/Auth/VerifyEmail`,
				requestConfig: {
					data: {
						email: userData?.email,
						token: otpCode,
					},
				},
			}),
			{
				loading: "Verifying...",
				success: (res) => {
					if (res.isSuccessful) {
						setUserData(res);

						return res.remark || "OTP verified successfully!";
					}
					throw new Error(
						res.remark || res || "Verification failed. Please try again."
					);
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	const handleResendToken = () => {
		toast.promise(
			axiosFetch({
				method: "POST",
				url: `/Auth/ResendEmailVerificationToken?email=${userData?.email}`,
				// requestConfig: { data: { email: userData?.email } },
			}),
			{
				loading: "Resending token...",
				success: (res) => {
					if (res.isSuccessful) {
						return res.remark || "OTP resent successfully!";
					}

					throw new Error(
						res.remark || res || "Verification failed. Please try again."
					);
				},
				error: (err) => err.message,
			}
		);

		setCountdown(30);
	};

	return (
		<div className={styles.otp}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back to="/log-in" fixed />

				<div className={styles.content}>
					<h6>OTP Verification</h6>

					<p className={styles.sub}>
						Please enter the code sent to {userData?.email || "your mail"}.
					</p>

					<form onSubmit={handleSubmit}>
						<div className={styles.otpInputs}>
							{otp.map((digit, idx) => (
								<input
									key={idx}
									type="text"
									value={digit}
									maxLength={1}
									className={styles.otpInput}
									ref={(el) => (inputsRef.current[idx] = el!)}
									onChange={(e) => handleChange(e, idx)}
									onKeyDown={(e) => handleKeyDown(e, idx)} // Handle key events like backspace
								/>
							))}
						</div>

						{countdown > 0 ? (
							<p className={styles.resendTimer}>
								Resend code in {`00:${countdown.toString().padStart(2, "0")}`}
							</p>
						) : (
							<button
								type="button"
								onClick={handleResendToken}
								className={styles.resendButton}
							>
								Resend Code
							</button>
						)}

						<button className="btn" type="submit" disabled={otp.includes("")}>
							Send
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Otp;
