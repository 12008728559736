import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const RootLayout = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<header id="header">
				<Header />
			</header>

			<main id="main">
				<Outlet />
			</main>

			<footer id="main">
				<Footer />
			</footer>
		</>
	);
};

export default RootLayout;
