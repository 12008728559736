import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { FC } from "react";
import { twitterData } from "../../data/constant";
import styles from "./styles.module.scss";

interface ReviewsProps {}

const animation = { duration: 60000, easing: (t: number) => t };

// SVG components for the Twitter icon and verification badge
const TwitterIcon = () => (
	<svg
		width="54"
		height="44"
		viewBox="0 0 54 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M47.6286 11.6419C47.6607 12.107 47.6607 12.5722 47.6607 13.0416C47.6607 27.3453 36.7717 43.8418 16.8605 43.8418V43.8332C10.9787 43.8418 5.2191 42.157 0.267578 38.9803C1.12284 39.0832 1.98239 39.1346 2.84408 39.1368C7.71844 39.1411 12.4535 37.5055 16.2882 34.4939C11.6561 34.406 7.59412 31.3858 6.17511 26.9766C7.79775 27.2896 9.46969 27.2252 11.0623 26.7901C6.0122 25.7698 2.37894 21.3327 2.37894 16.1797C2.37894 16.1325 2.37894 16.0875 2.37894 16.0425C3.88369 16.8806 5.56849 17.3458 7.29188 17.3972C2.53542 14.2184 1.06925 7.89073 3.94157 2.94349C9.43754 9.70629 17.5465 13.8175 26.2513 14.2527C25.3789 10.493 26.5707 6.55317 29.383 3.91022C33.7429 -0.188186 40.6 0.0218783 44.6984 4.37965C47.1227 3.90164 49.4463 3.01208 51.5726 1.7517C50.7645 4.25747 49.0733 6.38598 46.814 7.73854C48.9597 7.4856 51.0561 6.91114 53.0302 6.03444C51.5769 8.21225 49.7464 10.1093 47.6286 11.6419Z"
			fill="#1D9BF0"
		/>
	</svg>
);

const VerifiedBadge = () => (
	<svg
		width="31"
		height="32"
		viewBox="0 0 31 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5059 3.95652C11.4742 2.12987 13.3947 0.884521 15.6079 0.884521C17.821 0.884521 19.7415 2.12987 20.7098 3.95652C22.6861 3.34953 24.9246 3.82692 26.4896 5.39188C28.0546 6.95684 28.5319 9.19542 27.925 11.1717C29.7516 12.14 30.997 14.0604 30.997 16.2736C30.997 18.4868 29.7516 20.4073 27.925 21.3755C28.5319 23.3518 28.0545 25.5904 26.4896 27.1554C24.9246 28.7203 22.6861 29.1977 20.7098 28.5907C19.7415 30.4174 17.821 31.6627 15.6079 31.6627C13.3947 31.6627 11.4742 30.4174 10.5059 28.5907C8.52965 29.1977 6.29107 28.7203 4.72611 27.1554C3.16116 25.5904 2.68377 23.3518 3.29075 21.3755C1.4641 20.4073 0.21875 18.4868 0.21875 16.2736C0.21875 14.0604 1.4641 12.14 3.29075 11.1717C2.68376 9.19542 3.16115 6.95685 4.72611 5.39188C6.29107 3.82692 8.52965 3.34954 10.5059 3.95652ZM22.9369 12.4118C23.0496 12.5245 23.0496 12.7072 22.9369 12.8199L13.8234 21.9334C13.7107 22.0461 13.528 22.0461 13.4153 21.9334L8.38254 16.9006C8.26986 16.7879 8.26986 16.6052 8.38254 16.4925L10.0148 14.8602C10.1275 14.7476 10.3102 14.7476 10.4229 14.8602L13.4153 17.8527C13.528 17.9654 13.7107 17.9654 13.8234 17.8527L20.8965 10.7796C21.0092 10.6669 21.1919 10.6669 21.3046 10.7796L22.9369 12.4118Z"
			fill="#1DA1F2"
		/>
		<path
			d="M22.9369 12.8199C23.0496 12.7072 23.0496 12.5245 22.9369 12.4118L21.3046 10.7796C21.1919 10.6669 21.0092 10.6669 20.8965 10.7796L13.8234 17.8527C13.7107 17.9654 13.528 17.9654 13.4153 17.8527L10.4229 14.8602C10.3102 14.7476 10.1275 14.7476 10.0148 14.8602L8.38254 16.4925C8.26986 16.6052 8.26986 16.7879 8.38254 16.9006L13.4153 21.9334C13.528 22.0461 13.7107 22.0461 13.8234 21.9334L22.9369 12.8199Z"
			fill="white"
		/>
	</svg>
);

interface TweetCardProps {
	profileImage: string;
	username: string;
	name: string;
	content: string;
	time: string;
	date: string;
	link: string;
	views: string;
	verified?: boolean;
}

const TweetCard: React.FC<TweetCardProps> = ({
	profileImage,
	username,
	name,
	content,
	time,
	link,
	date,
	views,
	verified = false,
}) => {
	return (
		<div className={`${styles.tweetCard}`}>
			<div className={`${styles.tweetHeader}`}>
				<div className={`${styles.userInfo}`}>
					<img
						src={profileImage}
						alt={username}
						className={`${styles.profileImage}`}
					/>

					<div>
						<p className={`${styles.username}`}>
							{username.length > 18 ? username.slice(0, 19) + "..." : username}{" "}
							{verified && <VerifiedBadge />}
						</p>

						<p className={`${styles.name}`}>
							{" "}
							{name.length > 25 ? name.slice(0, 26) + "..." : name}
						</p>
					</div>
				</div>

				<TwitterIcon />
			</div>

			<div className={`${styles.tweetContent}`}>{content}</div>

			<div className={`${styles.tweetFooter}`}>
				<p className={styles.timestamp}>
					{`${time} · ${date} · `}
					<span className={`${styles.views}`}>
						<span>{views}</span>
						<span>Views</span>
					</span>
				</p>

				<a href={link} className={`${styles.visitTweet}`}>
					Visit Tweet ➞
				</a>
			</div>
		</div>
	);
};

const Reviews: FC<ReviewsProps> = (): JSX.Element => {
	const [sliderRef] = useKeenSlider<HTMLDivElement>({
		loop: true,
		renderMode: "performance",
		slides: { perView: "auto", spacing: 30 },
		created(s) {
			s.moveToIdx(5, true, animation);
		},
		updated(s) {
			s.moveToIdx(s.track.details.abs + 5, true, animation);
		},
		animationEnded(s) {
			s.moveToIdx(s.track.details.abs + 5, true, animation);
		},
	});

	return (
		<div className={styles.reviewsContainer} id="mainContainer">
			<h4>Why We Do It</h4>

			<p className={styles.sub}>
				The rise of fraudulent activities (resulting in increased cyber-victims) powers our resolve to protect individuals, organizations, and create high trust societies.
			</p>

			<div ref={sliderRef} className={`${styles.reviews} keen-slider`}>
				{twitterData.map((tw, i) => (
					<div className={`${styles.slides} keen-slider__slide`} key={i}>
						<TweetCard
							profileImage={tw.profileImage}
							username={tw.username}
							name={tw.name}
							content={tw.content}
							time={tw.time}
							date={tw.date}
							views={tw.views}
							link={tw.link}
							verified={tw.verified}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default Reviews;
