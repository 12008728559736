import { FC } from "react";
import AboutHero from "../components/AboutHero/AboutHero";
import More from "../components/More/More";

interface AboutPageProps {}

const AboutPage: FC<AboutPageProps> = (): JSX.Element => {
	return (
		<>
			<AboutHero />

			<More />
		</>
	);
};

export default AboutPage;
