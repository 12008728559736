import { ApexOptions } from "apexcharts";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./chartsStyles.module.scss";
import DonutChart from "./DonutChart";

const Chart = () => {
	const [selectedFilter, setSelectedFilter] = useState<
		"daily" | "weekly" | "monthly"
	>("weekly");

	const chartData = {
		daily: {
			categories: ["12 AM", "4 AM", "8 AM", "12 PM", "4 PM", "8 PM", "11 PM"],
			series: [
				{
					name: "Added Records",
					data: [500, 1000, 1500, 2000, 2500, 3000, 3500],
				},
				{
					name: "Searched Record",
					data: [300, 700, 1200, 1500, 1800, 2200, 2600],
				},
				{ name: "Funded", data: [200, 500, 800, 1000, 1300, 1700, 2100] },
				{ name: "Charges", data: [200, 500, 800, 1000, 1300, 1700, 2100] },
			],
		},
		weekly: {
			categories: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
			series: [
				{
					name: "Added Records",
					data: [300000, 500000, 800000, 200000, 100000, 700000, 900000],
				},
				{
					name: "Searched Record",
					data: [500000, 300000, 700000, 400000, 300000, 800000, 700000],
				},
				{
					name: "Funded",
					data: [100000, 200000, 500000, 400000, 200000, 500000, 600000],
				},
				{
					name: "Charges",
					data: [100000, 200000, 500000, 400000, 200000, 500000, 600000],
				},
			],
		},
		monthly: {
			categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
			series: [
				{ name: "Added Records", data: [1.2e6, 1.8e6, 1.4e6, 1.7e6] },
				{ name: "Searched Record", data: [1.0e6, 1.5e6, 1.3e6, 1.6e6] },
				{ name: "Funded", data: [0.8e6, 1.2e6, 1.1e6, 1.5e6] },
				{ name: "Charges", data: [0.8e6, 1.2e6, 1.1e6, 1.5e6] },
			],
		},
	};

	const chartOptions: ApexOptions = {
		chart: {
			id: "basic-bar",
		},
		xaxis: {
			categories: chartData[selectedFilter].categories,
		},
		colors: ["#032B6B", "#0866FF", "#B2D0FF", "#FFA108"],
		stroke: {
			curve: "smooth",
		},
		plotOptions: {
			bar: {
				columnWidth: "60%",
			},
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			fontSize: "8px",
			fontWeight: 400,
		},
	};

	const handleFilterChange = (filter: "daily" | "weekly" | "monthly") => {
		setSelectedFilter(filter);
	};

	return (
		<div className={styles.chartContainer}>
			<div className={styles.contents}>
				<div className={styles.barChatContainer}>
					<div className={styles.chartHeader}>
						<div>
							<p>Statistics</p>

							<h3>Total summary</h3>
						</div>

						<div className={styles.filterButtons}>
							<button
								className={selectedFilter === "daily" ? styles.active : ""}
								onClick={() => handleFilterChange("daily")}
							>
								Daily
							</button>
							<button
								className={selectedFilter === "weekly" ? styles.active : ""}
								onClick={() => handleFilterChange("weekly")}
							>
								Weekly
							</button>
							<button
								className={selectedFilter === "monthly" ? styles.active : ""}
								onClick={() => handleFilterChange("monthly")}
							>
								Monthly
							</button>
						</div>
					</div>

					<div className={styles.chart}>
						<ReactApexChart
							options={chartOptions}
							series={chartData[selectedFilter].series}
							type="bar"
							height={350}
						/>
					</div>
				</div>

				<div className={styles.indicators}>
					<div className={styles.indicatorHeader}>
						<h4>Indicators</h4>
					</div>

					<div className={styles.indicatorContent}>
						<ul>
							<li>
								<span style={{ backgroundColor: "#032B6B" }} /> Approved -
								10,000
							</li>

							<li>
								<span style={{ backgroundColor: "#0866FF" }} /> Searched Records
								- 4,000
							</li>

							<li>
								<span style={{ backgroundColor: "#B2D0FF" }} /> Funded - 3,000
							</li>

							<li>
								<span style={{ backgroundColor: "#FFA108" }} /> Charges - 1,200
							</li>
						</ul>
					</div>
				</div>
			</div>

			<DonutChart filter={selectedFilter} />
		</div>
	);
};

export default Chart;
