import { createContext, ReactNode, useContext, useState } from "react";

interface FormContextProps {
	isFormOpen: boolean;
	toggleForm: (
		type?: "record" | "search" | "reminder" | "refund" | "onboard-user"
	) => void;
	closeForm: () => void;
	type?: "record" | "search" | "reminder" | "refund" | "onboard-user";
	selectedId?: string;
	setSelectedId: (id: string) => void;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

export const FormProvider = ({ children }: { children: ReactNode }) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [selectedId, setSelectedId] = useState("");
	const [type, setType] = useState<
		"record" | "search" | "reminder" | "refund" | "onboard-user"
	>();

	const toggleForm = (
		type?: "record" | "search" | "reminder" | "refund" | "onboard-user"
	) => {
		setType(type || "record");
		setFormOpen((prev) => !prev);
	};

	const closeForm = () => setFormOpen(false);

	return (
		<FormContext.Provider
			value={{
				isFormOpen,
				toggleForm,
				closeForm,
				type,
				selectedId,
				setSelectedId,
			}}
		>
			{children}
		</FormContext.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFormContext = () => {
	const context = useContext(FormContext);
	if (context === undefined) {
		throw new Error("useFormContext must be used within a FormProvider");
	}
	return context;
};
