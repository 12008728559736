import { FC, useEffect, useState } from "react";
import { AddedSvg, AddSvg, SearchSvg, TotalSvg } from "../../../assets/svg/Svg";
import { useAuth } from "../../../context/auth";
import { useFormContext } from "../../../context/FormContext";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import Tabs from "../../Tab/Tab";
import AddedRecordTable from "../../Table/AddedRecordTable";
import Pagination from "../../Table/Pagination";
import SearchedRecordTable from "../../Table/SearchedRecordTable";
import styles from "./styles.module.scss";
import WalletCard from "./WalletCard";

interface HomeProps {}

export interface AddedRecordType {
	publicId: string;
	amountInvolved: number;
	beneficiaryRelationship: string;
	recipientRegistrationNumber: string | null;
	recipientBvn: string;
	businessName: string | null;
	recipientEmailAddress: string;
	isIndividual: boolean;
	recipientBankName: string;
	transactionType: string;
	recipientPublicId: string;
	dueDate: string;
	isSettled: boolean | null;
	settlementDate: string | null;
	lenderPublicId: string;
	recipientFirstName: string;
	recipientLastName: string;
	recipientMiddleName: string | null;
	recipientAccountNumber: string;
	lenderAccountNumber: string;
	lenderBankName: string;
	lenderBvn: string | null;
	isReminded: boolean;
	transactionInitiationDate: string;
	currency: string;
	recipientNin: string;
}

export interface SearchRecordType {
	publicId: string;
	targetName: string;
	targetId: string;
	productName: string;
	currency: string;
	price: number;
	dateCreated: string;
}

const Home: FC<HomeProps> = (): JSX.Element => {
	const { userData, refreshCount } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [addedRecordCount, setAddedRecordCount] = useState(0);
	const [addedRecord, setAddedRecord] = useState<AddedRecordType[]>([]);
	const [searchRecord, setSearchRecord] = useState<SearchRecordType[]>([]);
	const [currentAddedPage, setCurrentAddedPage] = useState(1);
	const [currentSearchPage, setCurrentSearchPage] = useState(1);
	const [totalAdded, setTotalAdded] = useState(0);
	const { toggleForm } = useFormContext();

	useEffect(() => {
		const fetchData = async () => {
			const addedRes = await axiosFetch({
				method: "GET",
				url: `/Transactions/GetTransactionsByUserId?pageNumber=${currentAddedPage}&pageSize=10&userId=${userData?.publicId}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			if (addedRes.isSuccessful) setAddedRecordCount(addedRes.totalCount);
			if (addedRes.isSuccessful) setAddedRecord(addedRes.transactionViewModel);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentAddedPage, refreshCount]);

	useEffect(() => {
		const fetchData = async () => {
			const totalRes = await axiosFetch({
				method: "GET",
				url: `/PaidApiCalls/GetPaidApiCallByUserId?pageNumber=${currentSearchPage}&pageSize=10&userId=${userData?.publicId}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			if (totalRes.isSuccessful) setTotalAdded(totalRes.totalCount);
			if (totalRes.isSuccessful) setSearchRecord(totalRes.paidApiCallViewModel);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearchPage, refreshCount]);

	const handleAddedPageChange = (page: number) => setCurrentAddedPage(page);
	const handleSearchPageChange = (page: number) => setCurrentSearchPage(page);

	const tabData = [
		{
			label: "Searched Record",
			content: (
				<>
					<SearchedRecordTable searchRecord={searchRecord} />

					{totalAdded > 10 && (
						<Pagination
							total={totalAdded / 10}
							current={currentSearchPage}
							onPageChange={handleSearchPageChange}
						/>
					)}
				</>
			),
		},
		{
			label: "Added Record",
			content: (
				<>
					<AddedRecordTable addedRecord={addedRecord} />

					{addedRecordCount > 10 && (
						<Pagination
							total={addedRecordCount / 10}
							current={currentAddedPage}
							onPageChange={handleAddedPageChange}
						/>
					)}
				</>
			),
		},
	];

	return (
		<div className={styles.home}>
			<div className={styles.header}>
				<div>
					<p className={styles.title}>Overview</p>

					<p>
						Hi {userData?.firstName || "user"} welcome back to <span>DDT</span>
					</p>
				</div>

				<div>
					<button
						type="button"
						className="btn-secondary"
						onClick={() => toggleForm("search")}
					>
						<SearchSvg />

						<p>Search Record</p>
					</button>
					<button
						type="button"
						className="btn"
						onClick={() => toggleForm("record")}
					>
						<div>
							<AddSvg />
						</div>

						<p>Add Record</p>
					</button>
				</div>
			</div>

			<div className={styles.walletContainer}>
				<WalletCard />

				<div className={styles.start}>
					<div className={styles.left}>
						<span>
							<AddedSvg />

							<p>Added Record</p>
						</span>

						<span className={styles.line} />

						<span>
							<p className={styles.figure}>{addedRecordCount}</p>

							<p>Total Added</p>
						</span>
					</div>

					<div className={styles.right}>
						<span>
							<TotalSvg />

							<p>Total Request</p>
						</span>

						<span className={styles.line} />

						<span>
							<p className={styles.figure}>{totalAdded}</p>

							<p>Request made</p>
						</span>
					</div>
				</div>
			</div>

			<Tabs tabs={tabData} />
		</div>
	);
};

export default Home;
