import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

const Pagination: React.FC<{
	total: number;
	current: number;
	onPageChange: (page: number) => void;
}> = ({ total, current, onPageChange }) => {
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 768);
		};
		handleResize(); // Check on component mount
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const pageNumbers = [];
	const maxPageButtons = isSmallScreen ? 3 : 5;

	if (Math.ceil(total) <= maxPageButtons) {
		pageNumbers.push(
			...Array.from({ length: Math.ceil(total) }, (_, i) => i + 1)
		);
	} else {
		if (current > 2) {
			pageNumbers.push(1);
			if (current > 3) {
				pageNumbers.push(-1);
			}
		}

		if (isSmallScreen) {
			pageNumbers.push(current);
		} else {
			const startPage = Math.max(current - 1, 2);
			const endPage = Math.min(current + 1, Math.ceil(total) - 1);

			for (let i = startPage; i <= endPage; i++) {
				pageNumbers.push(i);
			}
		}

		if (current < Math.ceil(total) - 1) {
			if (current < Math.ceil(total) - 2) {
				pageNumbers.push(-1);
			}
			pageNumbers.push(Math.ceil(total));
		}
	}

	return (
		<ul className={styles.pagination}>
			<li>
				<button
					className={styles.prev}
					onClick={() => onPageChange(current - 1)}
					disabled={current === 1}
				>
					&larr; Prev
				</button>
			</li>

			<ul className={styles.middle}>
				{pageNumbers.map((page, index) =>
					page === -1 ? (
						<li key={`break-${index}`} className={styles.breakLabel}>
							...
						</li>
					) : (
						<li key={page}>
							<button
								className={current === page ? styles.active : ""}
								onClick={() => onPageChange(page)}
							>
								{page}
							</button>
						</li>
					)
				)}
			</ul>

			<li>
				<button
					className={styles.next}
					onClick={() => onPageChange(current + 1)}
					disabled={current === Math.ceil(total)}
				>
					Next &rarr;
				</button>
			</li>
		</ul>
	);
};

export default Pagination;
