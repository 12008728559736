import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

interface Action {
	label: string;
	icon?: JSX.Element;
	onClick: () => void;
}

interface TableActionsProps {
	actions: Action[];
}

const TableActions: React.FC<TableActionsProps> = ({ actions }) => {
	const [showPopup, setShowPopup] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);

	const handleOutsideClick = (event: MouseEvent) => {
		if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
			setShowPopup(false);
		}
	};

	useEffect(() => {
		if (showPopup) {
			document.addEventListener("mousedown", handleOutsideClick);
		} else {
			document.removeEventListener("mousedown", handleOutsideClick);
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [showPopup]);

	return (
		<div className={styles.action}>
			<button type="button" onClick={() => setShowPopup(true)}>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.0013 10.833C10.4615 10.833 10.8346 10.4599 10.8346 9.99967C10.8346 9.53944 10.4615 9.16634 10.0013 9.16634C9.54106 9.16634 9.16797 9.53944 9.16797 9.99967C9.16797 10.4599 9.54106 10.833 10.0013 10.833Z"
						stroke="#98A2B3"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10.0013 4.99967C10.4615 4.99967 10.8346 4.62658 10.8346 4.16634C10.8346 3.7061 10.4615 3.33301 10.0013 3.33301C9.54106 3.33301 9.16797 3.7061 9.16797 4.16634C9.16797 4.62658 9.54106 4.99967 10.0013 4.99967Z"
						stroke="#98A2B3"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10.0013 16.6663C10.4615 16.6663 10.8346 16.2932 10.8346 15.833C10.8346 15.3728 10.4615 14.9997 10.0013 14.9997C9.54106 14.9997 9.16797 15.3728 9.16797 15.833C9.16797 16.2932 9.54106 16.6663 10.0013 16.6663Z"
						stroke="#98A2B3"
						strokeWidth="1.66667"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>

			{showPopup && (
				<div ref={popupRef} className={styles.popup}>
					{actions.map((action, index) => (
						<button
							key={index}
							type="button"
							onClick={() => {
								action.onClick();
								setShowPopup(false);
							}}
						>
							{action.icon}

							<p>{action.label}</p>
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default TableActions;
