import { FC } from "react";
import SignUpCorporate from "../components/SignUpCorporate/SignUpCorporate";

interface SignUpCorporatePageProps {}

const SignUpCorporatePage: FC<SignUpCorporatePageProps> = (): JSX.Element => {
	return (
		<>
			<SignUpCorporate />
		</>
	);
};

export default SignUpCorporatePage;
