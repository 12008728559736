import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatCurrency } from "../../../utils";
import PaymentModal from "../../PopUps/Payments/PaymentModal";
import styles from "./styles.module.scss";

const WalletCard: React.FC = () => {
	const { userData, setUserData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [balance, setBalance] = useState(0);
	const [payment, setPayment] = useState(false);

	const fetchData = async () => {
		const userBalance = await axiosFetch({
			method: "GET",
			url: `/Wallets/GetUserWalletBalance?userId=${userData?.publicId}`,
			requestConfig: {
				headers: {
					Authorization: `Bearer ${userData?.token}`,
				},
			},
		});

		setBalance(userBalance.balance);
		setUserData({ balance: userBalance.balance });
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.walletCard}>
				<div className={styles.balanceInfo}>
					<p className={styles.heading}>My Wallet</p>

					<div>
						<p>Total Balance</p>

						<p>{formatCurrency(balance)}</p>
					</div>
				</div>

				<button className={styles.fundButton} onClick={() => setPayment(true)}>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.875 12.1426H7.125C6.8175 12.1426 6.5625 11.8876 6.5625 11.5801C6.5625 11.2726 6.8175 11.0176 7.125 11.0176H10.875C11.1825 11.0176 11.4375 11.2726 11.4375 11.5801C11.4375 11.8876 11.1825 12.1426 10.875 12.1426Z"
							fill="#E6EEFD"
						/>
						<path
							d="M9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5V9.75C8.4375 9.4425 8.6925 9.1875 9 9.1875C9.3075 9.1875 9.5625 9.4425 9.5625 9.75V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625Z"
							fill="#E6EEFD"
						/>
						<path
							d="M3.89928 7.84491C3.75678 7.84491 3.61428 7.78491 3.50178 7.67991C3.34428 7.52241 3.29178 7.27491 3.38178 7.06491L4.77678 3.73491C4.80678 3.66741 4.82178 3.62241 4.84428 3.58491C5.95428 1.02741 7.37178 0.404908 9.87678 1.36491C10.0193 1.41741 10.1318 1.52991 10.1918 1.67241C10.2518 1.81491 10.2518 1.97241 10.1918 2.11491L7.99428 7.21491C7.90428 7.41741 7.70178 7.55241 7.47678 7.55241H5.33928C4.91178 7.55241 4.50678 7.63491 4.11678 7.79991C4.04928 7.82991 3.97428 7.84491 3.89928 7.84491ZM7.95678 2.06241C7.02678 2.06241 6.45678 2.66991 5.86428 4.04991C5.85678 4.07241 5.84178 4.09491 5.83428 4.11741L4.85178 6.44991C5.01678 6.43491 5.17428 6.42741 5.33928 6.42741H7.10178L8.90928 2.22741C8.55678 2.11491 8.24178 2.06241 7.95678 2.06241Z"
							fill="#E6EEFD"
						/>
						<path
							d="M13.7182 7.7024C13.6657 7.7024 13.6057 7.6949 13.5532 7.6799C13.2757 7.5974 12.9682 7.5524 12.6532 7.5524H7.47816C7.29066 7.5524 7.11066 7.4549 7.00566 7.2974C6.90816 7.1399 6.88566 6.9374 6.96066 6.7649L9.13566 1.7174C9.24816 1.4474 9.57816 1.2674 9.85566 1.3574C9.94566 1.3874 10.0282 1.4249 10.1182 1.4624L11.8882 2.2049C12.9232 2.6324 13.6132 3.0824 14.0632 3.6224C14.1532 3.7274 14.2282 3.8399 14.2957 3.9524C14.3782 4.0799 14.4532 4.2374 14.5057 4.3949C14.5282 4.4474 14.5657 4.5449 14.5882 4.6499C14.7982 5.3549 14.6932 6.2324 14.2432 7.3574C14.1532 7.5674 13.9432 7.7024 13.7182 7.7024ZM8.33316 6.4274H12.6607C12.9007 6.4274 13.1332 6.4499 13.3657 6.4874C13.5757 5.8349 13.6207 5.3324 13.5007 4.9274C13.4857 4.8599 13.4707 4.8299 13.4632 4.7999C13.4182 4.6799 13.3882 4.6124 13.3507 4.5524C13.2982 4.4699 13.2607 4.4024 13.2007 4.3349C12.8782 3.9449 12.3082 3.5849 11.4607 3.2399L9.97566 2.6174L8.33316 6.4274Z"
							fill="#E6EEFD"
						/>
						<path
							d="M11.925 17.0627H6.075C5.865 17.0627 5.67 17.0477 5.475 17.0252C2.8425 16.8452 1.3425 15.3452 1.1625 12.6827C1.14 12.5177 1.125 12.3152 1.125 12.1127V10.6502C1.125 8.96273 2.13 7.44023 3.6825 6.76523C4.2075 6.54023 4.77 6.42773 5.3475 6.42773H12.6675C13.095 6.42773 13.5075 6.48773 13.89 6.60773C15.6525 7.14023 16.89 8.80523 16.89 10.6502V12.1127C16.89 12.2777 16.8825 12.4352 16.875 12.5852C16.71 15.5177 15 17.0627 11.925 17.0627ZM5.34 7.55273C4.9125 7.55273 4.5075 7.63523 4.1175 7.80023C2.9775 8.29523 2.2425 9.41273 2.2425 10.6502V12.1127C2.2425 12.2702 2.2575 12.4277 2.2725 12.5777C2.415 14.7152 3.465 15.7652 5.5725 15.9077C5.76 15.9302 5.91 15.9452 6.0675 15.9452H11.9175C14.3925 15.9452 15.6075 14.8577 15.7275 12.5327C15.735 12.3977 15.7425 12.2627 15.7425 12.1127V10.6502C15.7425 9.29273 14.835 8.07773 13.545 7.68023C13.2675 7.59773 12.96 7.55273 12.645 7.55273H5.34Z"
							fill="#E6EEFD"
						/>
						<path
							d="M1.67969 11.2128C1.37219 11.2128 1.11719 10.9578 1.11719 10.6503V8.45284C1.11719 6.09034 2.78969 4.05034 5.09969 3.60034C5.30219 3.56284 5.51219 3.63784 5.64719 3.79534C5.77469 3.95284 5.81219 4.17784 5.72969 4.36534L4.41719 7.50034C4.35719 7.63534 4.25219 7.74034 4.12469 7.80034C2.98469 8.29534 2.24969 9.41284 2.24969 10.6503C2.24219 10.9578 1.99469 11.2128 1.67969 11.2128ZM4.19969 5.11534C3.23969 5.65534 2.54219 6.60034 2.32469 7.70284C2.65469 7.36534 3.03719 7.08034 3.47219 6.87034L4.19969 5.11534Z"
							fill="#E6EEFD"
						/>
						<path
							d="M16.3208 11.2123C16.0133 11.2123 15.7583 10.9573 15.7583 10.6498C15.7583 9.29233 14.8508 8.07733 13.5608 7.67983C13.4108 7.63483 13.2833 7.52983 13.2158 7.38733C13.1483 7.24483 13.1408 7.07983 13.2008 6.93733C13.5533 6.05983 13.6433 5.42233 13.5008 4.92733C13.4858 4.85983 13.4708 4.82983 13.4633 4.79983C13.3658 4.58233 13.4183 4.32733 13.5908 4.16233C13.7633 3.99733 14.0258 3.95983 14.2358 4.07233C15.8708 4.92733 16.8833 6.60733 16.8833 8.45233V10.6498C16.8833 10.9573 16.6283 11.2123 16.3208 11.2123ZM14.4383 6.81733C14.9108 7.03483 15.3308 7.34233 15.6833 7.70983C15.5408 6.97483 15.1883 6.30733 14.6708 5.78233C14.6333 6.09733 14.5583 6.44233 14.4383 6.81733Z"
							fill="#E6EEFD"
						/>
					</svg>

					<p>Fund Account</p>
				</button>
			</div>

			{payment && (
				<PaymentModal
					onClose={() => {
						fetchData();
						setPayment(false);
					}}
				/>
			)}
		</>
	);
};

export default WalletCard;
