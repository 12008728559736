// please update required information
const siteMetadata = {
	title: "Domestic Transaction Tracker",
	description: "The database that helps you make safer transactions",
	headerTitle: "Domestic Transaction Tracker",
	author: "Domestic Transaction Tracker",
	theme: "#ffffff",
	siteUrl: "http://localhost:3000/",
	siteLogo: "/assets/images/Logo.png",
	socialBanner: "/assets/images/Logo.png",
	email: "",
	github: "",
	twitter: "",
	facebook: "",
	instagram: "",
	youtube: "",
	linkedin: "",
	language: "en-us",
	locale: "en-US",
	keywords: [
		"Africa Missions Global Harvesters",
		"Nigeria tech entrepreneur",
		"Empower young people",
	],
};

export default siteMetadata;
