import axios from "axios";
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../../api/adminBaseApi";
import { useFormContext } from "../../context/FormContext";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import UploadBox from "../InputField/UploadBox";
import { Spinner } from "../PopUps/Payments/PaymentModal";
import styles from "./styles.module.scss";

interface RefundStepProps {}

export interface RecordType {
	publicId: string;
	amountInvolved: number;
	beneficiaryRelationship: string;
	recipientRegistrationNumber?: string;
	recipientBvn?: string;
	businessName?: string;
	recipientEmailAddress: string;
	isIndividual: boolean;
	recipientBankName: string;
	transactionType: string;
	recipientPublicId: string;
	dueDate: string;
	isSettled: boolean;
	settlementDate?: string;
	lenderPublicId: string;
	recipientFirstName: string;
	recipientLastName: string;
	recipientMiddleName?: string;
	createdBy?: string;
	recipientAccountNumber: string;
	lenderAccountNumber: string;
	lenderBankName: string;
	lenderBvn?: string;
	isReminded: boolean;
	transactionInitiationDate: string;
	currency: string;
	recipientNin?: string;
	amountRefunded: number;
	nextDueDate: string;
	receiptOfRefund: string;
	isReceived: boolean;
	datePaid: string;
}

const initialVal = {
	amountRefunded: "",
	nextDueDate: "",
	receiptOfRefund: "",
	isReceived: "",
	depositorAccountNumber: "",
	depositorBankName: "",
	datePaid: "",
	proofOfRefund: File,
};

const RefundStep: FC<RefundStepProps> = (): JSX.Element => {
	const { selectedId, closeForm } = useFormContext();
	const { userData, refresh } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [record, setRecord] = useState<RecordType>();
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState(initialVal);
	const [bank, setBank] = useState<
		{ value: string; label: string | number | boolean }[]
	>([]);
	const [errors, setErrors] = useState({
		amountRefunded: "",
		nextDueDate: "",
		receiptOfRefund: "",
		datePaid: "",
		isReceived: "",
		depositorAccountNumber: "",
		depositorBankName: "",
		proofOfRefund: "",
	});
	const [isFormValid, setIsFormValid] = useState<
		boolean | string | number | undefined
	>(false);

	useEffect(() => {
		setFormData(initialVal);

		const fetchData = async () => {
			setLoading(true);

			const recordRes = await axiosFetch({
				method: "GET",
				url: `Transactions/GetTransactionsById?publicId=${selectedId}`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			if (recordRes.isSuccessful) setRecord(recordRes);

			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedId]);

	useEffect(() => {
		const fetchData = async () => {
			const banksData = await axiosFetch({
				method: "GET",
				url: "/Banks/GetBanks?pageNumber=1&pageSize=100",
			});

			if (banksData.isSuccessful)
				setBank(
					banksData?.bankViewModel?.map((bank: { name: string }) => ({
						value: bank.name,
						label: bank.name,
					}))
				);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (name: string, value: string | File) => {
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const validateField = (name: string, value: any) => {
		let error = "";
		const today = new Date();

		if (!value && name !== "nextDueDate") error = "This field is required.";
		if (name === "amountRefunded" && +value <= 0)
			error = "Amount refunded must be greater than zero.";
		if (name === "datePaid" && new Date(value) > today)
			error = "Date paid cannot be in the future.";
		if (name === "nextDueDate" && new Date(value) < today)
			error = "Next due date cannot be in the past.";
		if (name === "depositorAccountNumber") {
			if (!/^\d{10}$/.test(value)) error = "Account number must be 10 digits.";
		}
		if (name === "isReceived" && !value) error = "Please confirm";
		if (name === "depositorBankName" && !value) error = "Select a bank name";
		if (name === "receiptOfRefund" && value.length < 3)
			error = "Receipt of refund must be at least 3 characters long.";

		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	useEffect(() => {
		const isValid =
			!Object.values(errors).some((error) => error !== "") &&
			formData.amountRefunded &&
			formData.datePaid &&
			formData.isReceived &&
			formData.depositorBankName &&
			formData.depositorAccountNumber &&
			formData.proofOfRefund &&
			formData.receiptOfRefund;

		setIsFormValid(isValid);
	}, [formData, errors]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!record) return;

		const newFormData = {
			recipientPublicId: record.recipientPublicId,
			transactionPublicId: selectedId,
			lenderPublicId: record.lenderPublicId,
			lenderAccountNumber: record.lenderAccountNumber,
			lenderBankName: record.lenderBankName,
			createdBy: userData?.publicId,
			...formData,
		};

		const data = new FormData();

		for (const key in newFormData) {
			const value = newFormData[key as keyof typeof newFormData];

			if (value instanceof File) {
				data.append(key, value);
			} else if (typeof value === "string" || typeof value === "number") {
				data.append(key, value.toString());
			} else if (typeof value === "boolean") {
				data.append(key, value ? "true" : "false");
			}
		}

		try {
			toast.promise(
				axios.post(API_BASE_URL + "Refunds/CreateRefund/", data, {
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "application/json",
						Authorization: `Bearer ${userData?.token}`,
					},
				}),
				{
					loading: "Creating refund...",
					success: (res) => {
						if (res.data.isSuccessful) {
							refresh();
							closeForm();
							setFormData(initialVal);
							return res.data.remark || "Refund created successfully!";
						}

						throw new Error(res.data || "An error occurred, please try again!");
					},
					error: (err) => {
						if (axios.isAxiosError(err)) {
							return (
								err.response?.data.title ||
								err.response?.data.remark ||
								err.message
							);
						}
						return err.message;
					},
				}
			);
		} catch (error) {
			toast.error("Error submitting the form");
		}
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Report Refund</h6>

			<p className={styles.sub}>Create a refund</p>

			{loading ? (
				<Spinner />
			) : (
				<div className={styles.onBoardContainer}>
					<InputField
						type="number"
						label="Amount Refunded"
						name="amountRefunded"
						placeholder="Enter amount refunded"
						value={formData.amountRefunded}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						error={errors.amountRefunded}
						isRequired
					/>

					<InputField
						label="Depositor Account Number"
						name="depositorAccountNumber"
						placeholder="Enter depositor account number"
						value={formData.depositorAccountNumber}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						isRequired
						type="number"
						error={errors.depositorAccountNumber}
					/>

					<InputField
						label="Depositor Bank"
						name="depositorBankName"
						type="select"
						placeholder="Select bank"
						value={formData.depositorBankName}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						selectOptions={bank}
						isRequired
						error={errors.depositorBankName}
					/>

					<InputField
						label="Confirm received"
						name="isReceived"
						type="select"
						placeholder="Received?"
						value={formData.isReceived}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						selectOptions={[
							{
								label: "Yes",
								value: "true",
							},
							{
								label: "No",
								value: "false",
							},
						]}
						isRequired
						error={errors.isReceived}
					/>

					<InputField
						label="Date Paid"
						name="datePaid"
						type="date"
						placeholder="DD-MM-YYYY"
						value={formData.datePaid}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						error={errors.datePaid}
						isRequired
					/>

					<InputField
						label="Next Due Date"
						name="nextDueDate"
						type="date"
						placeholder="DD-MM-YYYY"
						value={formData.nextDueDate}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						error={errors.nextDueDate}
					/>

					<InputField
						label="Receipt of Refund"
						name="receiptOfRefund"
						placeholder="Enter receipt of refund"
						value={formData.receiptOfRefund}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						isRequired
						error={errors.receiptOfRefund}
					/>

					<UploadBox
						label="Proof of Refund"
						onUpload={(uploads) => {
							handleChange("proofOfRefund", uploads[0]);
						}}
						isRequired
						error={errors.proofOfRefund}
					/>

					<button className="btn" type="submit" disabled={!isFormValid}>
						Report
					</button>
				</div>
			)}
		</form>
	);
};

export default RefundStep;
