export const ProcessedSvg = () => (
	<svg
		width="48"
		height="48"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="48" height="48" rx="8" fill="#075DE8" />
		<path
			d="M19.356 15.39H18.1427C16.2877 15.39 15.541 16.09 15.541 17.8633V31.7233H21.9577V17.8633C21.946 16.09 21.1993 15.39 19.356 15.39Z"
			fill="white"
		/>
		<path
			d="M29.274 21.2234H28.0607C26.2057 21.2234 25.459 21.9351 25.459 23.6967V31.7234H31.8757V23.6967C31.8757 21.9351 31.1173 21.2234 29.274 21.2234Z"
			fill="white"
		/>
		<path
			d="M13.209 30.86H34.7923C35.2707 30.86 35.6673 31.2567 35.6673 31.735C35.6673 32.2133 35.2707 32.61 34.7923 32.61H13.209C12.7307 32.61 12.334 32.2133 12.334 31.7233C12.334 31.2333 12.7307 30.86 13.209 30.86Z"
			fill="white"
		/>
	</svg>
);

export const CustomerSvg = () => (
	<svg
		width="49"
		height="48"
		viewBox="0 0 49 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="0.333984" width="48" height="48" rx="8" fill="#075DE8" />
		<path
			d="M30.7852 19.065C30.7035 19.0534 30.6219 19.0534 30.5402 19.065C28.7319 19.0067 27.2969 17.525 27.2969 15.705C27.2969 13.85 28.8019 12.3334 30.6685 12.3334C32.5235 12.3334 34.0402 13.8384 34.0402 15.705C34.0285 17.525 32.5935 19.0067 30.7852 19.065Z"
			fill="white"
		/>
		<path
			d="M34.5895 27.15C33.2828 28.025 31.4512 28.3516 29.7595 28.13C30.2028 27.1733 30.4362 26.1116 30.4478 24.9916C30.4478 23.825 30.1912 22.7166 29.7012 21.7483C31.4278 21.515 33.2595 21.8416 34.5778 22.7166C36.4212 23.93 36.4212 25.925 34.5895 27.15Z"
			fill="white"
		/>
		<path
			d="M17.8468 19.065C17.9285 19.0534 18.0102 19.0534 18.0918 19.065C19.9002 19.0067 21.3352 17.525 21.3352 15.705C21.3352 13.8384 19.8302 12.3334 17.9635 12.3334C16.1085 12.3334 14.6035 13.8384 14.6035 15.705C14.6035 17.525 16.0385 19.0067 17.8468 19.065Z"
			fill="white"
		/>
		<path
			d="M17.9763 24.9916C17.9763 26.1233 18.2213 27.1966 18.6646 28.165C17.0196 28.34 15.3046 27.99 14.0446 27.1616C12.2013 25.9366 12.2013 23.9416 14.0446 22.7166C15.2929 21.8766 17.0546 21.5383 18.7113 21.725C18.2329 22.705 17.9763 23.8133 17.9763 24.9916Z"
			fill="white"
		/>
		<path
			d="M24.4736 28.515C24.3803 28.5033 24.2753 28.5033 24.1703 28.515C22.0236 28.445 20.3086 26.6833 20.3086 24.5133C20.3203 22.2967 22.1053 20.5 24.3336 20.5C26.5503 20.5 28.3469 22.2967 28.3469 24.5133C28.3353 26.6833 26.6319 28.445 24.4736 28.515Z"
			fill="white"
		/>
		<path
			d="M20.6826 30.93C18.9209 32.1083 18.9209 34.045 20.6826 35.2116C22.6892 36.5533 25.9792 36.5533 27.9859 35.2116C29.7476 34.0333 29.7476 32.0966 27.9859 30.93C25.9909 29.5883 22.7009 29.5883 20.6826 30.93Z"
			fill="white"
		/>
	</svg>
);

export const SavedSvg = () => (
	<svg
		width="49"
		height="48"
		viewBox="0 0 49 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="0.666016" width="48" height="48" rx="8" fill="#075DE8" />
		<path
			d="M24.666 27.5C26.599 27.5 28.166 25.933 28.166 24C28.166 22.067 26.599 20.5 24.666 20.5C22.733 20.5 21.166 22.067 21.166 24C21.166 25.933 22.733 27.5 24.666 27.5Z"
			fill="white"
		/>
		<path
			d="M30.5 14.0834H18.8333C15.3333 14.0834 13 15.8334 13 19.9167V20.5V27.5V28.0834C13 32.1667 15.3333 33.9167 18.8333 33.9167H30.5C34 33.9167 36.3333 32.1667 36.3333 28.0834V27.5V20.5V19.9167C36.3333 15.8334 34 14.0834 30.5 14.0834ZM18.8333 32.1667C18.8333 28.8767 17.7717 27.6634 14.75 27.5234V27.5V20.5V20.4767C17.7717 20.3367 18.8333 19.1234 18.8333 15.8334H30.5C30.5 19.1234 31.5617 20.3367 34.5833 20.4767V20.5V27.5V27.5234C31.5617 27.6634 30.5 28.8767 30.5 32.1667H18.8333Z"
			fill="white"
		/>
	</svg>
);

export const BusinessSvg = () => (
	<svg
		width="44"
		height="44"
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="44" height="44" rx="8" fill="#075DE8" />
		<path
			d="M32 31.25H12C11.59 31.25 11.25 31.59 11.25 32C11.25 32.41 11.59 32.75 12 32.75H32C32.41 32.75 32.75 32.41 32.75 32C32.75 31.59 32.41 31.25 32 31.25Z"
			fill="white"
		/>
		<path
			d="M27 12H17C14 12 13 13.79 13 16V32H31V16C31 13.79 30 12 27 12ZM20 27.25H17C16.59 27.25 16.25 26.91 16.25 26.5C16.25 26.09 16.59 25.75 17 25.75H20C20.41 25.75 20.75 26.09 20.75 26.5C20.75 26.91 20.41 27.25 20 27.25ZM20 22.75H17C16.59 22.75 16.25 22.41 16.25 22C16.25 21.59 16.59 21.25 17 21.25H20C20.41 21.25 20.75 21.59 20.75 22C20.75 22.41 20.41 22.75 20 22.75ZM20 18.25H17C16.59 18.25 16.25 17.91 16.25 17.5C16.25 17.09 16.59 16.75 17 16.75H20C20.41 16.75 20.75 17.09 20.75 17.5C20.75 17.91 20.41 18.25 20 18.25ZM27 27.25H24C23.59 27.25 23.25 26.91 23.25 26.5C23.25 26.09 23.59 25.75 24 25.75H27C27.41 25.75 27.75 26.09 27.75 26.5C27.75 26.91 27.41 27.25 27 27.25ZM27 22.75H24C23.59 22.75 23.25 22.41 23.25 22C23.25 21.59 23.59 21.25 24 21.25H27C27.41 21.25 27.75 21.59 27.75 22C27.75 22.41 27.41 22.75 27 22.75ZM27 18.25H24C23.59 18.25 23.25 17.91 23.25 17.5C23.25 17.09 23.59 16.75 24 16.75H27C27.41 16.75 27.75 17.09 27.75 17.5C27.75 17.91 27.41 18.25 27 18.25Z"
			fill="white"
		/>
	</svg>
);

export const TransactionSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22 7.55039C22 8.21039 21.46 8.75039 20.8 8.75039H3.2C2.54 8.75039 2 8.21039 2 7.55039V7.54039C2 5.25039 3.85 3.40039 6.14 3.40039H17.85C20.14 3.40039 22 5.26039 22 7.55039Z"
			fill="#0866FF"
		/>
		<path
			d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
			fill="#0866FF"
		/>
	</svg>
);

export const SpentSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.85 3.9498V7.7498H13.35V3.9498C13.35 3.6798 13.11 3.5498 12.95 3.5498C12.9 3.5498 12.85 3.5598 12.8 3.5798L4.87 6.56981C4.34 6.7698 4 7.2698 4 7.8398V8.5098C3.09 9.1898 2.5 10.2798 2.5 11.5098V7.8398C2.5 6.6498 3.23 5.5898 4.34 5.1698L12.28 2.1698C12.5 2.0898 12.73 2.0498 12.95 2.0498C13.95 2.0498 14.85 2.8598 14.85 3.9498Z"
			fill="#0866FF"
		/>
		<path
			d="M21.5007 14.5V15.5C21.5007 15.77 21.2907 15.99 21.0107 16H19.5507C19.0207 16 18.5407 15.61 18.5007 15.09C18.4707 14.78 18.5907 14.49 18.7907 14.29C18.9707 14.1 19.2207 14 19.4907 14H21.0007C21.2907 14.01 21.5007 14.23 21.5007 14.5Z"
			fill="#0866FF"
		/>
		<path
			d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V18.24C2.5 20.31 4.19 22 6.26 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.29 14.77 17.54 14.04 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
			fill="#0866FF"
		/>
	</svg>
);

export const CameraSvg = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_3223_16146)">
			<path
				d="M9.925 2.78067H8.6076L7.755 1.06777C7.69659 0.950878 7.60662 0.852665 7.49528 0.784253C7.38394 0.715841 7.25568 0.679961 7.125 0.680674H4.325C4.19492 0.680614 4.06739 0.716801 3.95673 0.785177C3.84607 0.853553 3.75665 0.951412 3.6985 1.06777L2.8424 2.78067H1.525C1.1537 2.78067 0.797601 2.92817 0.535051 3.19072C0.2725 3.45328 0.125 3.80937 0.125 4.18067V9.78067C0.125 10.152 0.2725 10.5081 0.535051 10.7706C0.797601 11.0332 1.1537 11.1807 1.525 11.1807H9.925C10.2963 11.1807 10.6524 11.0332 10.9149 10.7706C11.1775 10.5081 11.325 10.152 11.325 9.78067V4.18067C11.325 3.80937 11.1775 3.45328 10.9149 3.19072C10.6524 2.92817 10.2963 2.78067 9.925 2.78067ZM5.725 9.08067C5.30966 9.08067 4.90365 8.95751 4.5583 8.72676C4.21296 8.49601 3.9438 8.16803 3.78485 7.78431C3.62591 7.40059 3.58432 6.97835 3.66535 6.57099C3.74638 6.16362 3.94639 5.78944 4.24008 5.49575C4.53377 5.20206 4.90795 5.00205 5.31531 4.92103C5.72267 4.84 6.14491 4.88158 6.52864 5.04053C6.91236 5.19947 7.24033 5.46863 7.47109 5.81398C7.70184 6.15932 7.825 6.56533 7.825 6.98067C7.825 7.53763 7.60375 8.07177 7.20992 8.4656C6.8161 8.85943 6.28195 9.08067 5.725 9.08067Z"
				fill="#1C42CB"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3223_16146">
				<rect
					width="11.2"
					height="11.2"
					fill="white"
					transform="translate(0.125 0.331055)"
				/>
			</clipPath>
		</defs>
	</svg>
);
