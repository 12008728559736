import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
// import { useAuth } from "../../context/auth";
import Logo from "../../assets/images/Logo.png";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import { validatePassword } from "../../utils";
import InputField from "../InputField/InputField";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = (): JSX.Element => {
	const { axiosFetch } = useAxiosFunction();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const navigate = useNavigate();
	// const { setUserData } = useAuth();
	const [formData, setFormData] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	});
	const [errors, setErrors] = useState({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setFormData({
			...formData,
			[name]: e.target.value,
		});

		if (e.target.value === "") {
			setErrors({ ...errors, [name]: `${name} cannot be empty` });
		} else if (name === "newPassword") {
			const passwordError = validatePassword(e.target.value);
			setErrors({ ...errors, newPassword: passwordError });
		} else if (name === "confirmPassword") {
			const confirmError =
				e.target.value !== formData.newPassword ? "Passwords do not match" : "";
			setErrors({ ...errors, confirmPassword: confirmError });
		} else {
			setErrors({ ...errors, [name]: "" });
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const newPasswordError = validatePassword(formData.newPassword);
		const confirmPasswordError =
			formData.confirmPassword !== formData.newPassword
				? "Passwords do not match"
				: "";

		if (newPasswordError || confirmPasswordError) {
			setErrors({
				...errors,
				newPassword: newPasswordError,
				confirmPassword: confirmPasswordError,
			});
			return;
		}

		toast.promise(
			axiosFetch({
				method: "PUT",
				url: "/Auth/ChangePassword",
				requestConfig: {
					data: {
						email: "",
						token: "",
						newPassword: formData.newPassword,
						confirmPassword: formData.oldPassword,
					},
				},
			}),
			{
				loading: "Resetting password...",
				success: (res) => {
					if (res.isSuccessful) {
						navigate("/log-in");

						return res.remark || "Password reset successfully!";
					}
					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	useEffect(() => {
		const hasErrors = Object.values(errors).some((error) => error !== "");
		const isEmptyField = Object.values(formData).some((value) => value === "");

		setIsButtonDisabled(hasErrors || isEmptyField);
	}, [errors, formData]);

	return (
		<div className={styles.resetPassword}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back to="/" />

				<div className={styles.content}>
					<h6>Reset Password</h6>

					<p className={styles.sub}>
						Please input new password to reset your password.
					</p>

					<form onSubmit={handleSubmit}>
						<InputField
							label="Old Password"
							name="oldPassword"
							type="password"
							placeholder="Enter your old password"
							value={formData.oldPassword}
							onChange={handleInputChange}
							error={errors.oldPassword}
							isRequired
						/>

						<InputField
							label="New Password"
							name="newPassword"
							type="password"
							placeholder="Enter a new password"
							value={formData.newPassword}
							onChange={handleInputChange}
							error={errors.newPassword}
							isRequired
						/>

						<InputField
							label="Confirm Password"
							name="confirmPassword"
							type="password"
							placeholder="Confirm new password"
							value={formData.confirmPassword}
							onChange={handleInputChange}
							error={errors.confirmPassword}
							isRequired
						/>

						<button className="btn" type="submit" disabled={isButtonDisabled}>
							Confirm
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
