import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AddSvg } from "../../../assets/svg/Svg";
import { useAuth } from "../../../context/auth";
import { useFormContext } from "../../../context/FormContext";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import Switch from "../../Switch/Switch";
import Pagination from "../../Table/Pagination";
import styles from "./styles.module.scss";

interface ReminderProps {}

export interface ReminderType {
	publicId: string;
	bvn: string;
	nin: string;
	registrationNumber: string;
	businessName: string;
	reminderFrequency: string;
	createdBy: string;
	requesterEmailAddress: string;
	productName: string;
	bankName: string;
	accountNumber: string;
	isSpreadSheetReport: boolean;
	transactionType: string;
}

const Reminder: FC<ReminderProps> = (): JSX.Element => {
	const { userData, refreshCount, refresh } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [reminders, setReminders] = useState<ReminderType[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(0);
	const [selectedReminder, setSelectedReminder] = useState<string[]>([]);
	const [switchStatus, setSwitchStatus] = useState<{ [key: string]: boolean }>(
		{}
	);
	const { toggleForm } = useFormContext();

	useEffect(() => {
		const fetchData = async () => {
			const reminderRes = await axiosFetch({
				method: "GET",
				url: `/Credibilities/GetAutomatedRemindersByUserId?userId=${userData?.publicId}pageNumber=${currentPage}&pageSize=10`,
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			});

			setReminders(reminderRes?.automatedReminderViewModel || []);
			setTotalPage(reminderRes?.totalCount || 0);

			const initialStatus = reminderRes?.automatedReminderViewModel?.reduce(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(acc: any, reminder: ReminderType) => {
					acc[reminder.publicId] = true;
					return acc;
				},
				{}
			);
			setSwitchStatus(initialStatus);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, refreshCount]);

	const deleteReminder = async (data: {
		publicId: string;
		deletedBy: string;
	}) => {
		toast.promise(
			axiosFetch({
				method: "DELETE",
				url: "/Credibilities/DeleteAutomatedReminder",
				requestConfig: {
					data,
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Deleting reminder...",
				success: (res) => {
					if (res.isSuccessful) {
						refresh();
						return res.remark || "Automated reminder Deleted successfully!";
					}

					throw new Error(
						res.remark || res || "An error occurred, please try again!"
					);
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedReminder(reminders.map((remind) => remind.publicId));
		} else {
			setSelectedReminder([]);
		}
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const handleSelectUser = (userId: string) => {
		setSelectedReminder((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	const handleToggleSwitch = (reminderId: string) => {
		setSwitchStatus((prevStatus) => ({
			...prevStatus,
			[reminderId]: !prevStatus[reminderId],
		}));

		if (switchStatus[reminderId]) {
			deleteReminder({
				publicId: reminderId,
				deletedBy: userData?.publicId || "",
			});
		}
	};

	return (
		<div className={styles.reminder}>
			<div className={styles.header}>
				<div>
					<p className={styles.title}>Automated Reminder</p>

					<p>Set automated reminders for specific individuals or businesses</p>
				</div>

				<div>
					<button
						type="button"
						className="btn"
						onClick={() => toggleForm("reminder")}
					>
						<div>
							<AddSvg />
						</div>

						<p>Add Reminder</p>
					</button>
				</div>
			</div>

			<div className={styles.tableContainer}>
				<div className={styles.table}>
					<table>
						<thead>
							<tr>
								<th align="center">
									<input
										type="checkbox"
										checked={selectedReminder.length === reminders.length}
										onChange={handleSelectAll}
									/>
								</th>

								<th align="left">Requester Email</th>

								<th align="left">Product Name</th>

								<th align="center">Frequency</th>

								<th align="center">Status</th>
							</tr>
						</thead>
						<tbody>
							{reminders.map((remind) => (
								<tr key={remind.publicId}>
									<td align="center">
										<input
											type="checkbox"
											checked={selectedReminder.includes(remind.publicId)}
											onChange={() => handleSelectUser(remind.publicId)}
										/>
									</td>

									<td align="left">{remind.requesterEmailAddress}</td>

									<td align="left">{remind.productName}</td>

									<td align="center">{remind.reminderFrequency}</td>

									<td align="center">
										<Switch
											checked={switchStatus[remind.publicId]}
											onChange={() => handleToggleSwitch(remind.publicId)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{totalPage > 10 && (
				<Pagination
					total={totalPage / 10}
					current={currentPage}
					onPageChange={handlePageChange}
				/>
			)}
		</div>
	);
};

export default Reminder;
