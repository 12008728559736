import { FC } from "react";
import PricingHero from "../components/PricingHero/PricingHero";

interface PricingPageProps {}

const PricingPage: FC<PricingPageProps> = (): JSX.Element => {
	return (
		<>
			<PricingHero />
		</>
	);
};

export default PricingPage;
