import { FC } from "react";
import PersonalInformation from "../components/PersonalInformation/PersonalInformation";

interface PersonalInformationPageProps {}

const PersonalInformationPage: FC<
	PersonalInformationPageProps
> = (): JSX.Element => {
	return (
		<>
			<PersonalInformation />
		</>
	);
};

export default PersonalInformationPage;
