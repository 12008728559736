import { FC } from "react";
import ResetPassword from "../components/ResetPassword/ResetPassword";

interface ResetPasswordPageProps {}

const ResetPasswordPage: FC<ResetPasswordPageProps> = (): JSX.Element => {
	return (
		<>
			<ResetPassword />
		</>
	);
};

export default ResetPasswordPage;
