import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../../api/adminBaseApi";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import UploadBox from "../InputField/UploadBox";
import { OnBoard } from "../SignUp/SignUp";
import styles from "./styles.module.scss";

interface FormStepProps {
	step: number;
	formData: FormData;
	handleChange: (name: string, value: string | number | boolean | File) => void;
	nextStep: () => void;
	prevStep: () => void;
	resetForm?: () => void;
}

export interface FormData {
	isIndividual: boolean;
	lenderPublicId: string;
	createdBy: string;
	amountInvolved: string;
	beneficiaryRelationship: string;
	transactionType: string;
	dueDate: string;
	recipientBankName: string;
	recipientAccountNumber: string;
	recipientFirstName: string;
	recipientLastName: string;
	recipientEmailAddress: string;
	recipientNin: string;
	hasNin: boolean;
	recipientBvn: string;
	hasBvn: boolean;
	lenderAccountNumber: string;
	lenderBankName: string;
	transactionInitiationDate: string;
	receiptsOfPayment: File | undefined;
	proofsOfTransaction: File | undefined;
	currency: string;
	paymentReferenceNumber: string;
	recipientRegistrationNumber: string;
	businessName: string;
	isSettled: boolean;
	// settlementDate?: string;
	// recipientMiddleName?: string;
	// lenderBvn?: string;
	// recipientPublicId?: string;
	// businessType?: string;
}

const compareDates = (date1: Date | string, date2: Date | string) => {
	const d1 = new Date(date1).toISOString().split("T")[0];
	const d2 = new Date(date2).toISOString().split("T")[0];
	return d1 < d2;
};

export const Step1: React.FC<FormStepProps> = ({ handleChange, nextStep }) => (
	<form className={styles.content}>
		<h6>Record Type</h6>

		<p className={styles.sub}>What kind of record do you want to add?</p>

		<div className={styles.onBoardContainer}>
			<OnBoard
				to=""
				type="button"
				who="For Individual"
				onClick={() => {
					handleChange("isIndividual", true);
					nextStep();
				}}
			/>

			<OnBoard
				to=""
				type="button"
				who="For Corporate"
				onClick={() => {
					handleChange("isIndividual", false);
					nextStep();
				}}
			/>
		</div>
	</form>
);

export const Step2: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	nextStep,
}) => {
	const { axiosFetch } = useAxiosFunction();
	const [beneficiary, setBeneficiary] = useState<
		{ value: string; label: string }[]
	>([]);
	const [transactionType, setTransactionType] = useState<
		{ value: string; label: string }[]
	>([]);
	const [errors, setErrors] = useState({
		amountInvolved: "",
		beneficiaryRelationship: "",
		transactionType: "",
		dueDate: "",
	});

	const [isFormValid, setIsFormValid] = useState<boolean | string | number>(
		false
	);

	useEffect(() => {
		const fetchData = async () => {
			const relationship = await axiosFetch({
				method: "GET",
				url: "/BeneficiaryRelationships/GetBeneficiaryRelationship?pageNumber=1&pageSize=100",
			});

			if (relationship.isSuccessful)
				setBeneficiary(
					relationship?.beneficiaryRelationshipViewModel?.map(
						(type: { name: string }) => ({
							value: type.name,
							label: type.name,
						})
					)
				);

			const transaction = await axiosFetch({
				method: "GET",
				url: "/TransactionTypes/GetAllTransactionTypes?pageNumber=1&pageSize=100",
			});

			if (transaction.isSuccessful)
				setTransactionType(
					transaction?.transactionTypeViewModel?.map(
						(type: { name: string }) => ({
							value: type.name,
							label: type.name,
						})
					)
				);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateField = (name: string, value: string) => {
		let error = "";

		if (!value) {
			error = "This field is required.";
		}

		if (name === "amountInvolved" && !value) {
			error = "This field is required, and accepts numbers only";
		}

		if (name === "dueDate") return;

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	useEffect(() => {
		const isValid =
			formData.amountInvolved &&
			formData.beneficiaryRelationship &&
			formData.transactionType &&
			formData.dueDate &&
			!Object.values(errors).some((error) => error !== "");

		setIsFormValid(isValid);
	}, [formData, errors]);

	return (
		<form
			className={styles.content}
			onSubmit={(e) => {
				e.preventDefault();
				if (isFormValid) nextStep();
			}}
		>
			<h6>Transaction Details</h6>

			<p className={styles.sub}>Add new record into our database</p>

			<div className={styles.onBoardContainer}>
				<InputField
					type="number"
					label="Amount Involved"
					name="amountInvolved"
					placeholder="Enter amount involved"
					value={formData.amountInvolved}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					error={errors.amountInvolved}
					isRequired
				/>

				<InputField
					label="Select beneficiary relationship"
					name="beneficiaryRelationship"
					placeholder="Select beneficiary relationship"
					type="select"
					value={formData.beneficiaryRelationship}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={beneficiary}
					error={errors.beneficiaryRelationship}
					isRequired
				/>

				<InputField
					label="Transaction Type"
					placeholder="Transaction type"
					name="transactionType"
					type="select"
					value={formData.transactionType}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={transactionType}
					error={errors.transactionType}
					isRequired
				/>

				<InputField
					label="Due Date"
					name="dueDate"
					type="date"
					placeholder="DD-MM-YYYY"
					value={formData.dueDate}
					onChange={(name, e) => {
						if (compareDates(e.target.value, new Date())) {
							setErrors((prevErrors) => ({
								...prevErrors,
								[name]: "Due date cannot be earlier than today.",
							}));
						} else {
							setErrors((prevErrors) => ({
								...prevErrors,
								[name]: "",
							}));
						}

						validateField(name, e.target.value);
						handleChange(name, e.target.value);
					}}
					error={errors.dueDate}
					isRequired
				/>

				<button className="btn" type="submit" disabled={!isFormValid}>
					Next
				</button>
			</div>
		</form>
	);
};

export const Step3: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	nextStep,
}) => {
	const { axiosFetch } = useAxiosFunction();
	const [bank, setBank] = useState<{ value: string; label: string }[]>([]);
	const [errors, setErrors] = useState({
		recipientFirstName: "",
		businessName: "",
		recipientLastName: "",
		recipientAccountNumber: "",
		recipientBankName: "",
		recipientBvn: "",
		recipientNin: "",
		recipientEmailAddress: "",
		recipientRegistrationNumber: "",
	});
	const [isFormValid, setIsFormValid] = useState<boolean | string>(false);

	useEffect(() => {
		const fetchData = async () => {
			const banksData = await axiosFetch({
				method: "GET",
				url: "/Banks/GetBanks?pageNumber=1&pageSize=100",
			});

			if (banksData.isSuccessful) {
				setBank(
					banksData.bankViewModel.map((bank: { name: string }) => ({
						value: bank.name,
						label: bank.name,
					}))
				);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateField = (name: string, value: string) => {
		let error = "";

		switch (name) {
			case "recipientFirstName":
				if (!value)
					error = formData.isIndividual
						? "First name is required."
						: "Business name is required.";
				break;
			case "recipientLastName":
				if (formData.isIndividual && !value) error = "Last name is required.";
				break;
			case "recipientAccountNumber":
				if (!value) error = "Account number is required.";
				else if (value.length !== 10)
					error = "Account number must be 10 digits.";
				break;
			case "recipientBankName":
				if (!value) error = "Bank is required.";
				break;
			case "recipientBvn":
				if (formData.isIndividual && (!value || value.length !== 11))
					error = "BVN must be 11 digits.";
				break;
			case "recipientNin":
				if (formData.isIndividual && (!value || value.length !== 11))
					error = "NIN must be 11 digits.";
				break;
			case "recipientRegistrationNumber":
				if (!formData.isIndividual && !value)
					error = "Registration number is required for businesses.";
				break;
			case "businessName":
				if (!formData.isIndividual && !value)
					error = "Business name is required.";
				break;
			case "recipientEmailAddress":
				if (!value) error = "Email is required.";
				else if (!/\S+@\S+\.\S+/.test(value)) error = "Email is invalid.";
				break;
			default:
				break;
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	useEffect(() => {
		const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

		const commonValidations =
			formData.recipientAccountNumber &&
			formData.recipientAccountNumber.length === 10 &&
			formData.recipientBankName &&
			formData.recipientEmailAddress &&
			isEmailValid(formData.recipientEmailAddress);

		const individualValidations =
			formData.isIndividual &&
			formData.recipientFirstName &&
			formData.recipientLastName &&
			formData.recipientBvn &&
			formData.recipientBvn.length === 11 &&
			formData.recipientNin &&
			formData.recipientNin.length === 11;

		const businessValidations =
			!formData.isIndividual &&
			formData.recipientRegistrationNumber &&
			formData.businessName;

		setIsFormValid(
			commonValidations &&
				(formData.isIndividual ? individualValidations : businessValidations) &&
				!Object.values(errors).some((error) => error !== "")
		);
	}, [formData, errors]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (isFormValid) {
			if (formData.recipientBvn) handleChange("hasBvn", true);
			if (formData.recipientNin) handleChange("hasNin", true);
			nextStep();
		}
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Beneficiary Details</h6>
			<p className={styles.sub}>Add new record into our database</p>

			<div className={styles.onBoardContainer}>
				<InputField
					label={
						formData.isIndividual
							? "Beneficiary First Name"
							: "Beneficiary Business Name"
					}
					name={formData.isIndividual ? "recipientFirstName" : "businessName"}
					placeholder={
						formData.isIndividual ? "Enter first name" : "Enter business name"
					}
					value={
						formData.isIndividual
							? formData.recipientFirstName
							: formData.businessName
					}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					isRequired
					autoComplete="given-name"
					error={
						formData.isIndividual
							? errors.recipientFirstName
							: errors.businessName
					}
				/>

				{formData.isIndividual && (
					<InputField
						label="Beneficiary Last Name"
						name="recipientLastName"
						placeholder="Enter last name"
						value={formData.recipientLastName}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						isRequired
						autoComplete="family-name"
						error={errors.recipientLastName}
					/>
				)}

				<InputField
					label="Beneficiary Account Number"
					name="recipientAccountNumber"
					placeholder="Enter account number"
					value={formData.recipientAccountNumber}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					isRequired
					type="number"
					error={errors.recipientAccountNumber}
				/>

				<InputField
					label="Beneficiary Bank"
					name="recipientBankName"
					type="select"
					placeholder="Select bank"
					value={formData.recipientBankName}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={bank}
					isRequired
					error={errors.recipientBankName}
				/>

				{formData.isIndividual && (
					<>
						<InputField
							label="Beneficiary BVN"
							name="recipientBvn"
							type="number"
							placeholder="Enter BVN"
							value={formData.recipientBvn}
							onChange={(name, e) => {
								handleChange(name, e.target.value);
								validateField(name, e.target.value);
							}}
							isRequired
							error={errors.recipientBvn}
						/>

						<InputField
							label="National Identification Number (NIN)"
							name="recipientNin"
							type="number"
							placeholder="Enter NIN"
							value={formData.recipientNin}
							onChange={(name, e) => {
								handleChange(name, e.target.value);
								validateField(name, e.target.value);
							}}
							isRequired
							error={errors.recipientNin}
						/>
					</>
				)}

				{!formData.isIndividual && (
					<InputField
						label="Beneficiary Registration Number"
						name="recipientRegistrationNumber"
						placeholder="Enter business registration number"
						value={formData.recipientRegistrationNumber}
						onChange={(name, e) => {
							handleChange(name, e.target.value);
							validateField(name, e.target.value);
						}}
						isRequired
						error={errors.recipientRegistrationNumber}
					/>
				)}

				<InputField
					label="Beneficiary Email"
					name="recipientEmailAddress"
					placeholder="Enter email address"
					value={formData.recipientEmailAddress}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					isRequired
					autoComplete="email"
					type="email"
					error={errors.recipientEmailAddress}
				/>

				<button className="btn" type="submit" disabled={!isFormValid}>
					Next
				</button>
			</div>
		</form>
	);
};

export const Step4: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	nextStep,
}) => {
	const { axiosFetch } = useAxiosFunction();
	const [bank, setBank] = useState<
		{ value: string; label: string | number | boolean }[]
	>([]);
	const [errors, setErrors] = useState({
		lenderAccountNumber: "",
		lenderBankName: "",
		transactionInitiationDate: "",
	});
	const [isFormValid, setIsFormValid] = useState<boolean | string>(false);

	useEffect(() => {
		const fetchData = async () => {
			const banksData = await axiosFetch({
				method: "GET",
				url: "/Banks/GetBanks?pageNumber=1&pageSize=100",
			});

			if (banksData.isSuccessful)
				setBank(
					banksData?.bankViewModel?.map((bank: { name: string }) => ({
						value: bank.name,
						label: bank.name,
					}))
				);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateField = (name: string, value: string) => {
		let error = "";
		if (!value) {
			error = "This field is required.";
		} else if (name === "lenderAccountNumber" && value.length !== 10) {
			error = "Account number must be 10 digits.";
		}

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	useEffect(() => {
		const isValid =
			formData.lenderAccountNumber &&
			formData.lenderBankName &&
			formData.transactionInitiationDate &&
			!Object.values(errors).some((error) => error !== "");

		setIsFormValid(isValid);
	}, [formData, errors]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (isFormValid) nextStep();
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Sender Details</h6>

			<p className={styles.sub}>Add new record into our database</p>

			<div className={styles.onBoardContainer}>
				<InputField
					label="Sender Account Number"
					name="lenderAccountNumber"
					placeholder="Enter sender account number"
					value={formData.lenderAccountNumber}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					isRequired
					type="number"
					error={errors.lenderAccountNumber}
				/>

				<InputField
					label="Sender Bank"
					name="lenderBankName"
					type="select"
					placeholder="Select bank"
					value={formData.lenderBankName}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					selectOptions={bank}
					isRequired
					error={errors.lenderBankName}
				/>

				<InputField
					label="Date Transaction Was Executed"
					name="transactionInitiationDate"
					type="date"
					placeholder="DD-MM-YYYY"
					value={formData.transactionInitiationDate}
					onChange={(name, e) => {
						handleChange(name, e.target.value);
						validateField(name, e.target.value);
					}}
					isRequired
					error={errors.transactionInitiationDate}
				/>

				<button className="btn" type="submit" disabled={!isFormValid}>
					Next
				</button>
			</div>
		</form>
	);
};

export const Step5: React.FC<FormStepProps> = ({
	formData,
	handleChange,
	resetForm,
}) => {
	const { userData, refresh } = useAuth();
	const [proofOfTransaction, setProofOfTransaction] = useState<File>();
	const [proofOfPayment, setProofOfPayments] = useState<File>();
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		const newErrors: Record<string, string> = {};

		if (!proofOfPayment) {
			newErrors.proofOfPayment = "Proof of payment is required";
		}

		if (!proofOfTransaction) {
			newErrors.proofOfTransaction = "Proof of transaction request is required";
		}

		setErrors(newErrors);

		setIsFormValid(Object.keys(newErrors).length === 0);
	}, [formData, proofOfPayment, proofOfTransaction]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (isFormValid) {
			const data = new FormData();

			for (const key in formData) {
				const value = formData[key as keyof typeof formData];

				if (value instanceof File) {
					data.append(key, value);
				} else if (typeof value === "string" || typeof value === "number") {
					data.append(key, value.toString());
				} else if (typeof value === "boolean") {
					data.append(key, value ? "true" : "false");
				}
			}

			try {
				toast.promise(
					axios.post(API_BASE_URL + "Transactions/CreateTransaction/", data, {
						headers: {
							"Content-Type": "multipart/form-data",
							Accept: "application/json",
							Authorization: `Bearer ${userData?.token}`,
						},
					}),
					{
						loading: "Adding record...",
						success: (res) => {
							if (res.data.isSuccessful) {
								if (resetForm) resetForm();
								refresh();
								return res.data.remark || "Record Added successfully!";
							}

							throw new Error(
								res.data || "An error occurred, please try again!"
							);
						},
						error: (err) => {
							if (axios.isAxiosError(err)) {
								return (
									err.response?.data.title ||
									err.response?.data.remark ||
									err.message
								);
							}
							return err.message;
						},
					}
				);
			} catch (error) {
				toast.error("Error submitting the form");
			}
		}
	};

	return (
		<form className={styles.content} onSubmit={handleSubmit}>
			<h6>Payment Proof</h6>

			<p className={styles.sub}>Add new record into our database</p>

			<div className={styles.onBoardContainer}>
				<InputField
					label="Payment Reference Number"
					name="paymentReferenceNumber"
					placeholder="Enter reference number"
					value={formData.paymentReferenceNumber}
					onChange={(name, e) => handleChange(name, e.target.value)}
					type="number"
				/>

				<UploadBox
					label="Proof of Payment"
					onUpload={(uploads) => {
						handleChange("receiptsOfPayment", uploads[0]);
						setProofOfPayments(uploads[0]);
					}}
					isRequired
					error={errors.proofOfPayment}
				/>

				<UploadBox
					label="Proof of Transaction Request"
					onUpload={(uploads) => {
						handleChange("proofsOfTransaction", uploads[0]);
						setProofOfTransaction(uploads[0]);
					}}
					isRequired
					error={errors.proofOfTransaction}
				/>

				<button className="btn" type="submit" disabled={!isFormValid}>
					Add Record
				</button>
			</div>
		</form>
	);
};
