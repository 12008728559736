import { FC } from "react";
import Home from "../../components/Dashboard/Home/Home";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = (): JSX.Element => {
	return (
		<>
			<Home />
		</>
	);
};

export default Dashboard;
