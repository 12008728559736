import { FC } from "react";
import ContactForm from "../components/ContactForm/ContactForm";

interface ContactPageProps {}

const ContactPage: FC<ContactPageProps> = (): JSX.Element => {
	return (
		<>
			<ContactForm />
		</>
	);
};

export default ContactPage;
