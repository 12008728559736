import React from "react";
import styles from "./styles.module.scss";

interface StatusIndicatorProps {
	status: string;
	color: "red" | "green" | "gray";
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, color }) => {
	return (
		<div className={`${styles.statusIndicator} ${styles[color]}`}>
			<span className={styles.dot}></span>
			<span>{status}</span>
		</div>
	);
};

export default StatusIndicator;
