export const DashSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.75 3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V9.75C3.75 10.1478 3.90804 10.5294 4.18934 10.8107C4.47064 11.092 4.85218 11.25 5.25 11.25H9.75C10.1478 11.25 10.5294 11.092 10.8107 10.8107C11.092 10.5294 11.25 10.1478 11.25 9.75V5.25C11.25 4.85218 11.092 4.47064 10.8107 4.18934C10.5294 3.90804 10.1478 3.75 9.75 3.75ZM9.75 9.75H5.25V5.25H9.75V9.75ZM18.75 3.75H14.25C13.8522 3.75 13.4706 3.90804 13.1893 4.18934C12.908 4.47064 12.75 4.85218 12.75 5.25V9.75C12.75 10.1478 12.908 10.5294 13.1893 10.8107C13.4706 11.092 13.8522 11.25 14.25 11.25H18.75C19.1478 11.25 19.5294 11.092 19.8107 10.8107C20.092 10.5294 20.25 10.1478 20.25 9.75V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM18.75 9.75H14.25V5.25H18.75V9.75ZM9.75 12.75H5.25C4.85218 12.75 4.47064 12.908 4.18934 13.1893C3.90804 13.4706 3.75 13.8522 3.75 14.25V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H9.75C10.1478 20.25 10.5294 20.092 10.8107 19.8107C11.092 19.5294 11.25 19.1478 11.25 18.75V14.25C11.25 13.8522 11.092 13.4706 10.8107 13.1893C10.5294 12.908 10.1478 12.75 9.75 12.75ZM9.75 18.75H5.25V14.25H9.75V18.75ZM18.75 12.75H14.25C13.8522 12.75 13.4706 12.908 13.1893 13.1893C12.908 13.4706 12.75 13.8522 12.75 14.25V18.75C12.75 19.1478 12.908 19.5294 13.1893 19.8107C13.4706 20.092 13.8522 20.25 14.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V14.25C20.25 13.8522 20.092 13.4706 19.8107 13.1893C19.5294 12.908 19.1478 12.75 18.75 12.75ZM18.75 18.75H14.25V14.25H18.75V18.75Z"
			fill="white"
		/>
	</svg>
);

export const ManageSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.6503 19.8751C20.2225 17.4067 18.0222 15.6367 15.4544 14.7976C16.7246 14.0415 17.7114 12.8893 18.2634 11.518C18.8153 10.1468 18.9019 8.63224 18.5098 7.20701C18.1177 5.78178 17.2685 4.52467 16.0928 3.62873C14.9171 2.73279 13.4798 2.24756 12.0016 2.24756C10.5234 2.24756 9.08609 2.73279 7.91037 3.62873C6.73465 4.52467 5.88553 5.78178 5.49342 7.20701C5.1013 8.63224 5.18787 10.1468 5.73983 11.518C6.2918 12.8893 7.27863 14.0415 8.54878 14.7976C5.98097 15.6357 3.78066 17.4057 2.35285 19.8751C2.30049 19.9605 2.26576 20.0555 2.2507 20.1545C2.23565 20.2535 2.24059 20.3545 2.26521 20.4516C2.28984 20.5487 2.33366 20.6399 2.39409 20.7197C2.45452 20.7996 2.53033 20.8666 2.61706 20.9167C2.70378 20.9667 2.79966 20.999 2.89904 21.0114C2.99842 21.0238 3.09928 21.0163 3.19568 20.9891C3.29208 20.9619 3.38205 20.9157 3.4603 20.8532C3.53855 20.7907 3.60349 20.7131 3.65128 20.6251C5.41753 17.5726 8.53941 15.7501 12.0016 15.7501C15.4638 15.7501 18.5857 17.5726 20.3519 20.6251C20.3997 20.7131 20.4646 20.7907 20.5429 20.8532C20.6211 20.9157 20.7111 20.9619 20.8075 20.9891C20.9039 21.0163 21.0048 21.0238 21.1042 21.0114C21.2035 20.999 21.2994 20.9667 21.3861 20.9167C21.4729 20.8666 21.5487 20.7996 21.6091 20.7197C21.6695 20.6399 21.7134 20.5487 21.738 20.4516C21.7626 20.3545 21.7675 20.2535 21.7525 20.1545C21.7374 20.0555 21.7027 19.9605 21.6503 19.8751ZM6.7516 9.00011C6.7516 7.96176 7.0595 6.94672 7.63638 6.08337C8.21326 5.22001 9.0332 4.5471 9.99251 4.14974C10.9518 3.75238 12.0074 3.64841 13.0258 3.85099C14.0442 4.05356 14.9797 4.55357 15.7139 5.2878C16.4481 6.02202 16.9481 6.95748 17.1507 7.97589C17.3533 8.99429 17.2493 10.0499 16.852 11.0092C16.4546 11.9685 15.7817 12.7884 14.9183 13.3653C14.055 13.9422 13.0399 14.2501 12.0016 14.2501C10.6097 14.2486 9.27517 13.695 8.29093 12.7108C7.30669 11.7265 6.75309 10.392 6.7516 9.00011Z"
			fill="#D6D6D6"
		/>
	</svg>
);

export const HistorySvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V8.25C1.5 8.64782 1.65804 9.02936 1.93934 9.31066C2.22064 9.59196 2.60218 9.75 3 9.75V18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H19.5C19.8978 19.5 20.2794 19.342 20.5607 19.0607C20.842 18.7794 21 18.3978 21 18V9.75C21.3978 9.75 21.7794 9.59196 22.0607 9.31066C22.342 9.02936 22.5 8.64782 22.5 8.25V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM19.5 18H4.5V9.75H19.5V18ZM21 8.25H3V6H21V8.25ZM9 12.75C9 12.5511 9.07902 12.3603 9.21967 12.2197C9.36032 12.079 9.55109 12 9.75 12H14.25C14.4489 12 14.6397 12.079 14.7803 12.2197C14.921 12.3603 15 12.5511 15 12.75C15 12.9489 14.921 13.1397 14.7803 13.2803C14.6397 13.421 14.4489 13.5 14.25 13.5H9.75C9.55109 13.5 9.36032 13.421 9.21967 13.2803C9.07902 13.1397 9 12.9489 9 12.75Z"
			fill="#D6D6D6"
		/>
	</svg>
);

export const PaymentSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.25 6.75H5.25C5.05109 6.75 4.86032 6.67098 4.71967 6.53033C4.57902 6.38968 4.5 6.19891 4.5 6C4.5 5.80109 4.57902 5.61032 4.71967 5.46967C4.86032 5.32902 5.05109 5.25 5.25 5.25H18C18.1989 5.25 18.3897 5.17098 18.5303 5.03033C18.671 4.88968 18.75 4.69891 18.75 4.5C18.75 4.30109 18.671 4.11032 18.5303 3.96967C18.3897 3.82902 18.1989 3.75 18 3.75H5.25C4.65326 3.75 4.08097 3.98705 3.65901 4.40901C3.23705 4.83097 3 5.40326 3 6V18C3 18.5967 3.23705 19.169 3.65901 19.591C4.08097 20.0129 4.65326 20.25 5.25 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V8.25C21.75 7.85218 21.592 7.47064 21.3107 7.18934C21.0294 6.90804 20.6478 6.75 20.25 6.75ZM20.25 18.75H5.25C5.05109 18.75 4.86032 18.671 4.71967 18.5303C4.57902 18.3897 4.5 18.1989 4.5 18V8.12156C4.74082 8.20693 4.9945 8.25037 5.25 8.25H20.25V18.75ZM15.75 13.125C15.75 12.9025 15.816 12.685 15.9396 12.5C16.0632 12.315 16.2389 12.1708 16.4445 12.0856C16.65 12.0005 16.8762 11.9782 17.0945 12.0216C17.3127 12.065 17.5132 12.1722 17.6705 12.3295C17.8278 12.4868 17.935 12.6873 17.9784 12.9055C18.0218 13.1238 17.9995 13.35 17.9144 13.5555C17.8292 13.7611 17.685 13.9368 17.5 14.0604C17.315 14.184 17.0975 14.25 16.875 14.25C16.5766 14.25 16.2905 14.1315 16.0795 13.9205C15.8685 13.7095 15.75 13.4234 15.75 13.125Z"
			fill="#D6D6D6"
		/>
	</svg>
);

export const SupportSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.5 3.75H4.5C4.10218 3.75 3.72064 3.90804 3.43934 4.18934C3.15804 4.47064 3 4.85218 3 5.25V10.7597C3 19.1606 10.1081 21.9478 11.5312 22.4212C11.8352 22.5246 12.1648 22.5246 12.4688 22.4212C13.8938 21.9478 21 19.1606 21 10.7597V5.25C21 4.85218 20.842 4.47064 20.5607 4.18934C20.2794 3.90804 19.8978 3.75 19.5 3.75ZM19.5 10.7616C19.5 18.1134 13.2797 20.5697 12 20.9972C10.7316 20.5744 4.5 18.12 4.5 10.7616V5.25H19.5V10.7616Z"
			fill="#D6D6D6"
		/>
	</svg>
);

export const LogoutSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 9.75C21 9.94891 20.921 10.1397 20.7803 10.2803C20.6397 10.421 20.4489 10.5 20.25 10.5C20.0511 10.5 19.8603 10.421 19.7197 10.2803C19.579 10.1397 19.5 9.94891 19.5 9.75V5.56125L13.2816 11.7806C13.1408 11.9214 12.95 12.0004 12.7509 12.0004C12.5519 12.0004 12.361 11.9214 12.2203 11.7806C12.0796 11.6399 12.0005 11.449 12.0005 11.25C12.0005 11.051 12.0796 10.8601 12.2203 10.7194L18.4387 4.5H14.25C14.0511 4.5 13.8603 4.42098 13.7197 4.28033C13.579 4.13968 13.5 3.94891 13.5 3.75C13.5 3.55109 13.579 3.36032 13.7197 3.21967C13.8603 3.07902 14.0511 3 14.25 3H20.25C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75V9.75ZM17.25 12C17.0511 12 16.8603 12.079 16.7197 12.2197C16.579 12.3603 16.5 12.5511 16.5 12.75V19.5H4.5V7.5H11.25C11.4489 7.5 11.6397 7.42098 11.7803 7.28033C11.921 7.13968 12 6.94891 12 6.75C12 6.55109 11.921 6.36032 11.7803 6.21967C11.6397 6.07902 11.4489 6 11.25 6H4.5C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H16.5C16.8978 21 17.2794 20.842 17.5607 20.5607C17.842 20.2794 18 19.8978 18 19.5V12.75C18 12.5511 17.921 12.3603 17.7803 12.2197C17.6397 12.079 17.4489 12 17.25 12Z"
			fill="#D6D6D6"
		/>
	</svg>
);

export const ReminderSvg = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 19V17H6V10C6 8.61667 6.41667 7.39167 7.25 6.325C8.08333 5.24167 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6417 2.73333 10.925 2.45C11.225 2.15 11.5833 2 12 2C12.4167 2 12.7667 2.15 13.05 2.45C13.35 2.73333 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.24167 16.75 6.325C17.5833 7.39167 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.975 21.8083 10.575 21.425C10.1917 21.025 10 20.55 10 20H14C14 20.55 13.8 21.025 13.4 21.425C13.0167 21.8083 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z"
			fill="#D6D6D6"
		/>
	</svg>
);
